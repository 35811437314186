import {CoreLocaleKeys} from 'invision-core';

export default {
    // Please make sure all the keys are in uppercase with _  separator, and listed in alphabetical order
    ACCEPTED_TERMS_DATE: 'customerCareModule.accepted_terms_date',
    ACCESS_COUNTRY: 'customerCareModule.access_country',
    ACCOUNT_HIERARCHY: {
        ADD_EXISTING_CUSTOMER: 'customerCareModule.account_hierarchy.add_existing_customer',
        ACCOUNT_HIERARCHY: 'customerCareModule.account_hierarchy.account_hierarchy',
        AVAILABLE_CREDIT_LIMIT: 'customerCareModule.account_hierarchy.available_credit_limit',
        CHANGE_BILLING_TYPE: {
            DIALOG_TITLE: 'customerCareModule.account_hierarchy.change_billing_type.dialog_title',
            DIALOG_TEXT: 'customerCareModule.account_hierarchy.change_billing_type.dialog_text',
            REPORTING_LEVEL_INVOICE: 'customerCareModule.account_hierarchy.change_billing_type.reporting_level_invoice',
            REPORTING_LEVEL_NONE: 'customerCareModule.account_hierarchy.change_billing_type.reporting_level_none',
            REPORTING_LEVEL_STATEMENT: 'customerCareModule.account_hierarchy.change_billing_type.reporting_level_statement',
            SUBMIT_BUTTON_TEXT: 'customerCareModule.account_hierarchy.change_billing_type.submit_button_text',
            UPDATE_SUCCESS_TEXT: 'customerCareModule.account_hierarchy.change_billing_type.update_success_text'
        },
        CLEAR_RESPONSIBLE_PARTY: 'customerCareModule.account_hierarchy.clear_responsible_party',
        CREATE_ACCOUNT_HIERARCHY: 'customerCareModule.account_hierarchy.create_account_hierarchy',
        CUSTOMER_WILL_BE_ADDED_AS_A_CHILD_NODE_OF: 'customerCareModule.account_hierarchy.customer_will_be_added_as_a_child_node_of',
        CUSTOMER_WAS_REMOVED_SUCCESSFULLY: 'customerCareModule.account_hierarchy.customer_was_removed_successfully',
        DUE: 'customerCareModule.account_hierarchy.due',
        HIERARCHY_CONVERGENT_BILLER_ERROR: 'customerCareModule.account_hierarchy.hierarchy_convergent_biller_error',
        INVOICE_CHARGES: 'customerCareModule.account_hierarchy.invoice_charges',
        INVOICE_UNBILLED_CHARGES: 'customerCareModule.account_hierarchy.invoice_unbilled_charges',
        MOVE_IN_HIERARCHY: 'customerCareModule.account_hierarchy.move_in_hierarchy',
        NO_HIERARCHY_FOR_CUSTOMER: 'customerCareModule.account_hierarchy.no_hierarchy_for_customer',
        REMOVE_MESSAGE: 'customerCareModule.account_hierarchy.remove_message',
        RESPONSIBLE_PARTY_REQUIRED: 'customerCareModule.account_hierarchy.responsible_party_required',
        SEARCH_FOR_CUSTOMERS: 'customerCareModule.account_hierarchy.search_for_customer',
        SELECT_THE_NEW_PARENT_FOR: 'customerCareModule.account_hierarchy.select_the_new_parent_for',
        SUBSCRIBER_UNBILLED_CHARGES: 'customerCareModule.account_hierarchy.subscriber_unbilled_charges',
        THIS_CUSTOMER_IS_ALREADY_ASSOCIATED_TO_A_HIERARCHY: 'customerCareModule.account_hierarchy.this_customer_is_already_associated_to_a_hierarchy',
        WILL_BE_THE_NEW_PARENT_FOR: 'customerCareModule.account_hierarchy.will_be_the_new_parent_for'
    },
    ACCOUNT_INFORMATION: 'customerCareModule.account_information',
    ACCOUNT_NUMBER: 'customerCareModule.account_number',
    ACCOUNT_TOTALS: 'customerCareModule.account_totals',
    ACCOUNT_USAGE: 'customerCareModule.account_usage',
    ACTIVATION_DATE: 'customerCareModule.activation_date',
    ACTIVE_DISCRETIONARY_DISCOUNTS: {
        ACTIVE_DATE: 'customerCareModule.active_discretionary_discounts.active_date',
        EXPIRATION_DATE: 'customerCareModule.active_discretionary_discounts.expiration_date',
        TITLE: 'customerCareModule.active_discretionary_discounts.title'
    },
    ACTIVE: 'customerCareModule.active',
    ACTIVITY: 'customerCareModule.activity',
    ACTIVITY_IS_REQUIRED: 'customerCareModule.activity_is_required',
    AD_HOC: 'customerCareModule.ad_hoc',
    ADD_ANOTHER_PAYMENT_METHOD: 'customerCareModule.add_another_payment_method',
    ADD_EXISTING_CUSTOMER: 'customerCareModule.add_existing_customer',
    ADD_FEE: 'customerCareModule.add_fee',
    ADD_RATE: 'customerCareModule.add_rate',
    ADDITIONAL_PERMISSIONS: 'customerCareModule.additional_permissions',
    ADDITIONAL_PROPERTIES: 'customerCareModule.additional_properties',
    ADDRESS: 'customerCareModule.address',
    ADDRESS_COMPONENT: {
        ADD_TO_ADDRESS: 'customerCareModule.address_component.add_to_address',
        EDIT_ADDRESS: 'customerCareModule.address_component.edit_address',
        ENTERED_ADDRESS: 'customerCareModule.address_component.entered_address',
        INVALID_ADDRESS_ERROR_MESSAGE: 'customerCareModule.address_component.invalid_address_error_message',
        KEEP_SUGGESTED_ADDRESS: 'customerCareModule.address_component.keep_suggested_address',
        MDU_RANGE_EXAMPLE: 'customerCareModule.address_component.mdu_range_example',
        MISSING_UNIT_NUMBER_WARNING: 'customerCareModule.address_component.missing_unit_number_warning',
        SUGGESTIONS: 'customerCareModule.address_component.suggestions',
        VALIDATE_ADDRESS_WARNING: 'customerCareModule.address_component.validate_address_warning'
    },
    ADDRESSES: {
        ACTIVATE_ADDRESS: 'customerCareModule.addresses.activate_address',
        ACTIVATE_SUCCESS_MESSAGE: 'customerCareModule.addresses.address_activated_successfully',
        ADDRESS_CREATED_SUCCESSFULLY: 'customerCareModule.addresses.address_created_successfully',
        ADDRESS_DETAILS: 'customerCareModule.addresses.address_details',
        ADDRESS_LOCKED: 'customerCareModule.addresses.address_locked',
        ADDRESS_TYPE: 'customerCareModule.addresses.address_type',
        ADDRESS_UPDATED_SUCCESSFULLY: 'customerCareModule.addresses.address_updated_successfully',
        ADDRESSES: 'customerCareModule.addresses.addresses',
        ADDRESSES_DOWN_KEY_DESCRIPTION: 'customerCareModule.addresses.addressesDownKeyDescription',
        ADDRESSES_UP_KEY_DESCRIPTION: 'customerCareModule.addresses.addressesUpKeyDescription',
        CANNOT_ACTIVATE: 'customerCareModule.addresses.cannot_activate',
        CANNOT_EDIT: 'customerCareModule.addresses.cannot_edit',
        CANNOT_REMOVE: 'customerCareModule.cannot_remove',
        CONFIRM_ACTIVATE_ADDRESS: 'customerCareModule.addresses.confirm_activate_title',
        CONFIRM_ACTIVATE_MESSAGE: 'customerCareModule.addresses.confirm_activate_message',
        CONFIRM_REMOVE_MESSAGE: 'customerCareModule.addresses.confirm_remove_message',
        CONFIRM_REMOVE_TITLE: 'customerCareModule.addresses.confirm_remove_title',
        EDIT: 'customerCareModule.edit',
        EDIT_ADDRESS: 'customerCareModule.addresses.edit_address',
        NO_ADDRESSES: 'customerCareModule.addresses.noAddresses',
        NO_CUSTOMER_ADDRESSES: 'customerCareModule.addresses.no_customer_addresses',
        REMOVE_ADDRESS: 'customerCareModule.addresses.remove_address',
        REMOVE_SUCCESS_MESSAGE: 'customerCareModule.addresses.remove_success_message',
        SAVE_ADDRESS: 'customerCareModule.addresses.save_address',
        SERVICE_ADDRESS: 'customerCareModule.addresses.service_address',
        SHIP_TO_NAME: 'customerCareModule.addresses.ship_to_name',
        TEXT_SCRUBBER: 'customerCareModule.addresses.text_scrubber',
        OVERRIDE: 'customerCareModule.addresses.override'
    },
    ADDRESS_LINE_1: 'address_line_1',
    ADDRESS_LINE_2: 'address_line_2',
    ADDRESS_LINE_1_REQUIRED: 'customerCareModule.address_line_1_required',
    ADDRESS_LINE_2_REQUIRED: 'customerCareModule.address_line_2_required',
    ADDRESS_SWITCHER: {
        CHANGE_PRIMARY: 'customerCareModule.address_switcher.change_primary',
        CHANGE_TITLE: 'customerCareModule.address_switcher.change_title',
        EMPTY_MESSAGE: 'customerCareModule.address_switcher.empty_message',
        EMPTY_MESSAGE_ACTION: 'customerCareModule.address_switcher.empty_message_action'
    },
    ADD_CASE_ACTIVITY: 'customerCareModule.addCaseActivity',
    ADD_CASE_REMARK: 'customerCareModule.addCaseRemark',
    ADD_CASE_REMARK_ALL_FIELDS_REQUIRED: 'customerCareModule.addCaseRemarkAllFieldsRequired',
    ADD_NEW: 'customerCareModule.add_new',
    ADD_OFFER_ORDER: 'customerCareModule.add_offer_order',
    ADD_SERVICES_ORDER: {
        ADD_SERVICE: 'customerCareModule.add_services_order.add_service',
        ADD_SERVICES_TO: 'customerCareModule.add_services_order.add_services_to',
        CHANGE_GLOBAL_TEMPLATE: {
            TITLE: 'customerCareModule.add_services_order.change_global_template.title',
            MESSAGE: 'customerCareModule.add_services_order.change_global_template.message'
        },
        CHOOSE_PHONE_NUMBER: 'customerCareModule.add_services_order.choose_phone_number',
        CONFIRM_ORDER_SETTINGS: 'customerCareModule.add_services_order.confirm_order_settings',
        EMPTY_SERVICES: 'customerCareModule.add_services_order.empty_services',
        ORDER_SETTINGS_OVERRIDDEN: 'customerCareModule.add_services_order.order_settings_overridden',
        REMOVE_SERVICES: {
            TITLE: 'customerCareModule.add_services_order.remove_services.title',
            MESSAGE: 'customerCareModule.add_services_order.remove_services.message'
        },
        RESET: 'customerCareModule.add_services_order.reset'
    },
    ADD_TO_CART: 'customerCareModule.add_to_cart',
    ADD_TO_CART_WITH_COUNT: 'customerCareModule.add_to_cart_with_count',
    ADJUSTMENT: 'customerCareModule.adjustment',
    ADJUSTMENTS: {
        ADJUSTMENT_AMOUNT: 'customerCareModule.adjustments.adjustment_amount',
        ADJUSTMENT_SUCCESS: 'customerCareModule.adjustments.adjustment_success',
        ADJUSTMENT_TYPE: 'customerCareModule.adjustments.adjustment_type',
        APPROVAL_STATUS: 'customerCareModule.adjustments.approval_status',
        APPLY_ADJUSTMENT: 'customerCareModule.adjustments.apply_adjustment',
        APPLY_INVOICE_ADJUSTMENT: 'customerCareModule.adjustments.apply_invoice_adjustment',
        APPLY_ITEM_ADJUSTMENT: 'customerCareModule.adjustments.apply_item_adjustment',
        APPLY_MISC_ADJUSTMENT: 'customerCareModule.adjustments.apply_misc_adjustment',
        APPROVE: 'customerCareModule.adjustments.approve',
        APPROVED: 'customerCareModule.adjustments.approved',
        CREDIT_INVOICE_DETAIL: 'customerCareModule.adjustments.credit_invoice_details',
        DESCRIPTIONS: 'customerCareModule.adjustments.descriptions',
        ERRORS: {
            ADJUSTMENT_AMOUNT_ABOVE_MAXIMUM: 'customerCareModule.adjustments.errors.adjustment_amount_above_maximum',
            ADJUSTMENT_AMOUNT_BELOW_MINIMUM: 'customerCareModule.adjustments.errors.adjustment_amount_below_minimum',
            ADJUSTMENT_AMOUNT_REQUIRED: 'customerCareModule.adjustments.errors.adjustment_amount_required',
            ADJUSTMENT_TYPE_REQUIRED: 'customerCareModule.adjustments.errors.adjustment_type_required',
            ADJUSTMENT_REASON_REQUIRED: 'customerCareModule.adjustments.errors.adjustment_reason_required',
            APPROVAL_STATUS_REQUIRED: 'customerCareModule.adjustments.errors.approval_status_required',
            DESCRIPTIONS_MAX_LENGTH: 'customerCareModule.adjustments.errors.descriptions_max_length',
            DESCRIPTIONS_REQUIRED: 'customerCareModule.adjustments.errors.descriptions_required',
            POST_DATE_INVALID: 'customerCareModule.adjustments.errors.post_date_invalid',
            POST_DATE_MIN: 'customerCareModule.adjustments.errors.post_date_min',
            POST_DATE_REQUIRED: 'customerCareModule.adjustments.errors.post_date_required',
            REASON_CODE_REQUIRED: 'customerCareModule.adjustments.errors.reason_code_required'
        },
        GENERATE_STATEMENTS: {
            ENABLE: 'customerCareModule.adjustments.generateStatements.enable',
            GENERATE_STATEMENTS_LABEL: 'customerCareModule.adjustments.generateStatements.generateStatementsLabel',
            GENERATE_STATEMENTS_DESCRIPTION: 'customerCareModule.adjustments.generateStatements.generateStatementsDescription'
        },
        INVOICE_ID: 'customerCareModule.adjustments.invoice_id',
        INVOICE_ID_TOOLTIP: 'customerCareModule.adjustments.invoice_id_tooltip',
        MAX_AMOUNT_LABEL: 'customerCareModule.adjustments.max_amount_label',
        PARENT_INVOICE_MESSAGE_CREDIT: 'customerCareModule.adjustments.parent_invoice_message_credit',
        PARENT_INVOICE_MESSAGE_DEBIT: 'customerCareModule.adjustments.parent_invoice_message_debit',
        PENDING: 'customerCareModule.adjustments.pending',
        REJECT: 'customerCareModule.adjustments.reject',
        REJECTED: 'customerCareModule.adjustments.rejected',
        POST_DATE: 'customerCareModule.adjustments.post_date',
        REASON_CODE: 'customerCareModule.adjustments.reason_code',
        REQUIRED_FIELD: 'customerCareModule.adjustments.required_field',
        REQUIRED_FIELDS_INDICATOR: 'customerCareModule.adjustments.required_fields_indicator',
        SUBSCRIBER_ITEM: 'customerCareModule.adjustments.subscriber_item',
        SELECT_REASON_CODE: 'customerCareModule.adjustments.select_reason_code'
    },
    ADDITIONAL_INFORMATION: 'customerCareModule.additional_information',
    ADJUSTMENT_REASON: 'customerCareModule.adjustment_reason',
    ADVERTISEMENTS: {
        ADS_COMPLETED: 'customerCareModule.advertisements.ads_completed',
        ADS_INITIATED: 'customerCareModule.advertisements.ads_initiated',
        NAME: 'customerCareModule.advertisements.name',
        QUIT_DURING_AD: 'customerCareModule.advertisements.quit_during_ad'
    },
    ALL: 'customerCareModule.all',
    ALL_ASSOCIATED_FEATURES_WILL_BE_REMOVED: 'customerCareModule.all_associated_features_will_be_removed',
    ALL_ASSOCIATED_FEATURES_WILL_BE_REMOVED_EDIT_OFFER: 'customerCareModule.all_associated_features_will_be_removed_edit_offer',
    ALL_FIELDS_ARE_REQUIRED: 'customerCareModule.all_fields_are_required',
    ALL_ITEMS_WILL_BE_REMOVED: 'customerCareModule.all_items_will_be_removed',
    ALL_OFFERS: 'customerCareModule.all_offers',
    ALL_TIME: 'customerCareModule.all_time',
    ALLOWED: 'allowed',
    ANONYMIZATION: {
        ACTION_LABEL: 'customerCareModule.anonymization.actionLabel',
        CANCEL_ANONYMIZATION_CONFIRMATION_NO: CoreLocaleKeys.NO,
        CANCEL_ANONYMIZATION_CONFIRMATION_YES: CoreLocaleKeys.YES,
        CANCEL_ANONYMIZATION_FAILURE_TITLE: 'customerCareModule.anonymization.cancel_anonymization_failure_title',
        CANCEL_ANONYMIZATION_FAILURE: 'customerCareModule.anonymization.cancel_anonymization_failure',
        CANCEL_ANONYMIZATION_SUCCESS: 'customerCareModule.anonymization.cancel_anonymization_success',
        CANCEL_ANONYMIZATION_TITLE: 'customerCareModule.anonymization.cancel_anonymization_title',
        CANCEL_ANONYMIZATION: 'customerCareModule.anonymization.cancel_anonymization_message',
        COLLAPSE_LABEL: 'customerCareModule.anonymization.collapseLabel',
        EXPAND_LABEL: 'customerCareModule.anonymization.expandLabel',
        EXPANDED_MESSAGE: 'customerCareModule.anonymization.expandedMessage',
        IMMEDIATE_EXPANDED_MESSAGE: 'customerCareModule.anonymization.immediate_expandedMessage',
        IMMEDIATE_MESSAGE: 'customerCareModule.anonymization.immediate_message',
        MESSAGE: 'customerCareModule.anonymization.message'
    },
    ANSWER: 'customerCareModule.answer',
    APPLY_TAX_CERTIFICATE: {
        APPLY_TAX_CERTIFICATE_SUCCESS: 'customerCareModule.apply_tax_certificate.apply_tax_certificate_success',
        APPLY_TAX_CERTIFICATE: 'customerCareModule.apply_tax_certificate.apply_tax_certificate',
        INVOICE_NUMBER: 'customerCareModule.apply_tax_certificate.invoice_number',
        REASON_CODE_REQUIRED: 'customerCareModule.apply_tax_certificate.reason_code_required',
        TAX_AMOUNT: 'customerCareModule.apply_tax_certificate.tax_amount',
        TAX_CERTIFICATE_DATE: 'customerCareModule.apply_tax_certificate.tax_certificate_date',
        WITHHELD_TAX_CERTIFICATE_ID_REQUIRED: 'customerCareModule.apply_tax_certificate.withheld_tax_certificate_id_required',
        WITHHELD_TAX_CERTIFICATE_ID: 'customerCareModule.apply_tax_certificate.withheld_tax_certificate_id',
        WITHHELD_TAX_CERTIFICATE_DATE: 'customerCareModule.apply_tax_certificate.withheld_tax_certificate_date',
        WITHHELD_TAX_CERTIFICATE_DATE_REQUIRED: 'customerCareModule.apply_tax_certificate.withheld_tax_certificate_date_required'
    },
    APPLY: 'uiModule.apply',
    APPROVED_AMOUNT: 'customerCareModule.approved_amount',
    APPROVED_AMOUNT_ABBREVIATION: 'customerCareModule.approved_amount_abbreviation',
    ASCENDING: 'customerCareModule.ascending',
    ASSOCIATED_FEATURES: 'customerCareModule.associated_features',
    ASSOCIATIONS: 'customerCareModule.associations',
    ATTRIBUTES: {
        CONFIRM_ORDER_SETTINGS_WARNING: 'customerCareModule.attributes.confirm_order_settings_warning',
        DELIVERY_OPTIONS: 'customerCareModule.attributes.delivery_options',
        DELIVERY_OPTIONS_INFO_TEXT: 'customerCareModule.attributes.delivery_options_info_text',
        IN_STORE_PICKUP: 'customerCareModule.attributes.in_store_pickup',
        MAKE: 'customerCareModule.attributes.make',
        MAKE_AND_MODEL_REQUIRED: 'customerCareModule.attributes.make_and_model_required',
        MODEL: 'customerCareModule.attributes.model',
        MODEL_REQUIRED: 'customerCareModule.attributes.model_required',
        NO_ATTRIBUTES_FOUND: 'customerCareModule.attributes.no_attributes_found',
        NO_INVENTORY_RESERVATION_FOUND: 'customerCareModule.attributes.no_inventory_reservation_found',
        SERVICE_FEATURES: {
            NO_ADD_ONS: 'customerCareModule.attributes.service_features.no_add_ons',
            NO_ADD_ONS_AVAILABLE: 'customerCareModule.attributes.service_features.no_add_ons_available',
            NO_PRICING_PLANS_AVAILABLE: 'customerCareModule.attributes.service_features.no_pricing_plans_available',
            OVERRIDE_AMOUNT: 'customerCareModule.attributes.service_features.override_amount',
            PRODUCT_OVER_MAXIMUM: 'customerCareModule.attributes.service_features.product_over_maximum'
        },
        SHIP_TO_AN_ADDRESS: 'customerCareModule.attributes.ship_to_an_address',
        TELEPHONE_SELECTION: {
            CHOOSE_YOUR_TELEPHONE_NUMBER: 'customerCareModule.attributes.telephone_selection.choose_your_telephone_number',
            CONTAINS: 'customerCareModule.attributes.telephone_selection.contains',
            CONTAINS_TOOLTIP: 'customerCareModule.attributes.telephone_selection.contains_tooltip',
            CONTAINS_TOOLTIP_WS: 'customerCareModule.attributes.telephone_selection.contains_tooltip_ws',
            DIALOG_TITLE: 'customerCareModule.attributes.telephone_selection.dialog_title',
            NO_MATCH_SEARCH: 'customerCareModule.attributes.telephone_selection.no_match_search',
            PHONE_NUMBER_LIST: 'customerCareModule.attributes.telephone_selection.phone_number_list',
            PHONE_NUMBER_SELECTION: 'customerCareModule.attributes.telephone_selection.phone_number_selection',
            PREFERRED_NUMBER: 'customerCareModule.attributes.telephone_selection.preferred_number',
            PREFERRED_NUMBER_NOTE: 'customerCareModule.attributes.telephone_selection.preferred_number_note',
            PROVINCE: 'customerCareModule.attributes.telephone_selection.province',
            REGION: 'customerCareModule.attributes.telephone_selection.region'
        },
        UNAVAILABLE_SHIPPING_INVENTORY: 'customerCareModule.attributes.unavailable_shipping_inventory',
        VIEW_SHIPPING_OPTIONS: 'customerCareModule.attributes.view_shipping_options'
    },
    AUTO_PAY: {
        ACCOUNT_NUMBER_PROMPT: {
            CONFIRM: 'customerCareModule.auto_pay.account_number_prompt.confirm',
            DENY: 'customerCareModule.auto_pay.account_number_prompt.deny',
            MESSAGE: 'customerCareModule.auto_pay.account_number_prompt.message',
            TITLE: 'customerCareModule.auto_pay.account_number_prompt.title'
        },
        ACTIVATE: 'customerCareModule.auto_pay.activate',
        ACTIVATED: 'customerCareModule.auto_pay.activated',
        ALIGN_RENEWALS: 'customerCareModule.auto_pay.align_renewals',
        APPLY: 'customerCareModule.auto_pay.apply',
        AUTO_PAY: 'customerCareModule.auto_pay.auto_pay',
        AUTO_PAY_ENABLE: 'customerCareModule.auto_pay.auto_pay_enable',
        AUTOPAY: 'customerCareModule.auto_pay.autopay',
        CONFIGURE_AUTO_PAY: 'customerCareModule.auto_pay.configure_auto_pay',
        CONFIGURE_AUTO_PAY_HEADER: 'customerCareModule.auto_pay.configure_auto_pay_header',
        CONFIGURED: 'customerCareModule.auto_pay.configured',
        DEACTIVATION_PROMPT: {
            CONFIRM: 'customerCareModule.auto_pay.deactivation_prompt.confirm',
            DENY: 'customerCareModule.auto_pay.deactivation_prompt.deny',
            MESSAGE: 'customerCareModule.auto_pay.deactivation_prompt.message',
            TITLE: 'customerCareModule.auto_pay.deactivation_prompt.title'
        },
        FAILED_PAYMENT: 'customerCareModule.auto_pay.failed_payment',
        FAILURE: 'customerCareModule.auto_pay.failure',
        INDEFINITE: 'customerCareModule.auto_pay.indefinite',
        NEXT_RENEWAL: 'customerCareModule.auto_pay.next_renewal',
        NO_PAYMENT_INSTRUMENTS: 'customerCareModule.auto_pay.no_payment_instrument',
        NO_RENEWALS_ALIGNED: 'customerCareModule.auto_pay.no_renewals_aligned',
        OVERVIEW_MESSAGE: 'customerCareModule.auto_pay.overview_message',
        OFF: 'customerCareModule.auto_pay.off',
        PAYMENT_TYPE: 'customerCareModule.auto_pay.payment_type',
        SUCCESS: 'customerCareModule.auto_pay.success',
        SUSPEND_UNTIL: 'customerCareModule.auto_pay.suspend_until',
        SUSPENDED: 'customerCareModule.auto_pay.suspended',
        SUSPENDED_UNTIL: 'customerCareModule.auto_pay.suspended_until',
        SUSPENSION: 'customerCareModule.auto_pay.suspension',
        SUSPENSION_REQUIRED: 'customerCareModule.auto_pay.suspension_required',
        UNTIL: 'customerCareModule.auto_pay.until',
        UPDATE: 'customerCareModule.auto_pay.update'
    },
    AUTO_RENEW: {
        DISABLE: 'customerCareModule.auto_renew.disable',
        ENABLE: 'customerCareModule.auto_renew.enable',
        MODAL_TEXT: 'customerCareModule.auto_renew.modal_text',
        MODAL_TEXT_DYNAMIC: 'customerCareModule.auto_renew.modal_text_dynamic',
        MODAL_TEXT_DYNAMIC_OUT: 'customerCareModule.auto_renew.modal_text_dynamic_out',
        MODAL_TEXT_OUT: 'customerCareModule.auto_renew.modal_text_out',
        PRODUCT_EXPIRATION: 'customerCareModule.auto_renew.product_expiration',
        PRODUCT_RENEWAL: 'customerCareModule.auto_renew.product_renewal'
    },
    AVAILABLE_FOR_PAYMENT: 'customerCareModule.available_for_payment',
    AVAILABLE_FOR_USAGE: 'customerCareModule.available_for_usage',
    AVAILABLE_PLANS: 'customerCareModule.available_plans',
    AVAILABLE_SEASONS: 'customerCareModule.available_seasons',
    BACK_TO_STEP: 'customerCareModule.back_to_step',
    BALANCE: 'customerCareModule.balance',
    BANNERS: {
        DETAILED_TAXATION: 'customerCareModule.banners.detailed_taxation'
    },
    BILL_CYCLE: {
        NEXT_BILL_DATE: 'customerCareModule.bill_cycle.next_bill_date',
        PAYMENT_DUE_DATE: 'customerCareModule.bill_cycle.payment_due_date'
    },
    BILLER_RULE_INSTANCE_TYPES: {
        CUSTOM: 'billerRuleInstanceTypes.custom',
        ENTITLEMENT: 'billerRuleInstanceTypes.entitlement',
        FINANCE: 'billerRuleInstanceTypes.finance',
        ONETIME: 'billerRuleInstanceTypes.onetime',
        ONETIME_CHARGE: 'billerRuleInstanceTypes.onetime_charge',
        RECURRING: 'billerRuleInstanceTypes.recurring',
        USAGE: 'billerRuleInstanceTypes.usage'
    },
    BILLING: 'customerCareModule.billing',
    BILLING_AND_PAYMENTS: 'customerCareModule.billing_and_payments',
    BILLING_AND_PAYMENT_INVOICE: {
        ACCOUNT_SUMMARY: 'customerCareModule.billing_and_payment_invoice.account_summary',
        AMOUNT_DUE: 'customerCareModule.billing_and_payment_invoice.amount_due',
        APPLY_ADJUSTMENT: 'customerCareModule.billing_and_payment_invoice.apply_adjustment',
        BALANCE: 'customerCareModule.billing_and_payment_invoice.balance',
        BILLING_FEE: 'customerCareModule.billing_and_payment_invoice.billing_fee',
        CASH: 'customerCareModule.billing_and_payment_invoice.cash',
        CASH_RECEIVABLE: 'customerCareModule.billing_and_payment_invoice.cash_receivable',
        CHARGE: 'customerCareModule.billing_and_payment_invoice.charge',
        CHARGE_PERIOD: 'customerCareModule.billing_and_payment_invoice.charge_period',
        CREDIT_NOTE_NUMBER: 'customerCareModule.billing_and_payment_invoice.credit_note_number',
        DATE_CREATED: 'customerCareModule.billing_and_payment_invoice.date_created',
        DEPOSIT_BALANCE: 'customerCareModule.billing_and_payment_invoice.deposit_balance',
        DEPOSIT: 'customerCareModule.billing_and_payment_invoice.deposit',
        DEPOSITS: 'customerCareModule.billing_and_payment_invoice.deposits',
        DISCOUNT: 'customerCareModule.billing_and_payment_invoice.discount',
        DISCOUNTS: 'customerCareModule.billing_and_payment_invoice.discounts',
        DUE_DATE: 'customerCareModule.billing_and_payment_invoice.due_date',
        ESTIMATED_AMOUNT_DUE: 'customerCareModule.billing_and_payment_invoice.estimated_amount_due',
        ESTIMATED_INVOICE_AMOUNT: 'customerCareModule.billing_and_payment_invoice.estimated_invoice_amount',
        INVOICE: 'customerCareModule.billing_and_payment_invoice.invoice',
        INVOICE_AMOUNT: 'customerCareModule.billing_and_payment_invoice.invoice_amount',
        INVOICE_BALANCE: 'customerCareModule.billing_and_payment_invoice.invoice_balance',
        INVOICE_BILL_DATES: 'customerCareModule.billing_and_payment_invoice.invoice_bill_dates',
        INVOICE_DETAILS: 'customerCareModule.billing_and_payment_invoice.invoice_details',
        INVOICE_HAS_DISPUTE: 'customerCareModule.billing_and_payment_invoice.invoice_has_dispute',
        INVOICE_NUMBER: 'customerCareModule.billing_and_payment_invoice.invoice_number',
        INVOICE_SUMMARY: 'customerCareModule.billing_and_payment_invoice.invoice_summary',
        INVOICE_UNABLE_TO_OPEN: 'customerCareModule.billing_and_payment_invoice.invoice_unable_to_open',
        LATE_PAYMENT_FEE: 'customerCareModule.billing_and_payment_invoice.late_payment_fee',
        LINE_ITEM_HAS_DISPUTE: 'customerCareModule.billing_and_payment_invoice.line_item_has_dispute',
        MAKE_A_PAYMENT: 'customerCareModule.billing_and_payment_invoice.make_a_payment',
        MORE: 'customerCareModule.billing_and_payment_invoice.more',
        NA: 'customerCareModule.billing_and_payment_invoice.na',
        ORIGINAL_CHARGE: 'customerCareModule.billing_and_payment_invoice.original_charge',
        ORIGINAL_PAYMENT_AMOUNT: 'customerCareModule.billing_and_payment_invoice.original_payment_amount',
        PARTIAL_ADJUSTMENT_TOOLTIP: 'customerCareModule.billing_and_payment_invoice.partial_adjustment_tooltip',
        PARTIAL_PAYMENT_TOOLTIP: 'customerCareModule.billing_and_payment_invoice.partial_payment_tooltip',
        PAYMENT_ID: 'customerCareModule.billing_and_payment_invoice.payment_id',
        PERIOD_FORMAT: 'customerCareModule.billing_and_payment_invoice.period_format',
        PERIOD_FORMAT_SINGLE: 'customerCareModule.billing_and_payment_invoice.period_format_single',
        PRORATED: 'customerCareModule.billing_and_payment_invoice.prorated',
        QUICK_INFO: 'customerCareModule.billing_and_payment_invoice.quick_info',
        REFUND: 'customerCareModule.billing_and_payment_invoice.refund',
        SERVICE_NAME: 'customerCareModule.billing_and_payment_invoice.service_name',
        SERVICE_TYPE: 'customerCareModule.billing_and_payment_invoice.service_type',
        STATEMENT: 'customerCareModule.billing_and_payment_invoice.statement',
        SUBSCRIBER_ITEM: 'customerCareModule.billing_and_payment_invoice.subscriber_item',
        TAX_FEE: 'customerCareModule.billing_and_payment_invoice.tax_fee',
        TO_BILLING_PAYMENTS_LIST: 'customerCareModule.to_billing_payments_list',
        TOTAL_CHARGE: 'customerCareModule.billing_and_payment_invoice.total_charge',
        TRANSACTION_ID: 'customerCareModule.billing_and_payment_invoice.transaction_id',
        VIEW_RECEIVABLES: 'customerCareModule.billing_and_payment_invoice.view_receivables',
        VIEW_TRANSACTION_DETAILS: 'customerCareModule.billing_and_payment_invoice.view_transaction_details',
        WITHHELD_TAX: 'customerCareModule.billing_and_payment_invoice.withheld_tax',
        WITHHELD_TAX_CERTIFICATE_DATE: 'customerCareModule.billing_and_payment_invoice.withheld_tax_certificate_date',
        WITHHELD_TAX_CERTIFICATE_ID: 'customerCareModule.billing_and_payment_invoice.withheld_tax_certificate_id'
    },
    BILLING_AND_PAYMENT_TYPES: {
        ADJUSTMENTS: 'customerCareModule.billing_and_payment_types.adjustments',
        ALL: 'customerCareModule.billing_and_payment_types.all',
        CREDIT_NOTE: 'customerCareModule.billing_and_payment_types.credit_note',
        CREDIT_NOTES: 'customerCareModule.billing_and_payment_types.credit_notes',
        DEBIT_NOTE: 'customerCareModule.billing_and_payment_types.debit_note',
        DEBIT_NOTES: 'customerCareModule.billing_and_payment_types.debit_notes',
        INVOICES: 'customerCareModule.billing_and_payment_types.invoices',
        PAYMENTS: 'customerCareModule.billing_and_payment_types.payments'
    },
    BILLING_DISPUTE: 'customerCareModule.billing_dispute',
    BILLING_DUE_DATE: 'customerCareModule.billing_due_date',
    BIRTHDAY: 'customerCareModule.birthday',
    BIRTH_DATE: 'customerCareModule.birth_date',
    BODY: 'customerCareModule.body',
    BULK_SERVICE: 'customerCareModule.bulk_service',
    BULK_SERVICES: {
        ADD_PRICING_PERIOD: 'customerCareModule.bulk_services.add_pricing_period',
        CUSTOM_MESSAGE: 'customerCareModule.bulk_services.customer_message',
        BU_TIMEZONE_MESSAGE: 'customerCareModule.bulk_services.bu_timezone_message',
        NO_SERVICES: 'customerCareModule.bulk_services.no_services',
        PRICING_PERIOD: 'customerCareModule.bulk_services.pricing_period',
        UPDATE_PRICING: 'customerCareModule.bulk_services.update_pricing'
    },
    BUSINESS: 'customerCareModule.business',
    BUSINESS_PHONE: 'customerCareModule.business_phone',
    CALCULATE_TOTALS: 'customerCareModule.calculate_totals',
    CANCEL: 'customerCareModule.cancel',
    CANCEL_ADD: 'customerCareModule.cancel_add',
    CANCEL_CHANGE: 'customerCareModule.cancel_change',
    CANCEL_EDIT: 'customerCareModule.cancel_edit',
    CANCEL_ORDER: 'customerCareModule.cancel_order',
    CANCEL_RESUME: 'customerCareModule.cancel_resume',
    CANCEL_SEND_TO_STORE_ORDER_MESSAGE: 'customerCareModule.cancel_send_to_store_order_message',
    CANCEL_STORE_ORDER_CONFIRMATION_POPUP: {
        CONFIRM: 'customerCareModule.cancel_store_order_confirmation_popup.title',
        DENY: 'customerCareModule.cancel',
        MESSAGE: 'customerCareModule.cancel_store_order_confirmation_popup.message',
        SUCCESS_MESSAGE: 'customerCareModule.cancel_store_order_confirmation_popup.success_message',
        TITLE: 'customerCareModule.cancel_store_order_confirmation_popup.title'
    },
    CART: {
        AMOUNT_NOT_APPLICABLE: 'customerCareModule.cart.amount_not_applicable',
        CREDIT: 'customerCareModule.cart.credit',
        CURRENT_SUBSCRIPTION: 'customerCareModule.cart.current_subscription',
        DBSS_PRODUCTS_SUMMARY_CAVEAT: 'customerCareModule.cart.dbss_products_summary_caveat',
        DISCOUNT_MODAL: {
            AMOUNT: 'customerCareModule.cart.discount_modal.amount',
            AMOUNT_ZERO_WARNING: 'customerCareModule.cart.discount_modal.amount_zero_warning',
            CHARGE_AMOUNT: 'customerCareModule.cart.discount_modal.charge_amount',
            CLEAR_SELECTED_DISCOUNTS: 'customerCareModule.cart.discount_modal.clear_selected_discounts',
            DISCOUNT_AMOUNT: 'customerCareModule.cart.discount_modal.discount_amount',
            DISCOUNT_AVAILABLE: 'customerCareModule.cart.discount_modal.discount_available',
            DISCOUNT_NEW_PRICE: 'customerCareModule.cart.discount_modal.discount_new_price',
            DISCOUNT_REMOVE: 'customerCareModule.cart.discount_modal.discount_remove',
            DISCOUNT_SAVINGS: 'customerCareModule.cart.discount_modal.discount_savings',
            DISCOUNT_TYPE: 'customerCareModule.cart.discount_modal.discount_type',
            DISCOUNT_WARNING: 'customerCareModule.cart.discount_modal.discount_warning',
            DISCRETIONARY: 'customerCareModule.cart.discount_modal.discretionary',
            GLOBAL_DISCOUNT_TOOLTIP_MESSAGE: 'customerCareModule.cart.discount_modal.global_discount_tooltip_message',
            MAXIMUM_REGULAR_DISCOUNT_SELECTION: 'customerCareModule.cart.discount_modal.maximum_regular_discount_selection',
            MAXIMUM_REGULAR_DISCOUNT_SELECTIONS: 'customerCareModule.cart.discount_modal.maximum_regular_discount_selections',
            PERCENTAGE: 'customerCareModule.cart.discount_modal.percentage',
            REASON: 'customerCareModule.cart.discount_modal.reason',
            REGULAR: 'customerCareModule.cart.discount_modal.regular',
            TOTAL_DISCOUNTS: 'customerCareModule.cart.discount_modal.total_discounts'
        },
        DISCOUNTS: 'customerCareModule.cart.discounts',
        DUPLICATE_ITEM_WARNING: 'customerCareModule.cart.duplicate_item_warning',
        EMPTY: 'customerCareModule.cart.empty',
        INVOICE_IMMEDIATELY: 'customerCareModule.cart.invoice_immediately',
        INVOICE_ON_CYCLE: 'customerCareModule.cart.invoice_on_cycle',
        ITEM_ADDED_BACK_TO_CART_SUCCESS: 'customerCareModule.cart.item_added_back_to_cart_success',
        ITEM_NOT_ADDED_GENERIC: 'customerCareModule.cart.item_not_added_generic',
        ITEM_NOT_ADDED_NAMED: 'customerCareModule.cart.item_not_added_named',
        ITEM_REMOVE_SUCCESS: 'customerCareModule.cart.item_remove_success',
        NO_CHANGED_ITEMS: 'customerCareModule.cart.no_changed_items',
        PREVIEW_RENEWAL_STATEMENTS: 'customerCareModule.cart.preview_renewal_statements',
        SUBMIT_UPDATE: 'customerCareModule.cart.submit_update',
        SUMMARY_TITLE: 'customerCareModule.cart.summary_title',
        TAXES_INCLUDED_INDICATOR: 'customerCareModule.cart.taxes_included_indicator',
        UPDATED_SUBSCRIPTION: 'customerCareModule.cart.updated_subscription'
    },
    CART_SUMMARY: {
        CANCEL_CHANGES: 'customerCareModule.cart_summary.cancel_changes',
        CART_CHARGES_MESSAGE: 'customerCareModule.cart_summary.cart_charges_message',
        APPLIED_ON_ACTIVATION: 'customerCareModule.cart_summary.applied_on_activation',
        APPLIED_ON_FIRST_USE: 'customerCareModule.cart_summary.applied_on_first_use',
        DISCONNECT: 'customerCareModule.cart_summary.disconnect',
        DISCOUNTS: 'customerCareModule.cart_summary.discounts',
        DOWN_PAYMENT: 'customerCareModule.order_details.down_payment',
        DUE_TODAY: 'customerCareModule.decisions.due_today',
        INACTIVE_CHARGES_NOTIFICATION: 'customerCareModule.cart_summary.inactive_charges_notification',
        ONE_TIME: 'customerCareModule.cart_summary.one_time',
        QUOTE_ERROR: 'customerCareModule.cart_summary.quote_error',
        QUOTE_PROGRESS: 'customerCareModule.cart_summary.quote_progress',
        QUOTE_SUMMARY: 'customerCareModule.cart_summary.quote_summary',
        QUOTE_WORKING: 'customerCareModule.cart_summary.quote_working',
        RECURRING: 'customerCareModule.cart_summary.recurring',
        REFRESH: 'customerCareModule.cart_summary.refresh',
        REFRESH_CART: 'customerCareModule.cart_summary.refresh_cart',
        REFRESH_CART_INSTRUCTIONS: 'customerCareModule.cart_summary.refresh_cart_instructions',
        SHIPPING: 'customerCareModule.cart_summary.shipping',
        SUB_TOTAL_DUE_NEXT_INVOICE: 'customerCareModule.cart_summary.sub_total_due_next_invoice',
        SUB_TOTAL_DUE_ON_ACTIVATION: 'customerCareModule.cart_summary.sub_total_due_on_activation',
        SUB_TOTAL_DUE_ON_FIRST_USE: 'customerCareModule.cart_summary.sub_total_due_on_first_use',
        SUB_TOTAL_DUE_RECURRING: 'customerCareModule.cart_summary.sub_total_due_recurring',
        SUB_TOTAL_DUE_TODAY: 'customerCareModule.cart_summary.sub_total_due_today',
        TAXES: 'customerCareModule.cart_summary.taxes',
        TAXES_NOT_INCLUDED: 'customerCareModule.cart_summary.taxes_not_included',
        TOTAL_DUE_NEXT_INVOICE: 'customerCareModule.cart_summary.total_due_next_invoice',
        TOTAL_DUE_ON_ACTIVATION: 'customerCareModule.cart_summary.total_due_on_activation',
        TOTAL_DUE_ON_FIRST_USE: 'customerCareModule.cart_summary.total_due_on_first_use',
        TOTAL_DUE_RECURRING: 'customerCareModule.cart_summary.total_due_recurring',
        TOTAL_DUE_TODAY: 'customerCareModule.cart_summary.total_due_today'
    },
    CASE_REMARK_CREATED_SUCCESSFULLY: 'customerCareModule.case_remark_created_successfully',
    CASE: {
        ADD_AMOUNT: 'customerCareModule.add_amount',
        APPROVED_AMOUNT_INVALID: 'customerCareModule.subscriber.approved_amount_invalid',
        CREATED_SUCCESSFULLY: 'customerCareModule.subscriber.case_created_successfully',
        CASE: 'customerCareModule.case.case',
        CASES: 'customerCareModule.case.cases',
        CASE_ACTIVITY: 'customerCareModule.case.case_activity',
        CASE_CLOSED: 'customerCareModule.case.case_closed',
        CASE_DETAILS: 'customerCareModule.case.case_details',
        CASE_REMARKS: 'customerCareModule.case.case_remarks',
        CLOSE: 'customerCareModule.close_case',
        DESCRIPTION_REQUIRED: 'customerCareModule.subscriber.case_description_required',
        DISPUTED_AMOUNT_INVALID: 'customerCareModule.subscriber.disputed_amount_invalid',
        DISPUTED_AMOUNT_REQUIRED: 'customerCareModule.subscriber.disputed_amount_required',
        DISPUTED_NUMBER_INVALID: 'customerCareModule.subscriber.disputed_number_invalid',
        EDIT: 'customerCareModule.edit',
        EDIT_CASE: 'customerCareModule.edit_case',
        INVENTORY_IDENTIFIER: 'customerCareModule.inventory_item',
        INVOICE_NUMBER_INVALID: 'customerCareModule.subscriber.invoice_number_invalid',
        INVOICE_NUMBER_REQUIRED: 'customerCareModule.subscriber.invoice_number_required',
        NO_CASE_REMARKS: 'customerCareModule.no_case_remarks',
        NO_CASE_RESULTS: 'customerCareModule.no_case_result',
        NO_CASE_ACTIVITY: 'customerCareModule.no_case_activity',
        NO_CASE_ACTIVITY_FOR_TYPE: 'customerCareModule.no_case_activity_for_type',
        NO_CONTACT_ON_FILE: 'customerCareModule.no_contact_on_file',
        PORT_IN: {
            ASSOCIATED_ADDRESS: 'customerCareModule.case.port_in.associated_address',
            PROVIDER: 'customerCareModule.case.port_in.provider',
            TELEPHONE_NUMBER: 'customerCareModule.case.port_in.telephone_number',
            TITLE: 'customerCareModule.case.port_in.title'
        },
        PRIORITY_REQUIRED: 'customerCareModule.subscriber.case_priority_required',
        SEVERITY: 'customerCareModule.severity',
        SEVERITY_REQUIRED: 'customerCareModule.subscriber.case_severity_required',
        SUMMARY_REQUIRED: 'customerCareModule.subscriber.case_summary_required',
        STATUS_REQUIRED: 'customerCareModule.status_is_required',
        SUMMARY: 'customerCareModule.summary',
        TO_CASE_LIST: 'customerCareModule.to_case_list',
        TROUBLE_CALL: 'customerCareModule.trouble_call',
        TYPE_REQUIRED: 'customerCareModule.subscriber.case_type_required',
        UPDATED_SUCCESSFULLY: 'customerCareModule.subscriber.case_updated_successfully',
        VIEW_CASE_DETAILS: 'customerCareModule.case.view_case_details'
    },
    CASE_MODAL: {
        SELECT: 'select',
        SEVERITY: 'severity',
        SUMMARY: 'summary'
    },
    CASE_NUMBER: 'customerCareModule.subscriber.case_number',
    CASE_TYPE: 'customerCareModule.subscriber.case_type',
    CATEGORY: 'customerCareModule.category',
    CHANGE: 'customerCareModule.change',
    CHANGE_BILLING_TYPE: 'customerCareModule.change_billing_type',
    CHARGE: 'customerCareModule.charge',
    CHARGE_TIMING: {
        ADDED_NEXT_INVOICE: 'customerCareModule.charge_timing.added_next_invoice',
        DUE_ON_ACTIVATION: 'customerCareModule.charge_timing.due_on_activation',
        DUE_ON_FIRST_USE: 'customerCareModule.charge_timing.due_on_first_use',
        DUE_TODAY: 'customerCareModule.charge_timing.due_today'
    },
    CHECKOUT: {
        ADD_DISCOUNT: 'customerCareModule.checkout.add_discount',
        ADD_SHIPPING_ADDRESS: 'customerCareModule.checkout.add_shipping_address',
        BILL_CYCLE: 'customerCareModule.checkout.bill_cycle',
        BILLING_SCHEDULING: 'customerCareModule.checkout.billing_scheduling',
        BILLING_STARTS: 'customerCareModule.checkout.billing_starts',
        BILLING_DETAILS: 'customerCareModule.checkout.billing_details',
        CHANGES_EFFECTIVE: 'customerCareModule.checkout.changes_effective',
        CHANGE_SHIPPING_ADDRESS: 'customerCareModule.checkout.change_shipping_address',
        CONTRACT_ID: 'customerCareModule.checkout.contract_id',
        CONTRACT_ID_TOOLTIP: 'customerCareModule.checkout.contract_id_tooltip',
        DEVICE_PAYMENTS: 'customerCareModule.checkout.device_payments',
        DISCRETIONARY_DISCOUNT: 'customerCareModule.checkout.discretionary_discount',
        DOWN_PAYMENTS_SCHEDULING_AND_BILLING: 'customerCareModule.checkout.down_payments_scheduling_and_billing',
        DOWN_PAYMENT_AMOUNT_VALIDATION_MESSAGE: 'customerCareModule.checkout.down_payment_amount_validation_message',
        DOWN_PAYMENT_LABEL: 'customerCareModule.checkout.down_payment_label',
        DOWN_PAYMENT_TOOLTIP: 'customerCareModule.checkout.down_payment_tooltip',
        EDIT: 'customerCareModule.checkout.edit',
        EDIT_CHECKOUT_DETAILS: 'customerCareModule.checkout.edit_checkout_details',
        EDITABLE_AFTER_TOTALS_CALCULATED: 'customerCareModule.checkout.editable_after_totals_calculated',
        FULL_PRICE: 'customerCareModule.checkout.full_price',
        GIFT_MESSAGE: 'customerCareModule.checkout.gift_message',
        GIFTING_OPTIONS: 'customerCareModule.checkout.gifting_options',
        HAS_RENEWALS_NON_DEFAULT_PAYMENT_INSTRUMENT: 'customerCareModule.checkout.has_renewals_non_default_payment_instrument',
        INCLUDES_TAX: 'customerCareModule.checkout.includes_tax',
        INVOICE_OPTIONS: 'customerCareModule.checkout.invoice_options',
        NOTIFICATION_DATE: 'customerCareModule.checkout.notification_date',
        ORDER_SCHEDULED_FOR: 'customerCareModule.checkout.order_scheduled_for',
        PAY_IN_FULL: 'customerCareModule.checkout.pay_in_full',
        PAY_PER_TERM: 'customerCareModule.checkout.pay_per_term',
        PAYMENT_INFO: 'customerCareModule.checkout.payment_info',
        PO_NUMBER: 'customerCareModule.checkout.po_number',
        PO_NUMBER_TOOLTIP: 'customerCareModule.checkout.po_number_tooltip',
        PRODUCT_NOT_GIFTABLE: 'customerCareModule.checkout.product_not_giftable',
        RECIPIENT_NAME: 'customerCareModule.checkout.recipient_name',
        REMOVE_ALL: 'customerCareModule.checkout.remove_all',
        REQUIRED_FIELDS: 'customerCareModule.checkout.required_fields',
        RETAIL_PRICE: 'customerCareModule.checkout.retail_price',
        RETRIEVE_BILL_CYCLE_DETAILS_ERROR: 'customerCareModule.checkout.retrieve_bill_cycle_details_error',
        SAVE_FOR_FUTURE_USE: 'customerCareModule.checkout.save_for_future_use',
        SCHEDULING_AND_BILLING: 'customerCareModule.checkout.scheduling_and_billing',
        SELECT_BILL_CYCLE_DIALOG_TITLE: 'customerCareModule.checkout.select_bill_cycle_dialog_title',
        SEND_AS_GIFT: 'customerCareModule.checkout.send_as_gift',
        SHIPPING: 'customerCareModule.checkout.shipping',
        SHIPPING_METHOD_EMPTY_STATE: 'customerCareModule.checkout.shipping_method_empty_state',
        SHIPPING_SAME_AS_BILLING: 'customerCareModule.checkout.shipping_same_as_billing',
        TAXES: 'customerCareModule.checkout.taxes',
        TERM_AMOUNT_PLURAL: 'customerCareModule.checkout.term_amount_plural',
        TERM_AMOUNT: 'customerCareModule.checkout.term_amount'
    },
    CITY: 'city',
    CITY_STATE_ZIP: 'customerCareModule.city_state_zip',
    COMPLAINT: 'customerCareModule.complaint',
    CITY_REQUIRED: 'city_required',
    CLEAR: 'customerCareModule.clear',
    CLEAR_SAVED_CART_CONFIRMATION_POPUP: {
        CONFIRM: 'customerCareModule.clear_saved_cart_confirmation_popup.title',
        DENY: 'customerCareModule.cancel',
        MESSAGE: 'customerCareModule.clear_saved_cart_confirmation_popup.message',
        SUCCESS_MESSAGE: 'customerCareModule.clear_saved_cart_confirmation_popup.success_message',
        TITLE: 'customerCareModule.clear_saved_cart_confirmation_popup.title'
    },
    CLOSE: 'customerCareModule.close',
    COLLAPSE_ALL: 'customerCareModule.collapse_all',
    COMMERCIAL: 'customerCareModule.commercial',
    COMMERCIAL_CUSTOMER: 'customerCareModule.commercial_customer',
    COMMUNITY_CONTRIBUTION: 'customerCareModule.community_contribution',
    COMPANY: 'customerCareModule.company',
    COMPANY_NAME: 'company_name',
    COMPLETE_ORDER: 'customerCareModule.complete_order',
    COMPLETED: 'customerCareModule.completed',
    CONFIRM: 'customerCareModule.confirm',
    CONTACT_ADMIN_FOR_CONFIG: 'customerCareModule.contact_admin_for_config',
    CONTACT_INFORMATION: 'customerCareModule.contact_information',
    CONTENT_ACTIVITY: {
        CANCEL_DOWNLOAD: {
            DIALOG: {
                MESSAGE: 'customerCareModule.content_activity.cancel_download.dialog.message',
                NO: CoreLocaleKeys.NO,
                TITLE: 'customerCareModule.content_activity.cancel_download.dialog.title',
                YES: CoreLocaleKeys.YES
            },
            DISPLAY_TEXT: 'customerCareModule.content_activity.cancel_download.display_text',
            TERMINATED: 'customerCareModule.content_activity.cancel_download.terminated'
        },
        CANCEL_STREAM: {
            DIALOG: {
                MESSAGE: 'customerCareModule.content_activity.cancel_stream.dialog.message',
                NO: CoreLocaleKeys.NO,
                TITLE: 'customerCareModule.content_activity.cancel_stream.dialog.title',
                YES: CoreLocaleKeys.YES
            },
            DISPLAY_TEXT: 'customerCareModule.content_activity.cancel_stream.display_text',
            TERMINATED: 'customerCareModule.content_activity.cancel_stream.terminated'
        },
        CATCHUP: 'customerCareModule.content_activity.catchup',
        CHANNEL: {
            ID: 'customerCareModule.content_activity.channel.id',
            NAME: 'customerCareModule.content_activity.channel.name',
        },
        CONTENT_TYPE: 'customerCareModule.content_activity.content_type',
        DELIVERY_CAPABILITY: {
            NAME: 'customerCareModule.content_activity.delivery_capability.name'
        },
        DEVICE_ID: 'customerCareModule.content_activity.device_id',
        DISTRIBUTION_CHANNEL: 'customerCareModule.content_activity.distribution_channel',
        DOWNLOAD: 'customerCareModule.content_activity.download',
        DOWNLOAD_COMPLETED_ON: 'customerCareModule.content_activity.download_completed_on',
        DURATION: {
            LABEL: 'customerCareModule.content_activity.duration.label',
            TITLE: 'customerCareModule.content_activity.duration.title',
            UNITS: {
                HOUR: 'uiModule.units.hour_short',
                MINUTE: 'uiModule.units.minute_short'
            }
        },
        FAILURE_TIME: 'customerCareModule.content_activity.failure_time',
        FAILURE_REASON: 'customerCareModule.content_activity.failure_reason',
        FAILURE_REASON_CODE: 'customerCareModule.content_activity.failure_reason_code',
        FILTER: {
            ACTIVE: 'customerCareModule.content_activity.filter.active',
            ARCHIVED: 'customerCareModule.content_activity.filter.archived',
            DEVICE_NAME: 'customerCareModule.content_activity.filter.device_name',
            PRODUCT_NAME: 'customerCareModule.content_activity.filter.product_name'
        },
        FIRST_PLAYED_ON: 'customerCareModule.content_activity.first_played_on',
        FRIENDLY_PROGRAM_NAME: 'customerCareModule.content_activity.friendly_program_name',
        HEADING: 'customerCareModule.content_activity.heading',
        IP_ADDRESS: 'customerCareModule.content_activity.ip_address',
        LIVE: 'customerCareModule.content_activity.live',
        NO_CONTENT_ACTIVITY: {
            FILTERED: 'customerCareModule.content_activity.no_content_activity.filtered',
            GENERAL: 'customerCareModule.content_activity.no_content_activity.general'
        },
        PRICING_PLAN_ID: 'customerCareModule.content_activity.pricing_plan_id',
        PRODUCT: {
            NAME: 'customerCareModule.content_activity.product.name',
            NOT_FOUND: 'customerCareModule.content_activity.product.not_found',
            PRODUCT: 'customerCareModule.content_activity.product.product'
        },
        REFERENCE_ID: 'customerCareModule.content_activity.reference_id',
        REPLAY: 'customerCareModule.content_activity.replay',
        RETURN_TO: 'customerCareModule.content_activity.return_to',
        SERIES_NAME: 'customerCareModule.content_activity.series_name',
        SESSION_ID: 'customerCareModule.content_activity.session_id',
        SHOW: 'customerCareModule.content_activity.show',
        START_OVER: 'customerCareModule.content_activity.start_over',
        START_TIME: 'customerCareModule.content_activity.start_time',
        STREAM: 'customerCareModule.content_activity.stream'
    },
    CONTRACTS: {
        ACCEPT_CONTRACT_TERMS_INVALID: 'customerCareModule.contracts.accept_contract_terms_invalid',
        ADD_CONTRACT: 'customerCareModule.contracts.add_contract',
        CHANGE_CONTRACT: 'customerCareModule.contracts.change_contract',
        CONTRACTS_HEADER: 'customerCareModule.contracts.contracts_header',
        CONTRACTS_SUB_HEADER: 'customerCareModule.contracts.contracts_sub_header',
        EXCLUDES_DISCOUNTS: 'customerCareModule.contracts.excludes_discounts',
        NO_CONTRACT_SELECTED: 'customerCareModule.contracts.no_contract_selected',
        SEARCH_TEXT_PLACEHOLDER: 'customerCareModule.contracts.search_text_placeholder',
        SELECT_CONTRACT: 'customerCareModule.contracts.select_contract',
        SELECT_CONTRACT_MODAL: {
            END_OF_CONTRACT: 'customerCareModule.contracts.select_contract_modal.end_of_contract',
            GRACE_PERIOD: 'customerCareModule.contracts.select_contract_modal.grace_period',
            LENGTH: 'customerCareModule.contracts.select_contract_modal.length',
            MINIMUM_SPEND: 'customerCareModule.contracts.select_contract_modal.minimum_spend',
            NO_CONTRACTS: 'customerCareModule.contracts.select_contract_modal.no_contracts',
            PAYMENT_TERMS: 'customerCareModule.contracts.select_contract_modal.payment_terms',
            TERMS_AND_CONDITIONS: 'customerCareModule.contracts.select_contract_modal.terms_and_conditions',
            TITLE: 'customerCareModule.contracts.select_contract_modal.title'
        },
        SUMMARY_DISCOUNT_TOOLTIP: 'customerCareModule.contracts.summary_discount_tooltip'
    },
    CONTINUE: 'customerCareModule.continue',
    CONTINUE_SHOPPING: 'customerCareModule.continue_shopping',
    CONTINUE_TO_STEP: 'customerCareModule.continue_to_step',
    COUNTRY: 'country',
    COUNTRY_REQUIRED: 'country_required',
    CONVERT_TO_CREDIT: 'customerCareModule.convert_to_credit',
    CONVERT_TO_CREDIT_SUCCESS: 'customerCareModule.convert_to_credit_success',
    COUPON: 'uiModule.coupon',
    COUPONS: {
        REDEMPTION_CODE_DETAILS: {
            TAB_LABELS: {
                GENERAL: 'customerCareModule.coupons.redemption_code_details.tab_labels.general',
                ASSOCIATIONS: 'customerCareModule.coupons.redemption_code_details.tab_labels.associations',
                REDEMPTIONS: 'customerCareModule.coupons.redemption_code_details.tab_labels.redemptions'
            },
            HEADINGS: {
                CODE: 'customerCareModule.coupons.redemption_code_details.headings.code',
                COUPON_OVERVIEW: 'customerCareModule.coupons.redemption_code_details.headings.coupon_overview',
                DISCOUNT_OVERVIEW: 'customerCareModule.coupons.redemption_code_details.headings.discount_overview'
            },
            MODALS: {
                CLEAR_DATE_INPUT: 'customerCareModule.coupons.redemption_code_details.modals.clear_date_input',
                REMOVE_TITLE: 'customerCareModule.coupons.redemption_code_details.modals.remove_title',
                CONFIRM_BUTTON_LABEL: 'customerCareModule.coupons.redemption_code_details.modals.confirm_button_label',
                FORM_FIELD_REASON_LABEL: 'customerCareModule.coupons.redemption_code_details.modals.form_field_reason_label',
                ERRORS: {
                    CANNOT_REMOVE_REDEMPTION_CODE: 'customerCareModule.coupons.redemption_code_details.modals.errors.cannot_remove_redemption_code',
                    CANNOT_RESEND_REDEMPTION_CODE: 'customerCareModule.coupons.redemption_code_details.modals.errors.cannot_resend_redemption_code',
                    REMOVE_REASON_REQUIRED: 'customerCareModule.coupons.redemption_code_details.modals.errors.remove_reason_required',
                },
                ZERO_ASSOCIATIONS_SECONDARY: 'customerCareModule.coupons.redemption_code_details.modals.zero_associations_secondary',
                ZERO_ASSOCIATIONS_TITLE: 'customerCareModule.coupons.redemption_code_details.modals.zero_associations_title'
            },
            LABELS: {
                COUPON_TYPES: {
                    ACTIVE: 'customerCareModule.coupons.redemption_code_details.labels.coupon_types.active',
                    REMOVED: 'customerCareModule.coupons.redemption_code_details.labels.coupon_types.removed',
                    PENDING_APPROVAL: 'customerCareModule.coupons.redemption_code_details.labels.coupon_types.pending_approval',
                    SUSPENDED: 'customerCareModule.coupons.redemption_code_details.labels.coupon_types.suspended'
                },
                DISCOUNT_TYPES: {
                    PERCENTAGE_OFF: 'customerCareModule.coupons.redemption_code_details.labels.discount_types.percentage_off',
                    AMOUNT_OFF: 'customerCareModule.coupons.redemption_code_details.labels.discount_types.amount_off'
                },
                IS_REDEEMABLE: 'customerCareModule.coupons.redemption_code_details.labels.is_redeemable',
                REMOVE_SUCCESS_MESSAGE: 'customerCareModule.coupons.redemption_code_details.labels.remove_success_message',
                RESEND_SUCCESS_MESSAGE: 'customerCareModule.coupons.redemption_code_details.labels.resend_success_message',
                SUBSCRIBER_USE_COUNT: 'customerCareModule.coupons.redemption_code_details.labels.subscriber_use_count',
                EXPIRATION_DATE: 'customerCareModule.coupons.redemption_code_details.labels.expiration_date',
                SUBSCRIBER_ID: 'customerCareModule.coupons.redemption_code_details.labels.subscriber_id',
                COUPON_STATUS: 'customerCareModule.coupons.redemption_code_details.labels.coupon_status',
                COUPON_NAME: 'customerCareModule.coupons.redemption_code_details.labels.coupon_name',
                COUPON_ID: 'customerCareModule.coupons.redemption_code_details.labels.coupon_overview_id',
                TRANSFERABLE: 'customerCareModule.coupons.redemption_code_details.labels.transferable',
                BEGIN_DATE: 'customerCareModule.coupons.redemption_code_details.labels.begin_date',
                END_DATE: 'customerCareModule.coupons.redemption_code_details.labels.end_date',
                SUBSCRIBER_MAX_REDEMPTIONS: 'customerCareModule.coupons.redemption_code_details.labels.subscriber_max_redemptions',
                TOTAL_REDEMPTIONS: 'customerCareModule.coupons.redemption_code_details.labels.total_redemptions',
                MAX_REDEMPTIONS: 'customerCareModule.coupons.redemption_code_details.labels.max_redemptions',
                DISCOUNT_NAME: 'customerCareModule.coupons.redemption_code_details.labels.discount_name',
                DISCOUNT_TYPE: 'customerCareModule.coupons.redemption_code_details.labels.discount_type',
                DISCOUNT_AMOUNT: 'customerCareModule.coupons.redemption_code_details.labels.discount_amount',
                DISCOUNT_RENEWAL_PERIODS: 'customerCareModule.coupons.redemption_code_details.labels.discount_renewal_periods',
                UPDATE_EXPIRATION_DATE_SUCCESS_MESSAGE: 'customerCareModule.coupons.redemption_code_details.labels.update_expiration_date_success_message'
            },
            MORE_OPTIONS: {
                RESEND_REDEMPTION_CODE: 'customerCareModule.coupons.redemption_code_details.more_options.resend_redemption_code',
                REMOVE_REDEMPTION_CODE: 'customerCareModule.coupons.redemption_code_details.more_options.remove_redemption_code'
            }
        },
        CLAIMED: 'customerCareModule.coupons.claimed',
        COUPON: 'customerCareModule.coupons.coupon',
        COUPONS: 'customerCareModule.coupons.coupons',
        COUPON_CODE: 'customerCareModule.coupons.coupon_code',
        COUPON_ID: 'customerCareModule.coupons.coupon_id',
        COUPON_GRANTED: 'customerCareModule.coupons.coupon_granted',
        DATE_ADDED: 'customerCareModule.coupons.date_added',
        DATE_GRANTED: 'customerCareModule.coupons.date_granted',
        ENTER_REDEMPTION_CODE_OR_NAME: 'customerCareModule.coupons.enter_redemption_code_or_name',
        EXPIRATION_DATE: 'customerCareModule.coupons.expiration_date',
        FILTER_BY_STATUS: 'customerCareModule.coupons.filter_by_status',
        GRANT: 'customerCareModule.coupons.grant',
        GRANTED_COUPONS_EMPTY_STATE_MESSAGE: 'customerCareModule.coupons.granted_coupons_empty_state_message',
        GRANT_COUPON: 'customerCareModule.coupons.grant_coupon',
        GRANT_COUPON_TIMES: 'customerCareModule.coupons.grant_coupon_times',
        GRANT_COUPON_FAILED: 'customerCareModule.coupons.grant_coupon_failed',
        GRANT_COUPONS_EMPTY_STATE_MESSAGE: 'customerCareModule.coupons.grant_coupons_empty_state_message',
        GRANT_DATE: 'customerCareModule.coupons.grant_date',
        GRANT_ONE_NOW: 'customerCareModule.coupons.grant_one_now',
        LAST_REDEEMED: 'customerCareModule.coupons.last_redeemed',
        NOT_REDEEMED: 'customerCareModule.coupons.not_redeemed',
        OPEN_COUPON_TITLE: 'customerCareModule.coupons.open_coupon_title',
        OPEN_COUPON_DESCRIPTION: 'customerCareModule.coupons.open_coupon_description',
        PRODUCT_PRICING_PLAN: 'customerCareModule.coupons.product_pricing_plan',
        REDEMPTIONS_LIST_EMPTY_STATE_MESSAGE: 'customerCareModule.coupons.redemptions_list_empty_state_message',
        REDEMPTION_CODE: 'customerCareModule.coupons.redemption_code',
        REDEMPTION_DATE: 'customerCareModule.coupons.redemption_date',
        REDEEMED: 'customerCareModule.coupons.redeemed',
        REDEEMER: 'customerCareModule.coupons.redeemer',
        RESEND_REDEMPTION_CODE: {
            DIALOG_HEADING: 'customerCareModule.coupons.resend_redemption_code.dialog_heading',
            RECIPIENT_NAME: 'customerCareModule.coupons.resend_redemption_code.recipient_name',
            RESEND_CODE: 'customerCareModule.coupons.resend_redemption_code.resend_code',
            RESEND_COUPON_NOTIFICATION: 'customerCareModule.coupons.resend_redemption_code.resend_coupon_notification'
        },
        RETURN_TO_GRANTED_COUPONS: 'customerCareModule.coupons.return_to_granted_coupons',
        RETURN_TO_COUPON_DETAILS: 'customerCareModule.coupons.return_to_coupon_details',
        SEND_NOTIFICATION: 'customerCareModule.coupons.send_notification',
        SEARCH_ASSOCIATIONS: 'customerCareModule.coupons.search_associations',
        TIMES: 'customerCareModule.coupons.times'
    },
    COUPON_TOOLTIP: 'customerCareModule.coupon_tooltip',
    CREATED: 'customerCareModule.created',
    CREATE_A_NEW_CUSTOMER: 'customerCareModule.create_a_new_customer',
    CREATED_BY: 'created_by',
    CREATED_DATE: 'created_date',
    CREATE_ACTIVITY: 'customerCareModule.create_activity',
    CREATE_CASE: 'customerCareModule.create_case',
    CREATE_CASE_WITH_TYPE: 'customerCareModule.create_case_with_type',
    CREATE_COMMERCIAL_CHILD: 'customerCareModule.create_commercial_child',
    CREATE_COMMERCIAL_CHILD_SUCCESS: 'customerCareModule.create_commercial_child_success',
    CREATE_CUSTOMER: 'customerCareModule.create_customer',
    CREATE_CUSTOMER_ERROR: 'customerCareModule.create_customer_error',
    CREATE_CUSTOMER_FORM: {
        ACCEPTING_FOR: 'customerCareModule.create_customer_form.accepting_for',
        ACCEPTING_FOR_OPTION_CHILD: 'customerCareModule.create_customer_form.accepting_for_option_child',
        ACCEPTING_FOR_OPTION_MYSELF: 'customerCareModule.create_customer_form.accepting_for_option_myself',
        ACCEPTING_TERMS: 'customerCareModule.create_customer_form.accepting_terms',
        ACCEPTING_TERMS_TEXT: 'customerCareModule.create_customer_form.accepting_terms_text',
        ADD_IDENTIFICATION: 'customerCareModule.create_customer_form.add_identification',
        ADDRESS_INFORMATION: 'customerCareModule.create_customer_form.address_information',
        BIRTH_DATE: 'customerCareModule.create_customer_form.birth_date',
        CONTACT_INFORMATION: 'customerCareModule.create_customer_form.contact_information',
        CONTACT_PREFERENCES: {
            CONTACT_METHODS_EMPTY_PLACEHOLDER: 'customerCareModule.create_customer_form.contact_preferences.contact_methods_empty_placeholder',
            PREFERRED_CONTACT_METHOD: 'customerCareModule.create_customer_form.contact_preferences.preferred_contact_method'
        },
        COUNTRY_PLACEHOLDER: 'customerCareModule.create_customer_form.country_placeholder',
        CREDIT_CHECK_ADDRESS: 'customerCareModule.create_customer_form.credit_check_address',
        CUSTOM_SECURITY_QUESTION: 'customerCareModule.create_customer_form.custom_security_question',
        CUSTOMER_INFORMATION: 'customerCareModule.create_customer_form.customer_information',
        CUSTOMER_TYPE: 'customerCareModule.create_customer_form.customer_type',
        DATA_PERMISSION_TERMS: 'customerCareModule.create_customer_form.data_permission_terms',
        ELIGIBLE_FOR_DOMESTIC_REVERSE_CHARGE: 'customerCareModule.create_customer_form.eligible_for_domestic_reverse_charge',
        ELIGIBLE_FOR_DOMESTIC_REVERSE_CHARGE_TOOLTIP: 'customerCareModule.create_customer_form.eligible_for_domestic_reverse_charge_tooltip',
        ELIGIBLE_FOR_WITHHOLDING_TAX: 'customerCareModule.create_customer_form.eligible_for_withholding_tax',
        ELIGIBLE_FOR_WITHHOLDING_TAX_TOOLTIP: 'customerCareModule.create_customer_form.eligible_for_withholding_tax_tooltip',
        ENABLE: 'customerCareModule.create_customer_form.enable',
        ERRORS: {
            ACCEPTING_FOR_REQUIRED: 'customerCareModule.create_customer_form.errors.accepting_for_required',
            ACCEPTING_TERMS_REQUIRED: 'customerCareModule.create_customer_form.errors.accepting_terms_required',
            BIRTH_DATE_REQUIRED: 'customerCareModule.create_customer_form.errors.birth_date_required',
            BIRTH_DATE_INVALID: 'customerCareModule.create_customer_form.errors.birth_date_invalid',
            BIRTH_DATE_OUT_OF_RANGE: 'customerCareModule.create_customer_form.errors.birth_date_out_of_range',
            BIRTH_DATE_RANGE_ERROR: 'customerCareModule.create_customer_form.errors.birth_date_range_error',
            BUSINESS_PHONE_REQUIRED: 'customerCareModule.create_customer_form.errors.business_phone_required',
            COMPANY_NAME_REQUIRED: 'customerCareModule.create_customer_form.errors.company_name_required',
            CUSTOMER_TYPE_REQUIRED: 'customerCareModule.create_customer_form.errors.customer_type_required',
            DRIVERS_LICENSE_NUM_REQUIRED: 'customerCareModule.create_customer_form.errors.drivers_license_num_required',
            DRIVERS_LICENSE_STATE_REQUIRED: 'customerCareModule.create_customer_form.errors.drivers_license_state_required',
            EMAIL_INVALID_LENGTH: 'customerCareModule.create_customer_form.errors.email_invalid_length',
            EXTERNAL_ID_EMPTY: 'customerCareModule.create_customer_form.errors.external_id_empty',
            EMAIL_INVALID_PATTERN: 'customerCareModule.create_customer_form.errors.email_invalid_pattern',
            EMAIL_REQUIRED: 'customerCareModule.create_customer_form.errors.email_required',
            EXTERNAL_ID_INVALID_PATTERN: 'customerCareModule.create_customer_form.errors.external_id_invalid_pattern',
            FIRST_NAME_REQUIRED: 'customerCareModule.create_customer_form.errors.first_name_required',
            GENDER_REQUIRED: 'customerCareModule.create_customer_form.errors.gender_required',
            HOME_PHONE_REQUIRED: 'customerCareModule.create_customer_form.errors.home_phone_required',
            IS_REQUIRED: 'customerCareModule.create_customer_form.errors.is_required',
            IS_INVALID: 'customerCareModule.create_customer_form.errors.is_invalid',
            INCOME_LEVEL_TYPE_REQUIRED: 'customerCareModule.create_customer_form.errors.income_level_type_required',
            LANGUAGE_REQUIRED: 'customerCareModule.create_customer_form.errors.language_required',
            LAST_NAME_REQUIRED: 'customerCareModule.create_customer_form.errors.last_name_required',
            LEAD_SOURCE_TYPE_REQUIRED: 'customerCareModule.create_customer_form.errors.lead_source_type_required',
            LEGAL_GUARDIAN_EMAIL_INVALID_LENGTH: 'customerCareModule.create_customer_form.errors.legal_guardian_email_invalid_length',
            LEGAL_GUARDIAN_EMAIL_INVALID_PATTERN: 'customerCareModule.create_customer_form.errors.legal_guardian_email_invalid_pattern',
            LEGAL_GUARDIAN_EMAIL_REQUIRED: 'customerCareModule.create_customer_form.errors.legal_guardian_email_required',
            LOGIN_REQUIRED: 'customerCareModule.create_customer_form.errors.login_required',
            MOBILE_PHONE_REQUIRED: 'customerCareModule.create_customer_form.errors.mobile_phone_required',
            PIN_INVALID_PATTERN: 'customerCareModule.create_customer_form.errors.pin_invalid_pattern',
            PIN_REQUIRED: 'customerCareModule.create_customer_form.errors.pin_required',
            RACE_REQUIRED: 'customerCareModule.create_customer_form.errors.race_required',
            SECURITY_ANSWER_PATTERN: 'customerCareModule.create_customer_form.errors.security_answer_pattern',
            SECURITY_ANSWER_REQUIRED: 'customerCareModule.create_customer_form.errors.security_answer_required',
            SECURITY_QUESTION_REQUIRED: 'customerCareModule.create_customer_form.errors.security_question_required',
            SEND_COPY_OF_TERMS_INVALID_LENGTH: 'customerCareModule.create_customer_form.errors.send_copy_of_terms_invalid_length',
            SEND_COPY_OF_TERMS_INVALID_PATTERN: 'customerCareModule.create_customer_form.errors.send_copy_of_terms_invalid_pattern',
            SSN_INVALID: 'customerCareModule.create_customer_form.errors.ssn_invalid',
            SSN_REQUIRED: 'customerCareModule.create_customer_form.errors.ssn_required',
            SUBSCRIBER_SUBTENANT_REQUIRED: 'customerCareModule.create_customer_form.errors.subscriber_subtenant_required',
            SUBSCRIBER_VERIFICATION_INVALID: 'customerCareModule.create_customer_form.errors.subscriber_verification_invalid',
            SUBSCRIBER_VERIFICATION_REQUIRED: 'customerCareModule.create_customer_form.errors.subscriber_verification_required',
            SUBSCRIBER_VERIFICATION_VALUE_INVALID: 'customerCareModule.create_customer_form.errors.subscriber_verification_pattern',
            TAX_TYPE_LEVEL_INVALID: 'customerCareModule.create_customer_form.errors.tax_type_level_invalid'
        },
        IDENTITY_VERIFICATION_TYPE: 'customerCareModule.create_customer_form.identity_verification_type',
        IDENTITY_VERIFICATION_VALUE: 'customerCareModule.create_customer_form.identity_verification_value',
        IDENTITY_VERIFICATIONS: 'customerCareModule.create_customer_form.identity_verifications',
        LEGAL_GUARDIAN_EMAIL: 'customerCareModule.create_customer_form.legal_guardian_email',
        NOTIFICATION_PREFERENCES: 'customerCareModule.create_customer_form.notification_preferences',
        OVERRIDE: 'customerCareModule.create_customer_form.override',
        SEND: 'customerCareModule.create_customer_form.send',
        SEND_COPY_OF_TERMS: 'customerCareModule.create_customer_form.send_copy_of_terms',
        SENT: 'customerCareModule.create_customer_form.sent',
        STATE_PLACEHOLDER: 'customerCareModule.create_customer_form.state_placeholder',
        STATUSES: {
            ACTIVE: 'customerCareModule.create_customer_form.statuses.active',
            PENDING_APPROVAL: 'customerCareModule.create_customer_form.statuses.pending_approval',
            REMOVED: 'customerCareModule.create_customer_form.statuses.removed',
            SUSPENDED: 'customerCareModule.create_customer_form.statuses.suspended'
        },
        SUCCESS: 'customerCareModule.create_customer_form.success',
        TEXT_SCRUBBER: 'customerCareModule.create_customer_form.text_scrubber',
        USE_DIFFERENT_ADDRESS_FOR_CREDIT_CHECK: 'customerCareModule.create_customer_form.use_different_address_for_credit_check'
    },
    CREATE_CHILD: 'customerCareModule.create_child',
    CREATE_CHILD_SUCCESS: 'customerCareModule.create_child_success',
    CREATE_HIERARCHY: 'customerCareModule.create_hierarchy',
    CREATE_NEW_HIERARCHY: 'customerCareModule.create_new_hierarchy',
    CREATE_NEW_CHILD: 'customerCareModule.create_new_child',
    CREATE_NEW_COMMERCIAL_CUSTOMER: 'customerCareModule.create_new_commercial_customer',
    CREATE_NEW_CUSTOMER: 'customerCareModule.create_new_customer',
    CREATE_NEW_RESIDENTIAL_CUSTOMER: 'customerCareModule.create_new_residential_customer',
    CREATE_PIN: 'customerCareModule.create_pin',
    CREATE_REMARK: {
        BODY_REQUIRED_ERROR: 'customerCareModule.create_remark.body_required_error',
        CANCEL: 'customerCareModule.create_remark.cancel',
        COMMENTS: 'customerCareModule.create_remark.comments',
        DELETE: 'customerCareModule.create_remark.delete',
        EDIT_REMARK: 'customerCareModule.create_remark.edit_remark',
        EXPIRES: 'customerCareModule.create_remark.expires',
        HIGH: 'customerCareModule.create_remark.high',
        LOW: 'customerCareModule.create_remark.low',
        MEDIUM: 'customerCareModule.create_remark.medium',
        NEW_REMARK: 'customerCareModule.create_remark.new_remark',
        PRIORITY: 'customerCareModule.create_remark.priority',
        REQUIRED_FIELD: 'required_field_indication',
        SAVE_REMARK: 'customerCareModule.create_remark.save_remark',
        SUBJECT: 'customerCareModule.create_remark.subject',
        SUBJECT_REQUIRED_ERROR: 'customerCareModule.create_remark.subject_required_error',
        SUCCESS: 'customerCareModule.create_remark.success',
        UPDATE_SUCCESS: 'customerCareModule.create_remark.update_success'
    },
    CREATE_RESIDENTIAL_CHILD: 'customerCareModule.create_residential_child',
    CREDIT: 'customerCareModule.credit',
    CREDIT_CARD: 'customerCareModule.credit_card',
    CREDIT_CARD_EXPIRATION_DATE: 'customerCareModule.credit_card_expiration_date',
    CREDIT_CHECK: {
        CREDIT_CHECK_LABEL: 'customerCareModule.credit_check.credit_check_label',
        COMMENT: 'customerCareModule.credit_check.comment',
        CREDIT_CLASS_ASSIGNED: 'customerCareModule.credit_check.credit_class_assigned',
        CREDIT_CLASS_LABEL: 'customerCareModule.credit_check.credit_class_label',
        CREDIT_CLASS_LIMIT: 'customerCareModule.credit_check.credit_class_limit',
        CREDIT_INFORMATION_SECTION_TITLE: 'customerCareModule.credit_check.credit_information_section_title',
        DEFAULT_CREDIT_CLASS_ASSIGNED: 'customerCareModule.credit_check.default_credit_class_assigned',
        ERROR: 'customerCareModule.credit_check.error',
        EXPIRED: 'customerCareModule.credit_check.expired',
        EXPIRES_LABEL: 'customerCareModule.credit_check.expires_label',
        FINANCE_KEEP_BILLING_SCHEDULE: 'customerCareModule.credit_check.finance_keep_billing_schedule',
        FINANCE_MOVE_BALANCE_TO_NEXT_INVOICE: 'customerCareModule.credit_check.finance_move_balance_to_next_invoice',
        FINANCING: 'customerCareModule.financing',
        HYPHEN: 'customerCareModule.credit_check.hyphen',
        LAST_UPDATED_LABEL: 'customerCareModule.credit_check.last_updated_label',
        NA: 'customerCareModule.credit_check.na',
        NO_CREDIT_CHECK: 'customerCareModule.credit_check.no_credit_check',
        OVERRIDE_CREDIT_CHECK_SUCCESS: 'customerCareModule.credit_check.override_credit_check_success',
        OVERRIDE_LABEL: 'customerCareModule.credit_check.override_label',
        PROVIDER_ERROR: 'customerCareModule.credit_check.provider_error',
        REASON: 'customerCareModule.credit_check.reason',
        RERUN_CREDIT_CHECK: 'customerCareModule.credit_check.rerun_credit_check',
        RUN_CHECK: 'customerCareModule.credit_check.run_check',
        RUN_CREDIT_CHECK: 'customerCareModule.credit_check.run_credit_check',
        SKIP_CHECK: 'customerCareModule.credit_check.skip_check',
        SKIP_CHECK_MSG: 'customerCareModule.credit_check.skip_check_msg',
        SKIP_CREDIT_CHECK: 'customerCareModule.credit_check.skip_credit_check',
        SKIPPED: 'customerCareModule.credit_check.skipped',
        SUCCESS: 'customerCareModule.credit_check.success',
        TOOLTIP_FINANCE_OPTION: 'customerCareModule.credit_check.tooltip_finance_option',
        TOOLTIP_FINANCE_OPTION_NOT_APPLICABLE: 'customerCareModule.credit_check.tooltip_finance_option_not_applicable',
        TOOLTIP_NO_CHECK: 'customerCareModule.credit_check.tooltip_no_check',
        TOOLTIP_OVERRIDE: 'customerCareModule.credit_check.tooltip_override',
        TOOLTIP_SUCCESS: 'customerCareModule.credit_check.tooltip_success',
        TOOLTIP_SKIPPED: 'customerCareModule.credit_check.tooltip_skipped',
        TOOLTIP_EXPIRED: 'customerCareModule.credit_check.tooltip_expired',
        TOOLTIP_ERROR: 'customerCareModule.credit_check.tooltip_error',
        UNEXPECTED_ERROR: 'customerCareModule.credit_check.unexpected_error'
    },
    CREDIT_DEBIT_NOTE: {
        ADJUSTMENT_DETAILS_HEADER: 'customerCareModule.credit_debit_note.adjustment_details_header',
        ADJUSTMENT_DETAILS: 'customerCareModule.credit_debit_note.adjustment_details',
        ADJUSTMENT_NUMBER: 'customerCareModule.credit_debit_note.adjustment_number',
        ADJUSTMENT_SUMMARY: 'customerCareModule.credit_debit_note.adjustment_summary',
        AMOUNT_PAID: 'customerCareModule.credit_debit_note.amount_paid',
        AMOUNT_TO_APPLY_EXCEEDS_AMOUNT_DUE_ERROR: 'customerCareModule.credit_debit_note.amount_to_apply_exceeds_amount_due_error',
        AMOUNT_TO_APPLY_EXCEEDS_BALANCE_ERROR: 'customerCareModule.credit_debit_note.amount_to_apply_exceeds_balance_error',
        AMOUNT_TO_APPLY_MAX_ERROR_SUMMARY: 'customerCareModule.credit_debit_note.amount_to_apply_max_error_summary',
        AMOUNT_TO_APPLY_MAX_ERROR: 'customerCareModule.credit_debit_note.amount_to_apply_max_error',
        AMOUNT_TO_APPLY_REQUIRED: 'customerCareModule.credit_debit_note.amount_to_apply_required',
        AMOUNT_TO_APPLY: 'customerCareModule.credit_debit_note.amount_to_apply',
        APPLY_CREDIT_NOTE_TITLE: 'customerCareModule.credit_debit_note.apply_credit_note_title',
        APPLY_CREDIT_NOTE: 'customerCareModule.credit_debit_note.apply_credit_note',
        APPLY_NOTE: 'customerCareModule.credit_debit_note.apply_note',
        BILL_ADJUSTMENT_DETAIL_HEADER: 'customerCareModule.credit_debit_note.bill_adjustment_detail_header',
        BILL_ADJUSTMENT_SUMMARY: 'customerCareModule.credit_debit_note.bill_adjustment_summary',
        BILL_ADJUSTMENT_NUMBER: 'customerCareModule.credit_debit_note.bill_adjustment_number',
        BILL_ADJUSTMENT_DETAILS: 'customerCareModule.credit_debit_note.bill_adjustment_details',
        CONVERSION_CREDIT_DETAILS_HEADER: 'customerCareModule.credit_debit_note.conversion_credit_details_header',
        CONVERSION_CREDIT_DETAILS: 'customerCareModule.credit_debit_note.conversion_credit_details',
        CONVERSION_CREDIT_NUMBER: 'customerCareModule.credit_debit_note.conversion_credit_number',
        CONVERSION_CREDIT_SUMMARY: 'customerCareModule.credit_debit_note.conversion_credit_summary',
        CONVERSION_DEBIT_DETAILS_HEADER: 'customerCareModule.credit_debit_note.conversion_debit_details_header',
        CONVERSION_DEBIT_DETAILS: 'customerCareModule.credit_debit_note.conversion_debit_details',
        CONVERSION_DEBIT_NUMBER: 'customerCareModule.credit_debit_note.conversion_debit_number',
        CONVERSION_DEBIT_SUMMARY: 'customerCareModule.credit_debit_note.conversion_debit_summary',
        CREATE_NOTE: 'customerCareModule.credit_debit_note.create_note',
        CREDIT_NOTE_APPLIED: 'customerCareModule.credit_debit_note.credit_note_applied',
        CREDIT_NOTE_DETAILS_HEADER: 'customerCareModule.credit_debit_note.credit_note_details_header',
        CREDIT_NOTE_DETAILS: 'customerCareModule.credit_debit_note.credit_note_details',
        CREDIT_NOTE_NUMBER: 'customerCareModule.credit_debit_note.credit_note_number',
        CREDIT_NOTE_SUMMARY: 'customerCareModule.credit_debit_note.credit_note_summary',
        DATE_CREATED: 'customerCareModule.credit_debit_note.date_created',
        DEBIT_NOTE_APPLIED: 'customerCareModule.credit_debit_note.debit_note_applied',
        DEBIT_NOTE_DETAILS: 'customerCareModule.credit_debit_note.debit_note_details',
        DEBIT_NOTE_NUMBER: 'customerCareModule.credit_debit_note.debit_note_number',
        DEBIT_NOTE_PREFIX: 'customerCareModule.credit_debit_note.debit_note_prefix',
        DEBIT_NOTE_SUMMARY: 'customerCareModule.credit_debit_note.debit_note_summary',
        DESCRIPTION: 'customerCareModule.credit_debit_note.description',
        GENERATE_STATEMENT: 'customerCareModule.credit_debit_note.generate_statement',
        GENERATE_STATEMENT_ENABLED: 'customerCareModule.credit_debit_note.generate_statement_enabled',
        GENERATE_STATEMENT_DISABLED: 'customerCareModule.credit_debit_note.generate_statement_disabled',
        INVOICE_DEBIT_ID_REQUIRED: 'customerCareModule.credit_debit_note.invoice_debit_id_required',
        INVOICE_DEBIT_NOTE_ID: 'customerCareModule.credit_debit_note.invoice_debit_note_id',
        INVOICE_DEBIT_NOTE: 'customerCareModule.credit_debit_note.invoice_debit_note',
        INVOICE_PREFIX: 'customerCareModule.credit_debit_note.invoice_prefix',
        MAX_AMOUNT: 'customerCareModule.credit_debit_note.max_amount',
        NOTE_AMOUNT_MAX_ERROR: 'customerCareModule.credit_debit_note.note_amount_max_error',
        NOTE_AMOUNT_MIN_ERROR: 'customerCareModule.credit_debit_note.note_amount_min_error',
        NOTE_AMOUNT_REQUIRED: 'customerCareModule.credit_debit_note.note_amount_required',
        NOTE_AMOUNT: 'customerCareModule.credit_debit_note.note_amount',
        NOTE_TYPE: 'customerCareModule.credit_debit_note.note_type',
        ORIGINAL_BALANCE: 'customerCareModule.credit_debit_note.original_balance',
        PAYMENT_ID: 'customerCareModule.credit_debit_note.payment_id',
        TRANSACTION_ID: 'customerCareModule.credit_debit_note.transaction_id'
    },
    CREDIT_LIMIT_BREACHED: 'customerCareModule.credit_limit_breached',
    CREDIT_LIMIT_THRESHOLD_BREACHED: 'customerCareModule.credit_limit_threshold_breached',
    CREDIT_LIMIT_BREACHED_NO_AMOUNT: 'customerCareModule.credit_limit_breached_no_amount',
    CURRENCY: 'customerCareModule.currency',
    CURRENCY_ENTITLEMENT: {
        AVAILABLE_BALANCE: 'uiModule.currency_entitlement.available_balance',
    },
    CURRENCY_MISMATCH: {
        TITLE: 'customerCareModule.currency_mismatch.title',
        MESSAGE: 'customerCareModule.currency_mismatch.message',
    },
    CURRENCY_NOT_FOUND: 'customerCareModule.currency_not_found',
    CURRENCY_NOT_SUPPORTED: 'customerCareModule.currency_not_supported',
    CURRENT: 'customerCareModule.current',
    CURRENT_CHARGE: 'customerCareModule.current_charge',
    CURRENT_TOTAL: 'customerCareModule.current_total',
    CUSTOM_PAGE: {
        ERROR_PRIMARY: 'customerCareModule.custom_page.error_primary',
        ERROR_SECONDARY: 'customerCareModule.custom_page.error_secondary',
        NO_SUCH_PAGE: 'customerCareModule.custom_page.no_such_page'
    },
    CUSTOM_WIDGETS: {
        ERROR_PRIMARY: 'customerCareModule.custom_widgets.error_primary',
        ERROR_SECONDARY: 'customerCareModule.custom_widgets.error_secondary'
    },
    CUSTOMER: 'customerCareModule.customer',
    CUSTOMERS: 'customerCareModule.customers',
    CUSTOMER_INFORMATION: 'customerCareModule.customer_information',
    CUSTOMER_CATEGORY: 'customerCareModule.customer_category',
    CUSTOMER_DASHBOARD: {
        ACCOUNT: 'customerCareModule.customerDashboard.account',
        ACCOUNT_BALANCE: 'customerCareModule.customerDashboard.account_balance',
        ACCOUNT_BALANCE_NO_TILES: 'customerCareModule.customerDashboard.account_balance_no_tiles',
        ACCOUNT_BALANCE_FROM_LAST_INVOICE: 'customerCareModule.customerDashboard.account_balance_from_last_invoice',
        ACCOUNT_BALANCE_TOOLTIP_LABEL: 'customerCareModule.customerDashboard.account_balance_tooltip_label',
        ACCOUNT_DELIMITER: 'customerCareModule.customerDashboard.account_delimiter',
        ACCOUNT_STATUS: 'customerCareModule.customerDashboard.account_status',
        ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING: 'customerCareModule.customerDashboard.account_status_blocklisted_locked_ordering',
        ACTIVE_PRODUCTS: 'customerCareModule.customerDashboard.active_products',
        ADD_ANOTHER_OFFER: 'customerCareModule.customerDashboard.add_another_offer',
        ADD_NEW: 'customerCareModule.customerDashboard.add_new',
        ADD_PRODUCT: 'customerCareModule.customerDashboard.add_product',
        ADDED_ON: 'customerCareModule.customerDashboard.added_on',
        BALANCE_FROM_PRIOR_INVOICE: 'customerCareModule.customerDashboard.balance_from_prior_invoice',
        BALANCE: 'customerCareModule.customerDashboard.balance',
        CANCEL_EARLY_PAYMENT: 'customerCareModule.customerDashboard.cancel_early_payment',
        CANNOT_REMOVE_BEFORE: 'customerCareModule.customerDashboard.cannot_remove_before',
        CASH_RECEIVABLE: 'customerCareModule.customerDashboard.cash_receivable',
        CHANGE: 'customerCareModule.customerDashboard.change',
        CHARGE: 'customerCareModule.customerDashboard.charge',
        CONNECTED_DATE: 'customerCareModule.customerDashboard.connected_date',
        CONVERGENT_BILLER_ERROR: 'customerCareModule.customerDashboard.convergent_biller_error',
        CREATE_CASE_ERROR_LOADING_DATA: 'customerCareModule.customerDashboard.create_case_error_loading_data',
        CREATE_ONE_NOW: 'customerCareModule.customerDashboard.create_one_now',
        CREDIT_LIMIT_REMAINING: 'customerCareModule.customerDashboard.credit_limit_remaining',
        CURRENT_INVOICE: 'customerCareModule.customerDashboard.current_invoice',
        DAYS_DELINQUENT: 'customerCareModule.customerDashboard.days_delinquent',
        DEPOSIT_BALANCE: 'customerCareModule.customerDashboard.deposit_balance',
        DEVICE_FINANCING: 'customerCareModule.customerDashboard.device_financing',
        DEVICE_FINANCING_COOLING_PERIOD: 'customerCareModule.customerDashboard.device_financing_cooling_period',
        DEVICE_FINANCING_COOLING_PERIOD_DATE: 'customerCareModule.customerDashboard.device_financing_cooling_period_date',
        DEVICE_FINANCING_COOLING_PERIOD_OFFER_REMOVED: 'customerCareModule.customerDashboard.device_financing_cooling_period_offer_removed',
        DEVICE_FINANCING_FOR: 'customerCareModule.customerDashboard.device_financing_for',
        DEVICE_RETURN_FAILURE: 'customerCareModule.customerDashboard.device_return_failure',
        DEVICE_RETURN_SUCCESS: 'customerCareModule.customerDashboard.device_return_success',
        DISABLE_AUTO_RENEW: 'customerCareModule.customerDashboard.disable_auto_renew',
        DUE: 'customerCareModule.customerDashboard.due',
        DUE_DATE: 'customerCareModule.customerDashboard.due_date',
        EDIT_EXISTING: 'customerCareModule.customerDashboard.edit_existing',
        EDIT_OFFER: 'customerCareModule.customerDashboard.edit_offer',
        EFFECTIVE: 'customerCareModule.customerDashboard.effective',
        ELIGIBILITY_RULES: 'customerCareModule.customerDashboard.eligibility_rules',
        ENABLE_AUTO_RENEW: 'customerCareModule.customerDashboard.enable_auto_renew',
        EXPIRES: 'customerCareModule.customerDashboard.expires',
        EXPIRES_ON: 'customerCareModule.customerDashboard.expires_on',
        FINANCING: 'customerCareModule.financing',
        FRIENDLY_NAME: {
            SUBMIT_TEXT: 'customerCareModule.customerDashboard.friendly_name.submit_text',
            LABEL: 'customerCareModule.customerDashboard.friendly_name.label',
            MENU_OPTION: 'customerCareModule.customerDashboard.friendly_name.menu_option',
            MODAL_TITLE: 'customerCareModule.customerDashboard.friendly_name.modal_title',
            SERVICE_ID_LABEL: 'customerCareModule.customerDashboard.friendly_name.service_id_label',
        },
        FULL_DETAILS: 'customerCareModule.customerDashboard.full_details',
        GRACE_PERIOD_EXPIRES: 'customerCareModule.customerDashboard.grace_period_expires',
        INVOICE_TYPE: 'customerCareModule.customerDashboard.invoice_type',
        LAST_PAID: 'customerCareModule.customerDashboard.last_paid',
        LATE_PAYMENT_FEE: {
            LATE_PAYMENT_FEE_LABEL: 'customerCareModule.customerDashboard.late_payment_fee.late_payment_fee_label',
            LATE_PAYMENT_ENABLED: 'customerCareModule.customerDashboard.late_payment_fee.late_payment_enabled'
        },
        MANAGE_OFFER: 'customerCareModule.customerDashboard.manage_offer',
        MANAGE_PAYMENT_INSTRUMENT: 'customerCareModule.customerDashboard.manage_payment_instrument',
        MISC_ADJUSTMENT: 'customerCareModule.customerDashboard.misc_adjustment',
        NEXT_INSTALLMENT_DUE: 'customerCareModule.customerDashboard.next_installment_due',
        NEXT_PAYMENT_ATTEMPT: 'customerCareModule.customerDashboard.next_payment_attempt',
        NO_ACTIVE_CHARGES: 'customerCareModule.customerDashboard.no_active_charges',
        NO_ENTITLEMENTS_FOR_SERVICE: 'customerCareModule.customerDashboard.no_entitlements_for_service',
        NO_PRODUCTS_OR_SERVICES: 'customerCareModule.customerDashboard.no_products_or_services',
        NO_WIDGETS_CONFIGURED: 'customerCareModule.customerDashboard.no_widgets_configured',
        OF: 'customerCareModule.customerDashboard.of',
        OFFERINGS_WIDGET: {
            FINANCING_AND_INVENTORY: 'customerCareModule.customerDashboard.offerings_widget.financing_and_inventory',
            CONSUMES_PLAN_ASSOCIATION_DETAILS: 'customerCareModule.customerDashboard.offerings_widget.consumes_plan_association_details',
            PROVIDES_PLAN_ASSOCIATION_DETAILS: 'customerCareModule.customerDashboard.offerings_widget.provides_plan_association_details',
            PROVIDES_PLAN_ASSOCIATION_DETAILS_PLURAL: 'customerCareModule.customerDashboard.offerings_widget.provides_plan_association_details_plural',
            REMAINING_FINANCE_AMOUNT: 'customerCareModule.customerDashboard.offerings_widget.remaining_finance_amount',
            SERVICES_COUNT: 'customerCareModule.customerDashboard.offerings_widget.services_count',
            USAGE_CAPS: 'customerCareModule.customerDashboard.offerings_widget.usage_caps',
            VIEW_ALL_INVENTORY_ITEMS: 'customerCareModule.customerDashboard.offerings_widget.view_all_inventory_items',
            VIEW_ALL_OFFERINGS: 'customerCareModule.customerDashboard.offerings_widget.view_all_offerings',
            VIEW_ALL_SERVICES: 'customerCareModule.customerDashboard.offerings_widget.view_all_services'
        },
        ON_DEMAND: 'customerCareModule.customerDashboard.on_demand',
        ONE_TIME: 'customerCareModule.customerDashboard.one_time',
        OPEN_INVOICES: 'customerCareModule.customerDashboard.open_invoices',
        OPEN_INVOICES_EMPTY_STATE: 'customerCareModule.customerDashboard.open_invoices_empty_state',
        PAST_DUE: 'customerCareModule.customerDashboard.past_due',
        PAYMENT_FAILURE_NOTIFICATION_MESSAGE: 'customerCareModule.customerDashboard.payment_failure_notification_message',
        PAYMENT_RETRY_DISABLED_TOOLTIP: 'customerCareModule.customerDashboard.payment_retry_disabled_tooltip',
        PENDING_STATE: 'customerCareModule.customerDashboard.pending_state',
        PERIODIC: 'customerCareModule.customerDashboard.periodic',
        PLANS_WITH_USAGE: 'customerCareModule.customerDashboard.plans_with_usage',
        PLAN_WILL_EXPIRE_ON_OR_AFTER: 'customerCareModule.customerDashboard.plan_will_expire_on_or_after',
        PRICING_PLAN_CONTAINS_USAGE_RATES: 'customerCareModule.customerDashboard.pricing_plan_contains_usage_rages',
        PRIOR_BALANCE: 'customerCareModule.customerDashboard.prior_balance',
        PROMISE_TO_PAY: 'customerCareModule.customerDashboard.promise_to_pay',
        PROVIDES_SERVICE_PLURAL: 'customerCareModule.customerDashboard.provides_service_plural',
        PROVIDES_SERVICE: 'customerCareModule.customerDashboard.provides_service',
        CONSUMES_PROVIDER_NAME: 'customerCareModule.customerDashboard.consumes_provider_name',
        RECENT_PRODUCTS: 'customerCareModule.customerDashboard.recent_products',
        RECURRING: 'customerCareModule.customerDashboard.recurring',
        REMAINING: 'customerCareModule.customerDashboard.remaining',
        RENEWS_ON: 'customerCareModule.customerDashboard.renews_on',
        RENEW_PLAN_NOW: 'customerCareModule.customerDashboard.renew_plan_now',
        RESUME_SUBSCRIBER_PRODUCTS: 'customerCareModule.customerDashboard.resume_subscriber_products',
        RETRY_PAYMENT: 'customerCareModule.customerDashboard.retry_payment',
        UPDATE_SERVICE_LIFE_CYCLE_STATUS: {
            MODAL_TITLE: 'customerCareModule.customerDashboard.update_service_life_cycle_status.modal_title',
            REASON_DESCRIPTION:'customerCareModule.customerDashboard.update_service_life_cycle_status.reason_description',
            SUBMIT_TEXT: 'customerCareModule.customerDashboard.update_service_life_cycle_status.submit_text',
            SELECT_LIFE_CYCLE_STATUS: 'customerCareModule.customerDashboard.update_service_life_cycle_status.select_life_cycle_status',
            SUCCESS: 'customerCareModule.customerDashboard.update_service_life_cycle_status.success',
        },
        SERVICE_FEATURE_PENDING_STATE: 'customerCareModule.customerDashboard.service_feature_pending_state',
        SERVICE_FOR: 'customerCareModule.customerDashboard.service_for',
        SERVICES_AND_OFFERS_WIDGET: {
            TABS: {
                OFFERS: 'customerCareModule.customerDashboard.services_and_offers_widget.tabs.offers',
                SERVICES: 'customerCareModule.customerDashboard.services_and_offers_widget.tabs.services',
                SHARED_USAGE: 'customerCareModule.customerDashboard.services_and_offers_widget.tabs.shared_usage'
            }
        },
        SERVICES_WIDGET: {
            ADD_ONS: 'customerCareModule.customerDashboard.services_widget.add_ons',
            DEVICE: 'customerCareModule.customerDashboard.services_widget.device',
            EDIT_ENTITLEMENT_MODAL: {
                ADJUST_REMAINING_BALANCE_BY: 'customerCareModule.customerDashboard.services_widget.edit_entitlement_modal.adjust_remaining_balance_by',
                CURRENT_BALANCE: 'customerCareModule.customerDashboard.services_widget.edit_entitlement_modal.current_balance',
                ENTITLEMENT: 'customerCareModule.customerDashboard.services_widget.edit_entitlement_modal.entitlement',
                EXPIRATION_DATE: 'customerCareModule.customerDashboard.services_widget.edit_entitlement_modal.expiration_date',
                REASON_DESCRIPTION: 'customerCareModule.customerDashboard.services_widget.edit_entitlement_modal.reason_description',
                TITLE: 'customerCareModule.customerDashboard.services_widget.edit_entitlement_modal.title'
            },
            EDIT_POLICY_COUNTER_MODAL: {
                POLICY_COUNTER: 'customerCareModule.customerDashboard.services_widget.edit_policy_counter_modal.policy_counter',
                POLICY_COUNTER_BALANCE_UPDATE_SUCCESS: 'customerCareModule.customerDashboard.services_widget.edit_policy_counter_modal.policy_counter_balance_update_success',
                TITLE: 'customerCareModule.customerDashboard.services_widget.edit_policy_counter_modal.title'
            },
            ENITITLEMENT_UPDATE_SUCCESS: 'customerCareModule.customerDashboard.services_widget.enititlement_update_success',
            FEATURES: 'customerCareModule.customerDashboard.services_widget.features',
            INSTALLMENTS_END: 'customerCareModule.customerDashboard.services_widget.installments_end',
            MANAGE_DEVICE_ADD_ONS: 'customerCareModule.customerDashboard.services_widget.manage_device_add_ons',
            MANAGE_SERVICE_ADD_ONS: 'customerCareModule.customerDashboard.services_widget.manage_service_add_ons',
            NETWORK_AND_BILLING_SUSPENDED: 'customerCareModule.customerDashboard.services_widget.network_and_billing_suspended',
            NETWORK_SUSPENDED: 'customerCareModule.customerDashboard.services_widget.network_suspended',
            NEXT_INSTALLMENT: 'customerCareModule.customerDashboard.services_widget.next_installment',
            REMAIN_OF: 'customerCareModule.customerDashboard.services_widget.remain_of',
            VIEW_ALL_SERVICES: 'customerCareModule.customerDashboard.services_widget.view_all_services'
        },
        SUBSCRIPTIONS: {
            COUNT_LABEL: 'customerCareModule.customerDashboard.subscriptions.count_label',
            REMINDER: 'customerCareModule.customerDashboard.subscriptions.reminder',
            SHOW_ALL: 'customerCareModule.customerDashboard.subscriptions.show_all',
            SHOW_LESS: 'customerCareModule.customerDashboard.subscriptions.show_less',
            WIDGET_TITLE: 'customerCareModule.customerDashboard.subscriptions.widget_title'
        },
        SUSPEND_SUBSCRIBER_PRODUCTS: 'customerCareModule.customerDashboard.suspend_subscriber_products',
        SUSPENSION_DATE: 'customerCareModule.customerDashboard.suspension_date',
        SUSPENSION_SCHEDULED: 'customerCareModule.customerDashboard.suspension_scheduled',
        SUSPENSION_SCHEDULED_FOR: 'customerCareModule.customerDashboard.suspension_scheduled_for',
        TOTAL_BALANCE: 'customerCareModule.customerDashboard.total_balance',
        TOTAL_ON_DEMAND_INVOICES: 'customerCareModule.customerDashboard.total_on_demand_invoices',
        TOTAL: 'customerCareModule.customerDashboard.total',
        TRANSACTIONS: 'customerCareModule.customerDashboard.transactions',
        CHANGE_OFFER: 'customerCareModule.customerDashboard.change_offer',
        TREATMENT: 'customerCareModule.customerDashboard.treatment',
        UNLIMITED: 'customerCareModule.customerDashboard.unlimited',
        USAGE_CHARGE: 'customerCareModule.customerDashboard.usage_charge',
        USAGE_CHARGES: 'customerCareModule.customerDashboard.usage_charges',
        USED: 'customerCareModule.customerDashboard.used',
        VALIDATE_PIN_LOCK_MESSAGE: 'customerCareModule.customerDashboard.validate_pin_lock_message',
        VIEW_ALL_OPEN_INVOICES: 'customerCareModule.customerDashboard.view_all_open_invoices',
        VIEW_ALL_PRODUCTS: 'customerCareModule.customerDashboard.view_all_products',
        VIEW_ALL_SUBSCRIPTIONS: 'customerCareModule.customerDashboard.view_all_subscriptions',
        VIEW_CONTENT_BY: 'customerCareModule.customerDashboard.view_content_by',
        VIEWING_ACTIVITY: 'customerCareModule.customerDashboard.viewing_activity',
        WITHHELD_TAX: 'customerCareModule.customerDashboard.withheld_tax',
        WRITTEN_OFF: 'customerCareModule.customerDashboard.written_off'
    },
    CUSTOMER_IDENTIFICATION: 'customerCareModule.customer_identification',
    CUSTOMER_TYPE: 'customerCareModule.customer_type',
    CUSTOMER_WRAP_UP: {
        MODALS: {
            FORM_FIELD_COMMENTS_LABEL: 'customerCareModule.customer_wrap_up.modals.form_field_comments_label',
            FORM_FIELD_REASON_LABEL: 'customerCareModule.customer_wrap_up.modals.form_field_reason_label',
            FORM_SUBMIT_LABEL: 'customerCareModule.customer_wrap_up.modals.form_submit_label',
            WRAP_UP_BUTTON_LABEL: 'customerCareModule.customer_wrap_up.modals.wrap_up_button_label'
        },
        TITLE: 'customerCareModule.customer_wrap_up.title'
    },
    DAILY_LIMIT: 'customerCareModule.daily_limit',
    DATE: 'customerCareModule.date',
    DATE_GENERATED: 'customerCareModule.date_generated',
    DAYS_REMAINING: 'customerCareModule.days_remaining',
    DEBIT: 'customerCareModule.debit',
    DEMO_ACTIONS: {
        ACTIONS: 'customerCareModule.demo_actions.actions',
        ACTIONS_CONVERGENT_BILLER_ERROR: 'customerCareModule.demo_actions.actions_convergent_biller_error',
        ACTIVE_WORKFLOWS: 'customerCareModule.demo_actions.active_workflows',
        ACTIVITY_NAME: 'customerCareModule.demo_actions.activity_name',
        COMPLETE_WORKFLOW_FAILURE: 'customerCareModule.demo_actions.complete_workflow_fail',
        COMPLETE_WORKFLOW_SUCCESS: 'customerCareModule.demo_actions.complete_workflow_success',
        EVENT_TYPE: 'customerCareModule.demo_actions.event_type',
        EVENT_SOURCE: 'customerCareModule.demo_actions.event_source',
        EVENT_SUB_TYPE: 'customerCareModule.demo_actions.event_sub_type',
        EXECUTE_BILL_RUN: 'customerCareModule.demo_actions.execute_bill_run',
        EXECUTE_BILL_RUN_FAILURE: 'customerCareModule.demo_actions.execute_bill_run_fail',
        EXECUTE_BILL_RUN_SUCCESS: 'customerCareModule.demo_actions.execute_bill_run_success',
        IS_BULK_SERVICE: 'customerCareModule.demo_actions.is_bulk_service',
        QUANTITY: 'customerCareModule.demo_actions.quantity',
        RETRIEVE_ACTIVE_WORKFLOWS_FAILURE: 'customerCareModule.demo_actions.retrieve_active_workflows_fail',
        SERVICE: 'customerCareModule.demo_actions.service',
        SIMULATE_USAGE: 'customerCareModule.demo_actions.simulate_usage',
        SIMULATE_USAGE_FAILURE: 'customerCareModule.demo_actions.simulate_usage_failure',
        SIMULATE_USAGE_SUCCESS: 'customerCareModule.demo_actions.simulate_usage_success',
        STATUS: 'customerCareModule.demo_actions.status',
        UNIT_OF_MEASURE: 'customerCareModule.demo_actions.unit_of_measure'
    },
    DESCENDING: 'customerCareModule.descending',
    DECISIONS: {
        ADDITIONAL_SELECTIONS: 'customerCareModule.decisions.additional_selections',
        APPLY_SELECTIONS: 'customerCareModule.decisions.apply_selections',
        BULK_CHARGE_TYPE: {
            FLAT: 'customerCareModule.decisions.bulk_charge_type.flat',
            UNIT: 'customerCareModule.decisions.bulk_charge_type.unit'
        },
        CHARGE_PAID: 'customerCareModule.decisions.charge_paid',
        DECREASE_BELOW_INVOICED_BULK_QUANTITY_DISALLOWED: 'customerCareModule.decisions.decrease_below_invoiced_bulk_quantity_disallowed',
        DISABLED_OPTION: 'customerCareModule.decisions.disabled_option',
        DISALLOW_EDIT_SUBSCRIBER_PRICING_PLAN_PENDING_OFFER: 'customerCareModule.decisions.disallow_edit_subscriber_pricing_plan_pending_offer',
        DISALLOW_EDIT_SUBSCRIBER_PRODUCT_PENDING_OFFERING_SEPARATION: 'customerCareModule.decisions.disallow_edit_subscriber_product_pending_offering_separation',
        DOWNGRADE: 'customerCareModule.decisions.downgrade',
        DUE_TODAY: 'customerCareModule.decisions.due_today',
        FLAT_PRICING: 'customerCareModule.decisions.flat_pricing',
        FULL_PRICE: 'customerCareModule.decisions.full_price',
        LAST_INVOICED_BULK_QUANTITY: 'customerCareModule.decisions.last_invoiced_bulk_quantity',
        OF: 'customerCareModule.decisions.of',
        PRICE: 'customerCareModule.decisions.price',
        QUANTITY: 'customerCareModule.decisions.quantity',
        REMOVE_PLAN_ASSOCIATIONS_GENERIC: 'customerCareModule.decisions.remove_plan_associations_generic',
        REMOVE_PLAN_ASSOCIATIONS_SINGLE: 'customerCareModule.decisions.remove_plan_associations_single',
        REMOVE_SERVICES: 'customerCareModule.decisions.remove_services',
        RENEWAL_PERIOD_MAX: 'customerCareModule.decisions.renewal_period_max',
        RENEWAL_PERIOD_MIN: 'customerCareModule.decisions.renewal_period_min',
        REQUIRES: 'customerCareModule.decisions.requires',
        RETAIL_PRICE: 'customerCareModule.decisions.retail_price',
        SELECT_LIFE_CYCLE: 'customerCareModule.decisions.select_life_cycle',
        SELECTED: 'customerCareModule.decisions.selected',
        SIDEGRADE: 'customerCareModule.decisions.sidegrade',
        TAPERED_DESCRIPTION: 'customerCareModule.decisions.tapered_description',
        TAPERED_PRICING: 'customerCareModule.decisions.tapered_pricing',
        TIERED_DESCRIPTION: 'customerCareModule.decisions.tiered_description',
        TIERED_PRICING: 'customerCareModule.decisions.tiered_pricing',
        UNIT_PRICING: 'customerCareModule.decisions.unit_pricing',
        UNNAMED_PAGE: 'customerCareModule.decisions.unnamed_page',
        UPGRADE: 'customerCareModule.decisions.upgrade',
        VIEW_LIFECYCLE: 'customerCareModule.decisions.view_lifecycle'
    },
    DECISIONS_ERROR: 'customerCareModule.decisions_error',
    DEFAULT: 'customerCareModule.default',
    DEFAULT_ADDRESS_DESCRIPTION: 'customerCareModule.default_address_description',
    DEFAULT_BILLING: 'customerCareModule.default_billing',
    DEFAULT_HOME: 'customerCareModule.default_home',
    DEFAULT_PAYMENT_METHOD: 'customerCareModule.default_payment_method',
    DEFAULT_POSTAL: 'customerCareModule.default_postal',
    DEFAULT_SERVICE: 'customerCareModule.default_service',
    DEFAULT_SHIPPING: 'customerCareModule.default_shipping',
    DEFAULT_WORK: 'customerCareModule.default_work',
    DEPOSIT: 'customerCareModule.deposit',
    DEPOSITS: {
        COLLECTION_DATE: 'customerCareModule.deposits.collection_date',
        DEPOSIT: 'customerCareModule.deposits.deposit',
        DEPOSIT_STATUS: {
            ACTIVE: 'customerCareModule.deposits.deposit_status.active',
            ENTERED: 'customerCareModule.deposits.deposit_status.entered',
            FORFEITED: 'customerCareModule.deposits.deposit_status.forfeited',
            MATURED: 'customerCareModule.deposits.deposit_status.matured',
            RETURNED: 'customerCareModule.deposits.deposit_status.returned'
        },
        MATURITY_DATE: 'customerCareModule.deposits.maturity_date',
        PAYMENT_AMOUNT: 'customerCareModule.deposits.payment_amount',
        PRICING_PLAN: 'customerCareModule.deposits.pricing_plan',
        STATUS: 'customerCareModule.deposits.status',
        TOTAL_AMOUNT: 'customerCareModule.deposits.total_amount'
    },
    DESCRIPTION: 'customerCareModule.description',
    DETAIL: 'customerCareModule.detail',
    DETAILS: 'customerCareModule.details',
    DEVICE: {
        ALLOW: 'customerCareModule.device.allow',
        APPLY: 'customerCareModule.device.apply',
        ASSOCIATION_LIMIT: 'customerCareModule.device.association_limit',
        ASSOCIATION_LIMIT_LABEL: 'customerCareModule.device.association_limit_label',
        CALL_TO_ACTION: 'customerCareModule.device.call_to_action',
        DEVICE_DETAILS: 'customerCareModule.device.device_details',
        DEVICE_NAME: 'customerCareModule.device.device_name',
        DEVICE_NICKNAME: 'customerCareModule.device.device_nickname',
        DEVICE_TYPE: 'customerCareModule.device.device_type',
        EDIT: 'customerCareModule.edit',
        LIST_DOWN_KEY_DESCRIPTION: 'customerCareModule.device.list_down_key_description',
        LIST_UP_KEY_DESCRIPTION: 'customerCareModule.device.list_up_key_description',
        MODIFY_TITLE: 'customerCareModule.device.modify_title',
        NICKNAME: 'customerCareModule.device.nickname',
        NO_CUSTOMER_DEVICES: 'customerCareModule.device.no_customer_devices',
        OMIT: 'customerCareModule.device.omit',
        PHYSICAL_TYPE: 'customerCareModule.device.physical_type',
        PINLESS_PURCHASE: 'customerCareModule.device.pinless_purchase',
        PINLESS_PURCHASE_LABEL: 'customerCareModule.device.pinless_purchase_label',
        PRIMARY: 'customerCareModule.device.primary',
        REGISTER: 'customerCareModule.device.register',
        REGISTERED_DATE: 'customerCareModule.device.registered_date',
        REGISTER_NEW_DEVICE: 'customerCareModule.device.register_new_device',
        REGISTER_SUCCESS: 'customerCareModule.device.register_success',
        REGISTER_TITLE: 'customerCareModule.device.register_title',
        REMAINING_ASSOCIATIONS: 'customerCareModule.device.remaining_associations',
        REMOVED: 'customerCareModule.device.removed',
        REMOVE_ASSOCIATION: 'customerCareModule.device.remove_association',
        REMOVE_CONTENT: 'customerCareModule.device.remove_content',
        REMOVE_SUCCESS: 'customerCareModule.device.remove_success',
        REMOVE_TITLE: 'customerCareModule.device.remove_title',
        RENDEZVOUS_CODE: 'customerCareModule.device.rendezvous_code',
        SERIAL_NUMBER: 'customerCareModule.device.serial_number',
        STATUS: 'customerCareModule.device.status',
        UNLIMITED: 'customerCareModule.device.unlimited',
        UNREGISTERED_DATE: 'customerCareModule.device.unregistered_date',
        UPDATE_SUCCESS: 'customerCareModule.device.update_success'
    },
    DEVICE_FINANCE_CHARGES: 'customerCareModule.device_finance_charges',
    DEVICE_ID: 'customerCareModule.device_id',
    DEVICE_SERIAL_NUMBER: 'customerCareModule.device_serial_number',
    DEVICES: 'customerCareModule.devices',
    DISCONNECT_OFFERS: {
        CANCEL: 'customerCareModule.disconnect_offers.cancel',
        CONFIRMATION_MESSAGE: 'customerCareModule.disconnect_offers.confirmation_message',
        DISCONNECT: 'customerCareModule.disconnect_offers.disconnect',
        DISCONNECT_CUSTOMER: 'customerCareModule.disconnect_offers.disconnect_customer',
        DISCONNECT_OFFERS: 'customerCareModule.disconnect_offers.disconnect_offers',
        DISCONNECT_SUCCESSFUL: 'customerCareModule.disconnect_offers.disconnect_successful',
        DISCONNECT_UNSUCCESSFUL: 'customerCareModule.disconnect_offers.disconnect_unsuccessful',
        EARLY_TERMINATION_FEE: 'customerCareModule.disconnect_offers.early_termination_fee',
        MESSAGE: 'customerCareModule.disconnect_offers.message',
        MULTIPLE_CONTRACTS: 'customerCareModule.disconnect_offers.multiple_contracts',
        ORDER_QUOTE_UNSUCCESSFUL: 'customerCareModule.disconnect_offers.order_quote_unsuccessful',
        PAYMENT: 'customerCareModule.disconnect_offers.payment',
        PRORATE: 'customerCareModule.disconnect_offers.prorate',
        REMOVE: 'customerCareModule.disconnect_offers.remove',
        REMOVE_OFFER: 'customerCareModule.disconnect_offers.remove_offer',
        REMOVE_PLAN_ASSOCIATIONS: 'customerCareModule.disconnect_offers.remove_plan_associations',
        REMOVE_SUCCESSFUL: 'customerCareModule.disconnect_offers.remove_successful',
        REMOVE_UNSUCCESSFUL: 'customerCareModule.disconnect_offers.remove_unsuccessful',
        ROC_UNSUCCESSFUL: 'customerCareModule.disconnect_offers.roc_unsuccessful',

    },
    DISCOUNT: 'customerCareModule.discount',
    DISCOUNT_DIALOG: {
        APPLY: 'customerCareModule.discount_dialog.apply',
        DISCRETIONARY_DISCOUNT: 'customerCareModule.discount_dialog.discretionary_discount',
        REASON: 'customerCareModule.discount_dialog.reason',
        TITLE: 'customerCareModule.discount_dialog.title'
    },
    DISCOUNTS: 'customerCareModule.discounts',
    DISCOUNTS_LABEL: 'customerCareModule.discounts_label',
    DISPUTE_INVOICE: 'customerCareModule.dispute_invoice',
    DISPUTE_LINE_ITEM: 'customerCareModule.dispute_line_item',
    DISPUTED_AMOUNT: 'customerCareModule.disputed_amount',
    DISPUTE_AMOUNT_ABBREVIATION: 'customerCareModule.dispute_amount_abbreviation',
    DISPUTE_NUMBER: 'customerCareModule.dispute_number',
    DONE: CoreLocaleKeys.DONE,
    DRIVERS_LICENSE: 'customerCareModule.drivers_license',
    DRIVERS_LICENSE_NUMBER: 'customerCareModule.drivers_license_number',
    DRIVERS_LICENSE_STATE: 'customerCareModule.drivers_license_state',
    E_WALLET: {
        ACP: {
            ACCOUNT_ID: 'customerCareModule.e_wallet.acp.account_id'
        },
        ACTIVATE_PAYMENT_METHOD: 'customerCareModule.e_wallet.activate_payment_method',
        ACTIVATE_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.activate_success_message',
        ANONYMIZE_PAYMENT_METHOD_BUTTON_LABEL: 'customerCareModule.e_wallet.anonymize_payment_method_button_label',
        ANONYMIZE_PAYMENT_METHOD_MENU_OPTION: 'customerCareModule.e_wallet.anonymize_payment_method_menu_option',
        ANONYMIZE_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.anonymize_success_message',
        CANNOT_ACTIVATE: 'customerCareModule.e_wallet.cannot_activate',
        CANNOT_ANONYMIZE: 'customerCareModule.e_wallet.cannot_anonymize',
        CANNOT_CHANGE_LIST: 'customerCareModule.e_wallet.cannot_change_list',
        CANNOT_REMOVE: 'customerCareModule.cannot_remove',
        CANNOT_SET_DEFAULT: 'customerCareModule.e_wallet.cannot_set_default',
        CONFIRM_ANONYMIZE_PAYMENT_METHOD_MESSAGE: 'customerCareModule.e_wallet.confirm_anonymize_payment_method_message',
        CONFIRM_ANONYMIZE_PAYMENT_METHOD_TITLE: 'customerCareModule.e_wallet.confirm_anonymize_payment_method_title',
        CONFIRM_REMOVE_TITLE: 'customerCareModule.e_wallet.confirm_remove_title',
        CONFIRM_REMOVE_MESSAGE: 'customerCareModule.e_wallet.confirm_remove_message',
        CONFIRM_REMOVE_AUTO_PAY_MESSAGE: 'customerCareModule.e_wallet.confirm_remove_auto_pay_message',
        CONFIRM_UPDATE_SUBSCRIPTIONS: 'customerCareModule.e_wallet.confirm_update_subscriptions',
        CONFIRM_UPDATE_SUBSCRIPTIONS_MESSAGE: 'customerCareModule.e_wallet.confirm_update_subscriptions_message',
        CURRENT_BALANCE: 'customerCareModule.e_wallet.current_balance',
        BRAINTREE: {
            ACCOUNT_NUMBER: 'customerCareModule.e_wallet.braintree.account_number',
            TYPE: 'customerCareModule.e_wallet.braintree.type'
        },
        DETAILS: {
            ACCOUNT_NUMBER: 'customerCareModule.account_number',
            BILLING_ADDRESS: 'customerCareModule.e_wallet.details.billing_address',
            CREATED: 'customerCareModule.e_wallet.details.created',
            CREDIT_CARD_TYPE: 'customerCareModule.e_wallet.details.credit_card_type',
            EMPTY_VALUE: 'customerCareModule.e_wallet.details.empty_value',
            EXPIRES_ON: 'customerCareModule.e_wallet.details.expires_on',
            EXPIRATION: 'customerCareModule.e_wallet.details.expiration',
            EXPIRED_NOTE: 'customerCareModule.e_wallet.details.expired_note',
            EXTERNAL_GIFT_CARD_TYPE: 'customerCareModule.e_wallet.details.external_gift_card_type',
            HEADER: 'customerCareModule.e_wallet.details.header',
            ITUNES_USERNAME: 'customerCareModule.e_wallet.details.itunes_username',
            NAME: 'customerCareModule.e_wallet.details.name',
            NAME_ON_CARD: 'customerCareModule.e_wallet.details.name_on_card',
            PAYPAL_TOKEN: 'customerCareModule.e_wallet.details.paypal_token',
            PAYPAL_USERNAME: 'customerCareModule.e_wallet.details.paypal_username',
            PAYMENT_METHOD_ID: 'customerCareModule.e_wallet.details.payment_method_id',
            PAYMENT_METHOD_SUB_TYPE: 'customerCareModule.e_wallet.details.payment_method_sub_type',
            PAYMENT_METHOD_TYPE: 'customerCareModule.e_wallet.details.payment_method_type',
            PHONE_NUMBER: 'customerCareModule.e_wallet.details.phone_number',
            TYPE: 'customerCareModule.e_wallet.details.type'
        },
        DIRECT_DEBIT: {
            BANK_CODE: 'customerCareModule.e_wallet.direct_debit.bank_code',
            MANDATE_ACCEPTED: 'customerCareModule.e_wallet.direct_debit.mandate_accepted',
            MANDATE_ACCEPTED_ON: 'customerCareModule.e_wallet.direct_debit.mandate_accepted_on',
            MANDATE_NOT_ACCEPTED: 'customerCareModule.e_wallet.direct_debit.mandate_not_accepted',
            NAME_ON_ACCOUNT: 'customerCareModule.e_wallet.direct_debit.name_on_account',
            PHONE_NUMBER: 'customerCareModule.e_wallet.direct_debit.phone_number',
            TYPE: 'customerCareModule.e_wallet.direct_debit.type',
            TYPE_LABELS: {
                AUSTRIA: 'customerCareModule.e_wallet.direct_debit.type_labels.austria',
                GERMANY: 'customerCareModule.e_wallet.direct_debit.type_labels.germany',
                THE_NETHERLANDS: 'customerCareModule.e_wallet.direct_debit.type_labels.the_netherlands',
                SPAIN: 'customerCareModule.e_wallet.direct_debit.type_labels.spain',
                UNITED_KINGDOM: 'customerCareModule.e_wallet.direct_debit.type_labels.united_kingdom'
            }
        },
        ECHECK: {
            ACCOUNT_NUMBER: 'customerCareModule.account_number',
            ROUTING_NUMBER: 'customerCareModule.e_wallet.echeck.routing_number'
        },
        EDIT: 'customerCareModule.e_wallet.edit',
        ERROR_LOADING_WALLET: 'customerCareModule.e_wallet.error_loading_wallet',
        ERRORS: {
            MAX: 'customerCareModule.e_wallet.errors.max',
            MAX_ACTIVE: 'customerCareModule.e_wallet.errors.max_active'
        },
        EXTERNAL_BILL: {
            AUTHORIZATION_CHALLENGE: 'customerCareModule.e_wallet.external_bill.authorization_challenge',
            EXPIRATION_TEXT: 'customerCareModule.e_wallet.external_bill.expiration_text',
            NAME_ON_ACCOUNT: 'customerCareModule.e_wallet.external_bill.name_on_account'
        },
        LIST_DOWN_KEY_DESCRIPTION: 'customerCareModule.e_wallet.list_down_key_description',
        LIST_UP_KEY_DESCRIPTION: 'customerCareModule.e_wallet.list_up_key_description',
        MODALS: {
            ADD_TO_BLOCKLIST_MENU_TEXT: 'customerCareModule.e_wallet.modals.add_to_blocklist_menu_text',
            ADD_TO_BLOCKLIST_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.modals.add_to_blocklist_success_message',
            ADD_TO_ALLOWLIST_MENU_TEXT: 'customerCareModule.e_wallet.modals.add_to_allowlist_menu_text',
            ADD_TO_ALLOWLIST_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.modals.add_to_allowlist_success_message',
            BLOCKLISTED: 'customerCareModule.e_wallet.modals.blocklisted',
            CONFIRM_BUTTON_LABEL: 'customerCareModule.e_wallet.modals.confirm_button_label',
            ERRORS: {
                BLOCKLIST_ADD_REASON_REQUIRED: 'customerCareModule.e_wallet.modals.errors.blocklist_add_reason_required',
                BLOCKLIST_REMOVE_REASON_REQUIRED: 'customerCareModule.e_wallet.modals.errors.blocklist_remove_reason_required',
                ALLOWLIST_ADD_REASON_REQUIRED: 'customerCareModule.e_wallet.modals.errors.allowlist_add_reason_required',
                ALLOWLIST_REMOVE_REASON_REQUIRED: 'customerCareModule.e_wallet.modals.errors.allowlist_remove_reason_required'
            },
            FORM_FIELD_COMMENTS_LABEL: 'customerCareModule.e_wallet.modals.form_field_comments_label',
            FORM_FIELD_REASON_LABEL: 'customerCareModule.e_wallet.modals.form_field_reason_label',
            REMOVE_FROM_BLOCKLIST_MENU_TEXT: 'customerCareModule.e_wallet.modals.remove_from_blocklist_menu_text',
            REMOVE_FROM_BLOCKLIST_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.modals.remove_from_blocklist_success_message',
            REMOVE_FROM_ALLOWLIST_MENU_TEXT: 'customerCareModule.e_wallet.modals.remove_from_allowlist_menu_text',
            REMOVE_FROM_ALLOWLIST_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.modals.remove_from_allowlist_success_message',
            ALLOWLISTED: 'customerCareModule.e_wallet.modals.allowlisted'
        },
        NEW_PAYMENT_METHOD: 'customerCareModule.e_wallet.new_payment_method',
        NO_LABEL: 'customerCareModule.e_wallet.no_label',
        NO_PAYMENT_METHODS: 'customerCareModule.e_wallet.no_payment_methods',
        PAYMENT_FAILURE_NOTIFICATION_MESSAGE: 'customerCareModule.e_wallet.payment_failure_notification_message',
        PAYMENT_METHOD_LOCKED: 'customerCareModule.e_wallet.payment_method_locked',
        REDEEM_GIFT_CARD_SUCCESS: 'customerCareModule.e_wallet.redeem_gift_card_success',
        REMOVE_PAYMENT_METHOD: 'customerCareModule.e_wallet.remove_payment_method',
        REMOVE_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.remove_success_message',
        REMOVED: 'customerCareModule.e_wallet.removed',
        ROKU: {
            USERNAME: 'customerCareModule.e_wallet.roku.username'
        },
        SET_AS_DEFAULT_PAYMENT_METHOD: 'customerCareModule.e_wallet.set_as_default_payment_method',
        SET_DEFAULT_SUCCESS_MESSAGE: 'customerCareModule.e_wallet.set_default_success_message',
        STORED_VALUE_ACCOUNT: {
            ACCOUNT_NUMBER: 'customerCareModule.account_number',
            CHARGE: 'customerCareModule.e_wallet.stored_value_account.charge',
            CHARGE_PAYMENT_INSTRUMENT_ID: 'customerCareModule.e_wallet.stored_value_account.charge_payment_instrument_id',
            CURRENT_BALANCE: 'customerCareModule.e_wallet.stored_value_account.current_balance',
            CURRENCY_TYPE: 'customerCareModule.e_wallet.stored_value_account.currency_type',
            INITIAL_BALANCE: 'customerCareModule.e_wallet.stored_value_account.initial_balance',
            LOYALTY_CARD: 'customerCareModule.e_wallet.stored_value_account.loyalty_card',
            PAYMENT_SOURCE: 'customerCareModule.e_wallet.stored_value_account.payment_source',
            RECHARGE_TYPE: 'customerCareModule.e_wallet.stored_value_account.recharge_type',
            RECHARGE_TYPES: {
                FREQUENCY_BY_DAY: {
                    TEXT: 'customerCareModule.e_wallet.stored_value_account.recharge_types.frequency_by_day.text',
                    ONE: 'customerCareModule.e_wallet.stored_value_account.recharge_types.frequency_by_day.one',
                    TWO: 'customerCareModule.e_wallet.stored_value_account.recharge_types.frequency_by_day.two',
                    MORE: 'customerCareModule.e_wallet.stored_value_account.recharge_types.frequency_by_day.more'
                },
                MANUALLY: 'customerCareModule.e_wallet.stored_value_account.recharge_types.manually',
                MONTHLY: 'customerCareModule.e_wallet.stored_value_account.recharge_types.monthly',
                WHEN_EMPTY: 'customerCareModule.e_wallet.stored_value_account.recharge_types.when_empty'
            }
        },
        TITLE: 'customerCareModule.e_wallet.title',
        YES_LABEL: 'customerCareModule.e_wallet.yes_label',
        UPDATE_SUBSCRIPTIONS_LABEL: 'customerCareModule.e_wallet.update_subscriptions_label',
        VIEW_PAYMENT_HISTORY: 'customerCareModule.e_wallet.view_payment_history'
    },
    EARLY_TERMINATION_FEE_DIALOG: {
        COMMENT: 'customerCareModule.early_termination_fee_dialog.comment',
        DEFAULT: 'customerCareModule.early_termination_fee_dialog.default',
        OVERRIDE: 'customerCareModule.early_termination_fee_dialog.override',
        RESTORE_DEFAULT: 'customerCareModule.early_termination_fee_dialog.restore_default',
        TITLE: 'customerCareModule.early_termination_fee_dialog.title',
        UPDATE: 'customerCareModule.early_termination_fee_dialog.update',
        WAIVE_OFF: 'customerCareModule.early_termination_fee_dialog.waive_off'
    },
    EDIT: 'customerCareModule.edit',
    EDIT_OPTIONS: 'customerCareModule.edit_options',
    EDIT_BULK_PLAN: {
        EDIT_BULK_PRICING: 'customerCareModule.edit_bulk_plan.edit_bulk_pricing',
        MINIMUM: 'customerCareModule.edit_bulk_plan.minimum',
        PLAN_OR_SERVICE_CHANGE: 'customerCareModule.edit_bulk_plan.plan_or_service_change'
    },
    EDIT_CURRENT: 'customerCareModule.edit_current',
    EDIT_CUSTOMER: 'customerCareModule.edit_customer',
    EDIT_CUSTOMER_PIN: 'customerCareModule.edit_customer_pin',
    EDITED: 'customerCareModule.edited',
    EDIT_FINANCE_BRI: {
        FULL_PRICE: 'customerCareModule.edit_finance_bri.full_price',
        MINIMUM_AMOUNT: 'customerCareModule.edit_finance_bri.minimum_amount',
        TERM_LENGTH: 'customerCareModule.edit_finance_bri.term_length',
    },
    EDIT_OFFER: {
        EDIT_OFFER_ORDER: 'customerCareModule.edit_offer.edit_offer_order',
        ORDER_WARNING: 'customerCareModule.edit_offer.order_warning',
        RECONNECT_OFFER_ORDER: 'customerCareModule.edit_offer.reconnect_offer_order',
        RECONNECT_OFFER_ORDER_INSUFFICIENT_ACCESS_TITLE: 'customerCareModule.edit_offer.reconnect_offer_order_insufficient_access_title',
        SERVICE_FEATURE_WARNING: 'customerCareModule.edit_offer.service_feature_warning',
        SUBMIT_ORDER_SUCCESS: 'customerCareModule.edit_offer.submit_order_success',
        SUBMIT_ORDER_FAILURE: 'customerCareModule.edit_offer.submit_order_failure'
    },
    EDIT_PIN: 'customerCareModule.edit_pin',
    EDIT_PROFILE_INFORMATION: 'customerCareModule.edit_profile_information',
    EDIT_ADDITIONAL_PROPERTIES: 'customerCareModule.edit_additional_properties',
    EFFECTIVE_START_DATE: 'customerCareModule.effective_start_date',
    EMAIL: 'email',
    EMAIL_REQUIRED: 'customerCareModule.email_required',
    EMPTY_CUSTOMER: {
        MESSAGE_LINE_1: 'customerCareModule.empty_customer.message_line_1',
        MESSAGE_LINE_2: 'customerCareModule.empty_customer.message_line_2'
    },
    EMPTY_FILTER_PLACEHOLDER: 'customerCareModule.empty_filter_placeholder',
    EMPTY_VALUE_NA: 'customerCareModule.empty_value_na',
    ENTER_CUSTOMER_PIN: 'customerCareModule.enter_customer_pin',
    ENTITLEMENT: 'customerCareModule.entitlement',
    ENTITLEMENT_THUMBNAIL: {
        ACTIVATION: 'customerCareModule.entitlement_thumbnail.activation',
        HIERARCHY_INSTANCES_COUNT_TOOLTIP: 'customerCareModule.entitlement_thumbnail.hierarchy_instances_count_tooltip',
        LOCATION: 'customerCareModule.entitlement_thumbnail.location',
        ON_FIRST_USE: 'customerCareModule.entitlement_thumbnail.on_first_use',
        ON_FIRST_USE_WITH_PRICING_PLAN: 'customerCareModule.entitlement_thumbnail.on_first_use_with_pricing_plan',
        VALIDITY_PERIOD: 'customerCareModule.entitlement_thumbnail.validity_period'
    },
    ENTITLEMENT_MODAL_EMPTY_PURCHASE: 'customerCareModule.entitlement_modal_empty_purchase',
    ENTITLEMENT_MODAL_EMPTY_USAGE: 'customerCareModule.entitlement_modal_empty_usage',
    EXISTING_CART_PROMPT: {
        CONFIRM: 'customerCareModule.existing_cart_prompt.confirm',
        DENY: 'customerCareModule.existing_cart_prompt.deny',
        MESSAGE: 'customerCareModule.existing_cart_prompt.message',
        TITLE: 'customerCareModule.existing_cart_prompt.title'
    },
    EXPAND_ALL: 'customerCareModule.expand_all',
    EXPIRED: 'customerCareModule.expired',
    EXPIRES: 'customerCareModule.expires',
    EXPIRES_DATE: 'customerCareModule.expires_date',
    EXTERNAL: 'customerCareModule.external',
    EXTERNAL_AUTHORIZATION: 'customerCareModule.external_authorization',
    EXTERNAL_ID: 'customerCareModule.external_id',
    FEES: 'customerCareModule.fees',
    FEATURED_PRICE: 'customerCareModule.featured_price',
    FILTER_SEARCH_PLACEHOLDER: 'customerCareModule.filter_search_placeholder',
    FINANCE_AND_CREDIT_LIMIT_BREACHED_NOTICE: 'customerCareModule.finance_and_credit_limit_breached_notice',
    FINANCE_LIMIT_BREACHED: 'customerCareModule.finance_limit_breached',
    FIRST_NAME: 'first_name',
    FIRST_NAME_REQUIRED: 'first_name_required',
    FLAT_EVENT_USAGE_RATES: 'customerCareModule.flat_event_usage_rates',
    FOR_PAYMENT: 'customerCareModule.for_payment',
    FORM_ERRORS: 'form.errors',
    FRIENDLY_NAME: 'customerCareModule.friendly_name',
    FULL_CART: {
        ADDITIONAL_CHARGES: 'customerCareModule.full_cart.additional_charges',
        DAILY: 'customerCareModule.full_cart.daily',
        DISCOUNTS: 'customerCareModule.full_cart.discounts',
        DUE_ON_ACTIVATION: 'customerCareModule.full_cart.due_on_activation',
        DUE_ON_FIRST_USE: 'customerCareModule.full_cart.due_on_first_use',
        DUE_RECURRING: 'customerCareModule.full_cart.due_recurring',
        DUE_TODAY: 'customerCareModule.full_cart.due_today',
        EXISTING_ITEMS: 'customerCareModule.full_cart.existing_items',
        HEADER: 'customerCareModule.full_cart.header',
        HOURLY: 'customerCareModule.full_cart.hourly',
        MONTHLY: 'customerCareModule.full_cart.monthly',
        NEXT_INVOICE: 'customerCareModule.full_cart.next_invoice',
        SERVICE_SUBTOTAL: 'customerCareModule.full_cart.service_subtotal',
        WEEKLY: 'customerCareModule.full_cart.weekly',
        YEARLY: 'customerCareModule.full_cart.yearly'
    },
    FUTURE_CHARGE: 'customerCareModule.future_charge',
    FUTURE_DISCOUNTS: 'customerCareModule.future_discounts',
    FUTURE_STATEMENT: 'customerCareModule.future_statement',
    GIFT_CARD: 'customerCareModule.gift_card',
    GIFT_CARDS: 'customerCareModule.gift_cards',
    GIFT_CARD_DETAILS: {
        ACTIVATION_LIST_EMPTY: 'customerCareModule.gift_card_details.activation_list_empty',
        ACTIVATION_LIST_TITLE: 'customerCareModule.gift_card_details.activation_list_title',
        CREATION_DATE: 'customerCareModule.gift_card_details.creation_date',
        CURRENCY: 'customerCareModule.gift_card_details.currency',
        DETAILS_TITLE: 'customerCareModule.gift_card_details.details_title',
        GIFT_CARD_DETAILS_NOT_FOUND: 'customerCareModule.gift_card_details.gift_card_details_not_found',
        MESSAGE: 'customerCareModule.gift_card_details.message',
        NOTIFY_SENDER: 'customerCareModule.gift_card_details.notify_sender',
        PIN: 'customerCareModule.gift_card_details.pin',
        RECIPIENT_EMAIL: 'customerCareModule.gift_card_details.recipient_email',
        RECIPIENT_NAME: 'customerCareModule.gift_card_details.recipient_name',
        REDEEMING_SUBSCRIBER_ID: 'customerCareModule.gift_card_details.redeeming_subscriber_id',
        REDEMPTION_STATUS: 'customerCareModule.gift_card_details.redemption_status',
        REDEMPTION_VALUE: 'customerCareModule.gift_card_details.redemption_value',
        RESEND_ACTIVATION: 'customerCareModule.gift_card_details.resend_activation',
        RESEND_BUTTON_LABEL: 'customerCareModule.gift_card_details.resend_button_label',
        RETURN_TO_GIFT_CARD: 'customerCareModule.gift_card_details.return_to_gift_card',
        SEND_DATE: 'customerCareModule.gift_card_details.send_date',
        SENDER_EMAIL: 'customerCareModule.gift_card_details.sender_email',
        SENDER_NAME: 'customerCareModule.gift_card_details.sender_name',
        SUCCESS: 'customerCareModule.gift_card_details.success',
        TYPE: 'customerCareModule.gift_card_details.type',
        TYPE_ELECTRONIC: 'customerCareModule.gift_card_details.type_electronic',
        TYPE_PHYSICAL: 'customerCareModule.gift_card_details.type_physical'
    },
    GO: 'customerCareModule.go',
    GENDER: 'customerCareModule.gender',
    GENERAL_INFO: 'customerCareModule.general_info',
    GLOBAL_CASE_REFERENCE: 'customerCareModule.global_case_reference',
    GRANT_ACCESS: {
        ADD_ADDITIONAL_ACCESS: 'customerCareModule.grant_access.add_additional_access',
        ERROR: {
            WHOLE_NUMBER: 'customerCareModule.grant_access.error.whole_number'
        },
        GRANT_POLICY: 'customerCareModule.grant_access.grant_policy',
        GRANT_ACCESS: 'customerCareModule.grant_access.grant_access',
        GRANT_LICENSE: 'customerCareModule.grant_access.grant_license',
        GRANT_TIME_REMAINING: 'customerCareModule.grant_access.grant_time_remaining',
        HOURS: 'customerCareModule.grant_access.hours',
        NOT_AVAILABLE: 'customerCareModule.grant_access.not_available',
        POLICY_NAME: 'customerCareModule.grant_access.policy_name',
        SELECT_POLICY: 'customerCareModule.grant_access.select_policy',
        SUCCESSFULLY_UPDATED: 'customerCareModule.grant_access.successfully_updated'
    },
    HIERARCHY_INFORMATION: 'customerCareModule.hierarchy_information',
    HIERARCHY_NAME: 'customerCareModule.hierarchy_name',
    HIERARCHY_NAME_REQUIRED: 'customerCareModule.hierarchy_name_required',
    HIERARCHY_PARENT_NODE_ID: 'customerCareModule.hierarchy_parent_node_id',
    HIERARCHY_SUCCESSFULLY_CREATED: 'customerCareModule.hierarchy_created_successfully',
    HOME_COUNTRY: 'customerCareModule.home_country',
    HOME_PHONE: 'customerCareModule.home_phone',
    HOUSEHOLD: {
        ASSIGN_TO: 'customerCareModule.household.assign_to',
        ASSOCIATE_SERVICES: 'customerCareModule.household.associate_services',
        ASSOCIATE_SERVICES_ALLOCATION_INSTRUCTIONS: 'customerCareModule.household.associate_services_allocation_instructions',
        CREATE_HOUSEHOLD: 'customerCareModule.household.create_household',
        CREATE_HOUSEHOLD_SUCCESS: 'customerCareModule.household.create_household_success',
        CREATE_MEMBER: 'customerCareModule.household.create_member',
        CREATE_MEMBER_SUCCESS: 'customerCareModule.household.create_member_success',
        CREATE_NEW_MEMBER: 'customerCareModule.household.create_new_member',
        DEFAULT_HOUSEHOLD_NAME: 'customerCareModule.household.default_household_name',
        DELETE_AUTH_CONFIRMATION_NO: 'customerCareModule.household.delete_auth_confirmation_no',
        DETAILS: 'customerCareModule.household.details',
        DIALOG: {
            REMOVE_HOUSEHOLD: {
                MESSAGE: 'customerCareModule.household.dialog.remove_household.message',
                SUCCESS: 'customerCareModule.household.dialog.remove_household.success',
                TITLE: 'customerCareModule.household.dialog.remove_household.title'
            }
        },
        EDIT: 'customerCareModule.household.edit',
        EDIT_HOUSEHOLD: 'customerCareModule.household.edit_household',
        EDIT_PRIVILEGES: 'customerCareModule.household.edit_privileges',
        ERRORS: {
            ACCUMULATE_DATE_INVALID: 'customerCareModule.household.errors.accumulate_date_invalid',
            ACCUMULATE_MIN_DATE: 'customerCareModule.household.errors.accumulate_date_min_date',
            ACCUMULATE_DATE_REQUIRED: 'customerCareModule.household.errors.accumulate_date_required',
            HOUSEHOLD_NAME_REQUIRED: 'customerCareModule.household.errors.household_name_required',
            HOUSEHOLD_NAME_100_CHAR: 'customerCareModule.household.errors.household_name_100_char',
            MEMBER_REQUIRED: 'customerCareModule.household.errors.member_required'
        },
        FIRST_NAME: 'customerCareModule.household.first_name',
        FULL_ACCESS_PRIVILEGES: 'customerCareModule.household.full_access_privileges',
        HEAD_OF_HOUSEHOLD: 'customerCareModule.household.head_of_household',
        HOUSEHOLD: 'customerCareModule.household.household',
        HOUSEHOLD_CONVERGENT_BILLER_ERROR: 'customerCareModule.household.household_convergent_biller_error',
        HOUSEHOLD_NAME: 'customerCareModule.household.household_name',
        HOUSEHOLD_STATUS: 'customerCareModule.household.household_status',
        KEYBINDING_DESCRIPTIONS: {
            DOWN: 'customerCareModule.household.keybinding_descriptions.down',
            UP: 'customerCareModule.household.keybinding_descriptions.up'
        },
        LAST_NAME: 'customerCareModule.household.last_name',
        MEMBER: 'customerCareModule.household.member',
        MEMBER_PRIVILEGES: 'customerCareModule.household.member_privileges',
        MEMBER_SUMMARY: 'customerCareModule.household.member_summary',
        MEMBER_UPDATE_SUCCESS: 'customerCareModule.household.member_update_success',
        MEMBERS: 'customerCareModule.household.members',
        MORE: 'customerCareModule.household.more',
        NEW_HOUSEHOLD: 'customerCareModule.household.new_household',
        NEW_MEMBER: 'customerCareModule.household.new_member',
        NO_ASSOCIATED_SERVICES: 'customerCareModule.household.no_associated_services',
        NO_HOUSEHOLDS: 'customerCareModule.household.no_households',
        NO_SERVICES: 'customerCareModule.household.no_services',
        OF_UNLIMITED: 'customerCareModule.household.of_unlimited',
        PRIVILEGES: {
            ACCESS_PRIVILEGES: 'customerCareModule.household.privileges.access_privileges',
            ACCESS_PRIVILEGES_NOT_ENFORCED: 'customerCareModule.household.privileges.access_privileges_not_enforced',
            ACTIVATE: 'customerCareModule.household.privileges.activate',
            ACTIVE: 'customerCareModule.household.privileges.active',
            ACCUMULATE_DATE: 'customerCareModule.household.privileges.accumulate_date',
            ALLOW: 'customerCareModule.household.privileges.allow',
            BALANCE: 'customerCareModule.household.privileges.balance',
            BALANCE_ACCUMULATES: 'customerCareModule.household.privileges.balance_accumulates',
            BALANCE_ACCUMULATES_TOOLTIP: 'customerCareModule.household.privileges.balance_accumulates_tooltip',
            BALANCE_DETAILS: 'customerCareModule.household.privileges.balance_details',
            CREATE_PAYMENT_METHOD: 'customerCareModule.household.privileges.create_payment_method',
            CREATE_PAYMENT_METHODS: 'customerCareModule.household.privileges.create_payment_methods',
            DAYS: 'customerCareModule.household.privileges.days',
            DAYS_FOR_SPENDING_LIMIT: 'customerCareModule.household.privileges.days_for_spending_limit',
            ENABLE: 'customerCareModule.household.privileges.enable',
            ENFORCE: 'customerCareModule.household.privileges.enforce',
            ENFORCE_ACCESS_PRIVILEGES: 'customerCareModule.household.privileges.enforce_access_privileges',
            ENFORCE_ACCESS_PRIVILEGES_TOOLTIP: 'customerCareModule.household.privileges.enforce_access_privileges_tooltip',
            ENFORCE_PAYMENT_PRIVILEGES: 'customerCareModule.household.privileges.enforce_payment_privileges',
            ENFORCE_PAYMENT_PRIVILEGES_TOOLTIP: 'customerCareModule.household.privileges.enforce_payment_privileges_tooltip',
            ENFORCE_USAGE_PRIVILEGES: 'customerCareModule.household.privileges.enforce_usage_privileges',
            ENFORCE_USAGE_PRIVILEGES_TOOLTIP: 'customerCareModule.household.privileges.enforce_usage_privileges_tooltip',
            EXPIRES_ON: 'customerCareModule.household.privileges.expires_on',
            LIMIT_SPENDING: 'customerCareModule.household.privileges.limit_spending',
            MEMBER_PRIVILEGES: 'customerCareModule.household.privileges.member_privileges',
            NEXT_ACCUMULATE_DATE: 'customerCareModule.household.privileges.next_accumulate_date',
            NIGHTS: 'customerCareModule.household.privileges.nights',
            NO_ACCESS_PRIVILEGES_SET: 'customerCareModule.household.privileges.no_access_privileges_set',
            NO_SHAREABLE_PAYMENT_METHODS: 'customerCareModule.household.privileges.no_shareable_payment_methods',
            PAYMENT_PRIVILEGES: 'customerCareModule.household.privileges.payment_privileges',
            PAYMENT_PRIVILEGES_NOT_ENFORCED: 'customerCareModule.household.privileges.payment_privileges_not_enforced',
            SERVICE_IDS: 'customerCareModule.household.privileges.service_ids',
            SERVICE_TIMES: 'customerCareModule.household.privileges.service_times',
            SHARE_PAYMENT_METHODS: 'customerCareModule.household.privileges.share_payment_methods',
            SHARED_PAYMENT_METHODS: 'customerCareModule.household.privileges.shared_payment_methods',
            SPENDING_LIMIT: 'customerCareModule.household.privileges.spending_limit',
            SPENDING_LIMIT_EVERY: 'customerCareModule.household.privileges.spending_limit_every',
            SPENDING_PRIVILEGES: 'customerCareModule.household.privileges.spending_privileges',
            TIME_ZONE: 'customerCareModule.household.privileges.time_zone',
            USAGE_PRIVILEGES: 'customerCareModule.household.privileges.usage_privileges',
            USAGE_PRIVILEGES_NOT_ENFORCED: 'customerCareModule.household.privileges.usage_privileges_not_enforced',
            WEEKENDS: 'customerCareModule.household.privileges.weekends'
        },
        REACTIVATE_HOUSEHOLD: 'customerCareModule.household.reactivate_household',
        REACTIVATE_HOUSEHOLD_SUCCESS: 'customerCareModule.household.reactivate_household_success',
        REACTIVATE_HOUSEHOLD_MEMBER_SUCCESS_MESSAGE: 'customerCareModule.household.reactivate_household_member_success_message',
        REACTIVATE_MEMBER: 'customerCareModule.household.reactivate_member',
        REMOVE: 'customerCareModule.household.remove',
        REMOVE_HOUSEHOLD: 'customerCareModule.household.remove_household',
        REMOVE_HOUSEHOLD_MEMBER_CONFIRMATION_TITLE: 'customerCareModule.household.remove_household_member_confirmation_title',
        REMOVE_HOUSEHOLD_MEMBER_CONFIRMATION_MESSAGE: 'customerCareModule.household.remove_household_member_confirmation_message',
        REMOVE_HOUSEHOLD_MEMBER_FAILURE_MESSAGE: 'customerCareModule.household.remove_household_member_failure_message',
        REMOVE_HOUSEHOLD_MEMBER_FAILURE_TITLE: 'customerCareModule.household.remove_household_member_failure_title',
        REMOVE_HOUSEHOLD_MEMBER_SUCCESS_MESSAGE: 'customerCareModule.household.remove_household_member_success_message',
        REMOVE_MEMBER: 'customerCareModule.household.remove_member',
        SAVE_HOUSEHOLD: 'customerCareModule.household.save_household',
        SAVE_HOUSEHOLD_SERVICES_ASSOCIATIONS_SUCCESS: 'customerCareModule.household.save_household_services_associations_success',
        SERVICE: 'customerCareModule.household.service',
        SERVICE_ALLOCATION: 'customerCareModule.household.service_allocation',
        SERVICE_LEVEL_PRIVILEGES: 'customerCareModule.household.service_level_privileges',
        SINGLE_SELECT_PLACEHOLDER: 'customerCareModule.household.single_select_placeholder',
        SHARED_ENTITLEMENTS: 'customerCareModule.household.shared_entitlements',
        SUBSCRIBER_ID: 'customerCareModule.household.subscriber_id',
        UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_SUCCESS: 'customerCareModule.household.update_household_services_associations_success',
        UPDATE_HOUSEHOLD_SUCCESS: 'customerCareModule.household.update_household_success',
        USAGE: 'customerCareModule.household.usage'
    },
    IDENTIFIER: 'customerCareModule.identifier',
    IDENTIFIER_ABBREVIATION: 'customerCareModule.identifier_abbreviation',
    INCLUDE_EXPIRED: 'customerCareModule.include_expired',
    INCLUDED_PRODUCTS: 'customerCareModule.included_products',
    INCLUDE_REMOVED: 'customerCareModule.include_removed',
    INCOME_LEVEL: 'customerCareModule.income_level',
    INDIVIDUAL: 'customerCareModule.individual',
    INDIVIDUAL_EPISODES: 'customerCareModule.individual_episodes',
    INDIVIDUAL_USAGE: 'customerCareModule.individual_usage',
    INTERACTION: 'customerCareModule.interaction',
    INTERACTIONS: 'customerCareModule.interactions',
    INTERACTION_ACTIVITY: {
        DATE: 'customerCareModule.interaction_activity.date',
        DESCRIPTION: 'customerCareModule.interaction_activity.description',
        MODIFIED_BY: 'customerCareModule.interaction_activity.modified_by',
        NO_RESULTS_FOUND: 'customerCareModule.interaction_activity.no_results_found',
        TITLE: 'customerCareModule.interaction_activity.title',
        TYPE_DESCRIPTION: 'customerCareModule.interaction_activity.type_description'
    },
    INTERNAL: 'customerCareModule.internal',
    INVALID_ORDER_SAVE: 'customerCareModule.invalid_order_save',
    INVENTORY: {
        ACTIVITY: 'customerCareModule.inventory.activity',
        ADD_INVENTORY: 'customerCareModule.inventory.add_inventory',
        ADD_ON: 'customerCareModule.inventory.add_on',
        ADDED_ON: 'customerCareModule.inventory.added_on',
        AMOUNT: 'customerCareModule.inventory.amount',
        BALANCE: 'customerCareModule.inventory.balance',
        CHANGE_STORE: 'customerCareModule.inventory.change_store',
        DATE: 'customerCareModule.inventory.date',
        DESCRIPTION: 'customerCareModule.inventory.description',
        DISPLAY_NAME: 'customerCareModule.inventory.display_name',
        FINANCE_DETAILS: {
            AMOUNT: 'customerCareModule.inventory.finance_details.amount',
            CURRENT_INSTALLMENT_AMOUNT: 'customerCareModule.inventory.finance_details.current_installment_amount',
            DEVICE_FINANCE_ACCOUNT_NUMBER: 'customerCareModule.inventory.finance_details.device_finance_account_number',
            DEVICE_FINANCING_MARKETING_TEXT: 'customerCareModule.inventory.finance_details.device_financing_marketing_text',
            DEVICE_FULL_PRICE: 'customerCareModule.inventory.finance_details.device_full_price',
            DEVICE_ID: 'customerCareModule.inventory.finance_details.device_id',
            DOWN_PAYMENT: 'customerCareModule.inventory.finance_details.down_payment',
            FINANCE_PERIOD: 'customerCareModule.inventory.finance_details.finance_period',
            INSTALLMENT_END_DATE: 'customerCareModule.inventory.finance_details.installment_end_date',
            PAYMENT_ACTIVITY: 'customerCareModule.inventory.finance_details.payment_activity',
            PAYMENT_TYPES: {
                ACPBILL: 'customerCareModule.inventory.finance_details.payment_types.acpbill',
                AMAZON_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.amazon_account',
                BATCH: 'customerCareModule.inventory.finance_details.payment_types.batch',
                BRAIN_TREE: 'customerCareModule.inventory.finance_details.payment_types.brain_tree',
                CASH: 'customerCareModule.inventory.finance_details.payment_types.cash',
                CHECK_REFUND: 'customerCareModule.inventory.finance_details.payment_types.check_refund',
                CHECK: 'customerCareModule.inventory.finance_details.payment_types.check',
                CONVERGENTBILLER_INVOICE: 'customerCareModule.inventory.finance_details.payment_types.convergentbiller_invoice',
                CONVERSION: 'customerCareModule.inventory.finance_details.payment_types.conversion',
                CREDIT_CARD: 'customerCareModule.inventory.finance_details.payment_types.credit_card',
                CURRENCY_ENTITLEMENT: 'customerCareModule.inventory.finance_details.payment_types.currency_entitlement',
                DIRECT_DEBIT: 'customerCareModule.inventory.finance_details.payment_types.direct_debit',
                E_CHECK: 'customerCareModule.inventory.finance_details.payment_types.e_check',
                EXTERNAL_BILL: 'customerCareModule.inventory.finance_details.payment_types.external_bill',
                EXTERNAL_GIFT_CARD: 'customerCareModule.inventory.finance_details.payment_types.external_gift_card',
                FACEBOOK_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.facebook_account',
                GIFT_CARD: 'customerCareModule.inventory.finance_details.payment_types.gift_card',
                GOOGLEPLAY_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.googleplay_account',
                ITUNES_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.itunes_account',
                LOCKBOX_PAYMENT: 'customerCareModule.inventory.finance_details.payment_types.lockbox_payment',
                PAYPAL_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.paypal_account',
                POINT_OF_SALE: 'customerCareModule.inventory.finance_details.payment_types.point_of_sale',
                ROKU_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.roku_account',
                STORED_VALUE_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.stored_value_account',
                UWP_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.uwp_account',
                XBOX_ACCOUNT: 'customerCareModule.inventory.finance_details.payment_types.xbox_account'
            },
            REMAINING_BALANCE: 'customerCareModule.inventory.finance_details.remaining_balance',
            SERIAL_NUMBER: 'customerCareModule.inventory.finance_details.serial_number',
            STARTING_BALANCE: 'customerCareModule.inventory.finance_details.starting_balance',
            TERM_AMOUNT: 'customerCareModule.inventory.finance_details.term_amount',
            TERM_AMOUNT_PLURAL: 'customerCareModule.inventory.finance_details.term_amount_plural'
        },
        FINANCING_DETAILS: 'customerCareModule.inventory.financing_details',
        FRIENDLY_NAME: 'customerCareModule.inventory.friendly_name',
        HEADING: 'customerCareModule.inventory.heading',
        INVENTORY_ID: 'customerCareModule.inventory.inventory_id',
        INVENTORY_NAME: 'customerCareModule.inventory.inventory_name',
        INVENTORY_STATUS: 'customerCareModule.inventory.inventory_status',
        ITEM_HEADING: 'customerCareModule.inventory.item_heading',
        MAKE_TYPE_BRAND: 'customerCareModule.inventory.make_type_brand',
        MODAL: {
            FILTER_BY_REGION: 'customerCareModule.inventory.modal.filter_by_region',
            STORE_FINDER: 'customerCareModule.inventory.modal.store_finder',
            STORE_NAME: 'customerCareModule.inventory.modal.store_name',
            STORE_RESULT: 'customerCareModule.inventory.modal.store_result',
            STORE_RESULT_PLURAL: 'customerCareModule.inventory.modal.store_result_plural'
        },
        MODEL_NUMBER: 'customerCareModule.inventory.model_number',
        MY_STORE: 'customerCareModule.inventory.my_store',
        NO_CUSTOMER_INVENTORIES: 'customerCareModule.inventory.no_customer_inventories',
        ONE_TIME_CHARGE: 'customerCareModule.inventory.one_time_charge',
        OFFER_NAME: 'customerCareModule.inventory.offer_name',
        OVERVIEW: 'customerCareModule.inventory.overview',
        PAYMENT: 'customerCareModule.inventory.payment',
        PAYMENT_TYPE: 'customerCareModule.inventory.payment_type',
        PRICING_PLAN_NAME: 'customerCareModule.inventory.pricing_plan_name',
        REMOVED_ON: 'customerCareModule.inventory.removed_on',
        RETURN_DEVICE: 'customerCareModule.inventory.return_device',
        RETURN_DEVICE_TEXT: 'customerCareModule.inventory.return_device_text',
        SELECT_STORE: 'customerCareModule.inventory.select_store',
        SERIAL_NUMBER: 'customerCareModule.inventory.serial_number',
        SERVICE_ID: 'customerCareModule.inventory.service_id',
        SERVICE_STATUS: 'customerCareModule.inventory.service_status',
        TRANSACTION_ID: 'customerCareModule.inventory.transaction_id',
        TROUBLE_CALL: 'customerCareModule.inventory.trouble_call',
        UPDATE_FRIENDLY_NAME: {
            CLEAR_TEXT: 'customerCareModule.inventory.update_friendly_name.clear_text',
            MENU_OPTION: 'customerCareModule.inventory.update_friendly_name.menu_option',
            MODAL_TITLE: 'customerCareModule.inventory.update_friendly_name.modal_title',
            SUBMIT_TEXT: 'customerCareModule.inventory.update_friendly_name.submit_text'
        }
    },
    INVENTORY_AVAILABILITY_POPUP: {
        CONTINUE: 'customerCareModule.inventory_availability_popup.continue',
        MODIFY_ATTRIBUTES: 'customerCareModule.inventory_availability_popup.modify_attributes',
        REMAINING: 'customerCareModule.inventory_availability_popup.remaining',
        TITLE: 'customerCareModule.inventory_availability_popup.title',
        UNAVAILABLE_MESSAGE: 'customerCareModule.inventory_availability_popup.unavailable_message'
    },
    INVENTORY_MENU: 'customerCareModule.inventory_menu',
    INVENTORY_ITEM: 'customerCareModule.inventory_item',
    INVOICE: 'customerCareModule.invoice',
    INVOICE_ADJUSTMENT: 'customerCareModule.invoice_adjustment',
    INVOICE_DATES: 'customerCareModule.invoice_type',
    INVOICE_DELIVERY_METHOD: 'customerCareModule.invoice_delivery_method',
    INVOICE_DETAIL_LEVEL: 'customerCareModule.invoice_detail_level',
    INVOICE_DETAIL_LEVEL_OPTIONS: {
        DETAIL: 'customerCareModule.invoice_detail_level_options.detail',
        SUMMARY: 'customerCareModule.invoice_detail_level_options.summary'
    },
    INVOICE_DISPUTE: 'customerCareModule.invoice_dispute',
    INVOICE_FORMAT: 'customerCareModule.invoice_format',
    INVOICE_NAME: 'customerCareModule.invoice_name',
    INVOICE_NUMBER: 'customerCareModule.invoice_number',
    INVOICE_TYPE: 'customerCareModule.invoice_type',
    LANGUAGE: 'customerCareModule.language',
    LAST_ACCESS_COUNTRY: 'customerCareModule.last_access_country',
    LAST_ACCESS_COUNTRY_TOOLTIP: 'customerCareModule.last_access_country_tooltip',
    LAST_ACCESS_COUNTRY_OVERRIDE: 'customerCareModule.last_access_country_override',
    LAST_LOGIN_FAILURE: 'customerCareModule.last_login_failure',
    LAST_LOGIN_SUCCESS: 'customerCareModule.last_login_success',
    LAST_NAME: 'last_name',
    LAST_NAME_REQUIRED: 'last_name_required',
    LEAD_SOURCE: 'customerCareModule.lead_source',
    LEDGER: 'customerCareModule.ledger',
    LESS: 'customerCareModule.less',
    LIFE_CYCLE: {
        DEFAULT: 'customerCareModule.life_cycle.default',
        EDIT_LIFE_CYCLE_TITLE: 'customerCareModule.life_cycle.edit_life_cycle_title',
        LIFE_CYCLE_TITLE: 'customerCareModule.life_cycle.life_cycle_title',
        PLAN_CHANGE_MESSAGE: 'customerCareModule.life_cycle.plan_change_message',
        ONGOING_TITLE: 'customerCareModule.life_cycle.ongoing_title',
        STARTS: 'customerCareModule.life_cycle.starts',
        VIEW_LIFE_CYCLE: 'customerCareModule.life_cycle.view_life_cycle'
    },
    LIST_OF_SERVICES: 'customerCareModule.list_of_services',
    LOADING: 'customerCareModule.loading',
    LOCKER: 'customerCareModule.locker',
    LOGIN: 'customerCareModule.login',
    LOGIN_ERROR_COUNT: 'customerCareModule.login_error_count',
    MAKE_A_PAYMENT: 'customerCareModule.make_a_payment',
    MAKE_PAYMENT: {
        ACCOUNT_NUMBER: 'customerCareModule.account_number',
        ACCOUNT_NUMBER_REQUIRED: 'customerCareModule.make_payment.account_number_required',
        ADDRESS: 'customerCareModule.make_payment.address',
        ADD_NEW_PAYMENT_METHOD: 'customerCareModule.make_payment.add_new_payment_method',
        ADD_METHOD: 'customerCareModule.make_payment.add_method',
        ALL_PAYMENT_METHODS_USED: 'customerCareModule.make_payment.all_payment_methods_used',
        AMOUNT_EXCEEDED: 'customerCareModule.make_payment.amount_exceeded',
        AMOUNT_INVALID: 'customerCareModule.make_payment.amount_invalid',
        AMOUNT_REQUIRED: 'customerCareModule.make_payment.amount_required',
        BILLING_ADDRESS: 'customerCareModule.make_payment.billing_address',
        BILLING_ADDRESS_IS_SERVICE_ADDRESS: 'customerCareModule.make_payment.billing_address_is_service_address',
        CARD_CVV: 'customerCareModule.make_payment.cvv',
        CARD_EXP_DATE: 'customerCareModule.make_payment.expiration_date',
        CARD_NUMBER: 'customerCareModule.make_payment.card_number',
        CARD_TYPE_AND_LAST_FOUR: 'customerCareModule.make_payment.card_type_and_last_four',
        CREATE_INSTRUMENT_SUCCESS: 'customerCareModule.make_payment.create_instrument_success',
        CREATE_ONE_NOW: 'customerCareModule.make_payment.create_one_now',
        CURRENT_BALANCE: 'customerCareModule.make_payment.current_balance',
        CUSTOM_PAYMENT_AMOUNT: 'customerCareModule.make_payment.custom_payment_amount',
        DEVICE_BALANCE: 'customerCareModule.make_payment.device_balance',
        DUE_BY: 'customerCareModule.make_payment.due_by',
        EDIT: 'customerCareModule.make_payment.edit',
        EDIT_INSTRUMENT_SUCCESS: 'customerCareModule.make_payment.edit_instrument_success',
        EDIT_PAYMENT_BUTTON: 'customerCareModule.make_payment.edit_payment_button',
        EDIT_PAYMENT_TITLE: 'customerCareModule.make_payment.edit_payment_title',
        ERRORS: {
            ACCOUNT_NUMBER_REQUIRED: 'customerCareModule.make_payment.errors.account_number_required',
            CARD_NUMBER: 'customerCareModule.make_payment.errors.card_number',
            CARD_NUMBER_REQUIRED: 'customerCareModule.make_payment.errors.card_number_required',
            CARD_TYPE: 'customerCareModule.make_payment.errors.card_type',
            CARD_TYPE_REQUIRED: 'customerCareModule.make_payment.errors.card_type_required',
            CITY_REQUIRED: 'customerCareModule.make_payment.errors.city_required',
            COUNTRY_REQUIRED: 'customerCareModule.make_payment.errors.country_required',
            CVV: 'customerCareModule.make_payment.errors.cvv',
            CVV_REQUIRED: 'customerCareModule.make_payment.errors.cvv_required',
            EXPIRATION_DATE_INVALID: 'customerCareModule.make_payment.errors.expiration_date_invalid',
            EXPIRATION_MONTH: 'customerCareModule.make_payment.errors.expiration_month',
            EXPIRATION_MONTH_REQUIRED: 'customerCareModule.make_payment.errors.expiration_month_required',
            EXPIRATION_REQUIRED: 'customerCareModule.make_payment.errors.expiration_required',
            EXPIRATION_YEAR: 'customerCareModule.make_payment.errors.expiration_year',
            EXPIRATION_YEAR_REQUIRED: 'customerCareModule.make_payment.errors.expiration_year_required',
            EXTERNAL_BILL: {
                ACCOUNT_NUMBER_INVALID_PATTERN: 'uiModule.external_bill.errors.account_number_invalid_pattern',
                ACCOUNT_NUMBER_REQUIRED: 'uiModule.external_bill.errors.account_number_required',
                AUTHORIZATION_CHALLENGE_REQUIRED: 'uiModule.external_bill.errors.authorization_challenge_required',
                EXPIRATION_INVALID_DATE: 'uiModule.external_bill.errors.expiration_invalid_date',
                NAME_ON_ACCOUNT_REQUIRED: 'uiModule.external_bill.errors.name_on_account_required',
                PHONE_NUMBER_REQUIRED: 'uiModule.external_bill.errors.phone_number_required',
            },
            EXTERNAL_GIFT_CARD: {
                ACCOUNT_NUMBER: 'uiModule.external_gift_card.account_number',
                ACCOUNT_NUMBER_INVALID_PATTERN: 'uiModule.external_gift_card.errors.account_number_invalid_pattern',
                ACCOUNT_NUMBER_REQUIRED: 'uiModule.external_gift_card.errors.account_number_required',
                AUTH_KEY: 'uiModule.external_gift_card.authorization_key',
                AUTH_KEY_REQUIRED: 'uiModule.external_gift_card.errors.authorization_key_required',
                CARD_TYPE: 'uiModule.external_gift_card.card_type',
                CARD_TYPE_REQUIRED: 'uiModule.external_gift_card.errors.card_type_required'
            },
            GIFT_CARD_PIN: 'uiModule.giftcard.pin',
            GIFT_CARD_PIN_REQUIRED: 'uiModule.giftcard.errors.required_pin',
            NAME_ON_CARD: 'customerCareModule.make_payment.errors.name_on_card',
            NAME_ON_CARD_REQUIRED: 'customerCareModule.make_payment.errors.name_on_card_required',
            POSTAL_CODE_REQUIRED: 'customerCareModule.make_payment.errors.postal_code_required',
            ROUTING_NUMBER_REQUIRED: 'customerCareModule.make_payment.errors.routing_number_required',
            STATE_REGION_PROVINCE_REQUIRED: 'customerCareModule.make_payment.errors.state_region_province_required'
        },
        EXPIRES: 'customerCareModule.make_payment.expires',
        INVOICE_BALANCE: 'customerCareModule.make_payment.invoice_balance',
        INVOICE_NUMBER: 'customerCareModule.billing_and_payment_invoice.invoice_number',
        MAKE_PAYMENT: 'customerCareModule.make_payment.make_payment',
        MONTH_FORMAT_MM: 'customerCareModule.make_payment.mm',
        NAME_ON_CARD: 'customerCareModule.make_payment.name_on_card',
        NO_INSTRUMENT_AVAILABLE: 'customerCareModule.make_payment.no_instrument_available',
        NO_INSTRUMENT_REQUIRED: 'customerCareModule.make_payment.no_instrument_required',
        NO_INSTRUMENT_REQUIRED_GENERAL: 'customerCareModule.make_payment.no_instrument_required_general',
        NO_INVOICE_FOUND: 'customerCareModule.make_payment.no_invoice_found',
        NO_PAYMENT_METHODS_PRESENT: 'customerCareModule.make_payment.no_payment_methods_present',
        NO_PAYMENT_METHODS_ON_ACCOUNT: 'customerCareModule.make_payment.no_payment_methods_on_account',
        PAYMENT_AMOUNT: 'customerCareModule.make_payment.payment_amount',
        PAYMENT_FAILURE: 'customerCareModule.make_payment.payment_failure',
        PAYMENT_METHOD: 'customerCareModule.make_payment.payment_method',
        PAYMENT_METHOD_REQUIRED: 'customerCareModule.make_payment.payment_method_required',
        PAYMENT_SUCCESSFUL: 'customerCareModule.make_payment.payment_successful',
        PAYMENT_TYPE: 'customerCareModule.make_payment.payment_type',
        PROMISE_TO_PAY_INSTALLMENT_TOTAL: 'customerCareModule.make_payment.promise_to_pay_installment',
        PROMISE_TO_PAY_TREATMENT_BALANCE: 'customerCareModule.make_payment.promise_to_pay_total_balance',
        SELECT_BILLING_ADDRESS: 'customerCareModule.make_payment.select_billing_address',
        SELECT_EXISTING_PAYMENT_METHOD: 'customerCareModule.make_payment.select_existing_payment_method',
        SELECT_PAYMENT: 'customerCareModule.make_payment.select_payment_method',
        SELECT_PAYMENT_METHOD: 'customerCareModule.make_payment.select_payment_method',
        STATEMENT_BALANCE: 'customerCareModule.make_payment.statement_balance',
        TOTAL_ACCOUNT_BALANCE: 'customerCareModule.make_payment.total_account_balance',
        YEAR_FORMAT_YY: 'customerCareModule.make_payment.yy',
        YOUR_PAYMENT_METHODS: 'customerCareModule.make_payment.your_payment_methods'
    },
    MANAGE_ADD_ONS: 'customerCareModule.manage_add_ons',
    MANDATORY_CONSENTS_POPUP: {
        NOTIFICATION_TEXT: 'customerCareModule.mandatory_consents_popup.notification_text'
    },
    MAXIMUM: 'customerCareModule.maximum',
    MAXIMUM_WITH_VALUE: 'customerCareModule.maximum_with_value',
    MAX_QUANTITY_ERROR: 'customerCareModule.max_quantity_error',
    METHOD: 'customerCareModule.method',
    MIDDLE_NAME: 'customerCareModule.middle_name',
    MINIMUM: 'customerCareModule.minimum',
    MINIMUM_WITH_VALUE: 'customerCareModule.minimum_with_value',
    MISC_ADJUSTMENT: 'customerCareModule.misc_adjustment',
    MOBILE_NUMBER_PORTABILITY: {
        ACCOUNT_HOLDER_DOB: 'customerCareModule.mobile_number_portability.account_holder_dob',
        AGREEMENT: 'customerCareModule.mobile_number_portability.agreement',
        ASSOCIATED_ADDRESS: 'customerCareModule.mobile_number_portability.associated_address',
        CONFIRM: 'customerCareModule.mobile_number_portability.confirm',
        CONFIRM_TITLE: 'customerCareModule.mobile_number_portability.confirm_title',
        EDIT: 'customerCareModule.mobile_number_portability.edit',
        ELIGIBLE_DISCLAIMER: 'customerCareModule.mobile_number_portability.eligible_disclaimer',
        ENTER_NUMBER: 'customerCareModule.mobile_number_portability.enter_number',
        ERROR_TITLE: 'customerCareModule.mobile_number_portability.error_title',
        EXISTING_NUMBER: 'customerCareModule.mobile_number_portability.existing_number',
        FORM_TITLE: 'customerCareModule.mobile_number_portability.form_title',
        NO_ASSIGN_NEW_NUMBER: 'customerCareModule.mobile_number_portability.no_assign_new_number',
        NUMBER_TYPE: 'customerCareModule.mobile_number_portability.number_type',
        PROVIDER: 'customerCareModule.mobile_number_portability.provider',
        TELEPHONE_NUMBER_PORT: 'customerCareModule.mobile_number_portability.telephone_number_port',
        UPDATE_SUCCESS: 'customerCareModule.mobile_number_portability.update_success',
        VERIFY: 'customerCareModule.mobile_number_portability.verify',
        YES_PORT_EXISTING_NUMBER: 'customerCareModule.mobile_number_portability.yes_port_existing_number'
    },
    MOBILE_PHONE: 'customerCareModule.mobile_phone',
    MODIFIED_BY: 'modified_by',
    MODIFIED_DATE: 'modified_date',
    MODIFY: 'customerCareModule.modify',
    MODIFY_PRODUCT_ORDER: 'customerCareModule.modify_product_order',
    MORE: 'customerCareModule.more',
    MORE_OPTIONS: 'customerCareModule.more_options',
    MULTIPLE_CONTRACTS_CAN_NOT_FULL_DISCONNECT: 'customerCareModule.multiple_contracts_can_not_full_disconnect',
    MULTIPLICATION_SYMBOL: 'customerCareModule.multiplication_symbol',
    NAME: 'customerCareModule.name',
    NARROW_RESULTS: 'customerCareModule.narrow_results',
    NAVIGATOR_CAPTION: {
        FLYOUT: 'customerCareModule.navigator_caption.flyout',
        RECENT: 'customerCareModule.navigator_caption.recent',
        SEARCH: 'customerCareModule.navigator_caption.search'
    },
    NETWORK_IDENTIFIER: 'customerCareModule.network_identifier',
    NETWORK_NAME: 'customerCareModule.network_name',
    NEW: 'customerCareModule.new',
    NEW_ACCOUNT_HIERARCHY: 'customerCareModule.new_account_hierarchy',
    NEW_ACTIVITY: 'customerCareModule.new_activity',
    NEW_ADDRESS: 'customerCareModule.subscriber.new_address',
    NEW_CASE: 'customerCareModule.subscriber.new_case',
    NEW_CASE_WITH_TYPE: 'customerCareModule.subscriber.new_case_with_type',
    NEW_CONNECT_TERMS_CONDITIONS: {
        AGREE: 'customerCareModule.new_connect_terms_conditions.agree',
        TERMS: 'customerCareModule.new_connect_terms_conditions.terms'
    },
    NEW_OFFERING_ORDER: 'customerCareModule.new_offering_order',
    NEW_ORDER_SEARCH: 'customerCareModule.new_order_search',
    NEW_PIN: 'customerCareModule.new_pin',
    NEW_PRODUCT_ORDER: 'customerCareModule.new_product_order',
    NEW_SUBSCRIPTION_ORDER: 'customerCareModule.new_subscription_order',
    NEXT: 'customerCareModule.next',
    NO: CoreLocaleKeys.NO,
    NO_CUSTOMER: 'customerCareModule.no_customer',
    NO_CUSTOMER_ACTIVE_CONTENT: 'customerCareModule.no_customer_active_content',
    NO_CUSTOMER_INTERACTIONS: 'customerCareModule.no_customer_interactions',
    NO_CUSTOMER_REMARKS: 'customerCareModule.no_customer_remarks',
    NO_DATA: 'customerCareModule.no_data',
    NO_DATA_ADJUST_FILTERS: 'customerCareModule.no_data_adjust_filters',
    NO_END_DATE: 'customerCareModule.no_end_date',
    NO_FILTERED_RESULTS: 'customerCareModule.no_filtered_results',
    NO_LIMIT: 'customerCareModule.no_limit',
    NO_OFFERINGS_AVAILABLE: 'customerCareModule.no_offerings_available',
    NO_PRICING_PLANS_AVAILABLE: 'customerCareModule.no_pricing_plans_available',
    NO_PRODUCTS_AVAILABLE: 'customerCareModule.no_products_available',
    NO_PRODUCTS_AVAILABLE_ON_SYSTEM: 'customerCareModule.no_products_available_on_system',
    NO_RECENT_CUSTOMERS: 'customerCareModule.no_recent_customers',
    NO_RESULTS: 'customerCareModule.no_results',
    NO_RESULTS_FOR_THIS_SEARCH: 'customerCareModule.search_errors_customer.no_results_found_for_this_search',
    NO_SEASONS: 'customerCareModule.no_seasons',
    NO_SERVICES_FOUND: 'customerCareModule.no_services_found',
    NO_SUMMARY: 'customerCareModule.no_summary',
    NO_TAXES: 'customerCareModule.no_taxes',
    NO_USAGE_CONTROLS_CONFIGURED: 'customerCareModule.no_usage_controls_configured',
    NONE: 'customerCareModule.none',
    NOT_ALLOWED: 'not_allowed',
    NOT_APPLICABLE: 'not_applicable',
    NOT_APPLICABLE_LONG: 'customerCareModule.not_applicable',
    NOT_EDITABLE: 'customerCareModule.not_editable',
    NOTIFICATION_PREFERENCES_TYPE: {
        OPT_OUT_EMAIL: 'customerCareModule.notification_preferences_type.opt_out_email',
        OPT_OUT_SMS: 'customerCareModule.notification_preferences_type.opt_out_sms'
    },
    OF: 'customerCareModule.of',
    OFFCYCLE_CART: {
        ORDER_TOTALS: 'customerCareModule.offcycle_cart.order_totals',
        RENEWAL_SCHEDULE: 'customerCareModule.offcycle_cart.renewal_schedule'
    },
    OFFERS: 'customerCareModule.offers',
    OFFER_ADDITIONAL_PROPERTIES: 'customerCareModule.offer_additional_properties',
    OFFER_ADDITIONAL_PROPERTIES_UPDATED_SUCCESSFULLY: 'customerCareModule.offer_additional_properties_updated_successfully',
    OFFER_DECISIONS: 'customerCareModule.offer_decisions',
    OFFER_NAME: 'customerCareModule.offer_name',
    OFFER_STATUS: {
        ACTIVE: 'customerCareModule.offer_status.active',
        PENDING_ACTIVE: 'customerCareModule.offer_status.pending_active'
    },
    OFFER_X_OF_Y: 'customerCareModule.offer_x_of_y',
    OFFERING_CHARGE_TYPES: {
        TITLE: 'customerCareModule.offering_charge_types.title',
        PREPAID: 'customerCareModule.offering_charge_types.prepaid',
        POSTPAID: 'customerCareModule.offering_charge_types.postpaid'
    },
    OFFERINGS_ERROR: 'customerCareModule.offerings_error',
    OFFERINGS_INVALID: 'customerCareModule.offerings_invalid',
    OFFERINGS_PAGE: {
        HEADING: 'customerCareModule.offerings_page.heading',
        OFFERS: 'customerCareModule.offerings_page.offers',
        NO_RESULTS: 'customerCareModule.offerings_page.no_results',
        NO_RESULTS_MATCH_THE_SEARCH: 'customerCareModule.offerings_page.no_results_match_the_search',
        OFFERING_INSTANCE_ID: 'customerCareModule.offerings_page.offering_instance_id',
        TITLE: 'customerCareModule.offerings_page.title',
        VIEW_OFFER_DETAILS: 'customerCareModule.offerings_page.view_offer_details'
    },
    OKAY: 'customerCareModule.okay',
    ONE_TIME_ACTIVATION: 'customerCareModule.one_time_activation',
    OPTION_PRICE: 'customerCareModule.option_price',
    OPEN_CASE: 'customerCareModule.open_case',
    OPTIONAL_PRODUCTS: 'customerCareModule.optional_products',
    OPTIONAL_LABEL: 'customerCareModule.optional_label',
    ORDER: 'customerCareModule.order',
    ORDER_ADDITIONAL_PROPERTIES: 'customerCareModule.order_additional_properties',
    ORDER_CANCELED_SUCCESSFULLY: 'customerCareModule.order_canceled_successfully',
    ORDER_COMMENT: 'customerCareModule.order_comment',
    ORDER_CONFIRMATION: 'customerCareModule.order_confirmation',
    ORDER_DETAILS: {
        ACTIVITY: 'customerCareModule.order_details.activity',
        ADDITIONAL_DETAILS: 'customerCareModule.order_details.additional_details',
        ARE_YOU_SURE_YOU_WANT_TO_CANCEL_ORDER: 'customerCareModule.order_details.are_you_sure_you_want_to_cancel_order',
        ASSOCIATED_CONTRACT: 'customerCareModule.order_details.associated_contract',
        BILLING_STARTS: 'customerCareModule.order_details.billing_starts',
        BULK_FLAT: 'customerCareModule.order_details.bulk_flat',
        BULK_UNIT: 'customerCareModule.order_details.bulk_unit',
        CANCEL_ORDER: 'customerCareModule.order_details.cancel_order',
        COMPLETE_ORDER: 'customerCareModule.order_details.complete_order',
        COMMENTS: 'customerCareModule.order_details.comments',
        COMPLETE_ORDER_MESSAGE: 'customerCareModule.order_details.complete_order_message',
        COMPLETE_ORDER_SUCCESS: 'customerCareModule.order_details.complete_order_success',
        COMPLETION_DATE: 'customerCareModule.order_details.completion_date',
        CONFIRM_RESUBMISSION: 'customerCareModule.order_details.confirm_resubmission',
        CREATED_ON: 'customerCareModule.order_details.created_on',
        CREDIT: {
            ADD_NEW_PAYMENT_METHOD: 'customerCareModule.order_details.credit.add_new_payment_method',
            APPLY: 'customerCareModule.order_details.credit.apply',
            AMOUNT: 'customerCareModule.order_details.credit.amount',
            AMOUNT_INVALID: 'customerCareModule.order_details.credit.amount_invalid',
            AMOUNT_REQUIRED: 'customerCareModule.order_details.credit.amount_required',
            AMOUNT_VALUE_INVALID: 'customerCareModule.order_details.credit.amount_value_invalid',
            AMOUNT_VALUE_INVALID_DBSS: 'customerCareModule.order_details.credit.amount_value_invalid_dbss',
            BRANDABLE_MAX_AMOUNT_TO_CREDIT: 'customerCareModule.order_details.credit.brandable_max_amount_to_credit',
            CANCEL: 'customerCareModule.order_details.credit.cancel',
            DESCRIPTION: 'customerCareModule.order_details.credit.description',
            DESCRIPTION_PLACEHOLDER: 'customerCareModule.order_details.credit.description_placeholder',
            ERRORS: {
                FORM_API_SUBMISSION_FAILED: 'customerCareModule.order_details.credit.errors.form_api_submission_failed',
            },
            ENDING_IN: 'customerCareModule.order_details.credit.ending_in',
            ENTER_AMOUNT: 'customerCareModule.order_details.credit.enter_amount',
            HEADER: 'customerCareModule.order_details.credit.header',
            MAX_AMOUNT: 'customerCareModule.order_details.credit.max_amount',
            MAX_AMOUNT_TO_CREDIT: 'customerCareModule.order_details.credit.max_amount_to_credit',
            ORDER_ITEM_TITLE: 'customerCareModule.order_details.credit.order_item_title',
            ORDER_NUMBER_TITLE: 'customerCareModule.order_details.credit.order_number_title',
            ORIGINAL_PAYMENT_METHOD: 'customerCareModule.order_details.credit.original_payment_method',
            PAYMENT_METHOD_CREDITED: 'customerCareModule.order_details.credit.payment_method_credited',
            REASON: 'customerCareModule.order_details.credit.reason',
            REFUND_BY_CHECK: 'customerCareModule.order_details.credit.refund_by_check',
            SELECT: 'select',
            SEND_NOTIFICATION: 'customerCareModule.order_details.credit.send_notification',
            SUBMIT: 'customerCareModule.order_details.credit.submit'
        },
        CURRENT_RECURRING: 'customerCareModule.order_details.current_recurring',
        DEFAULT_DELIVERY_CAPABILITY_NAME: 'customerCareModule.order_details.default_deliver_capability_name',
        DEPOSITS: 'customerCareModule.order_details.deposits',
        DETAILS: 'customerCareModule.order_details.details',
        DEVICE_TYPE: 'customerCareModule.order_details.device_type',
        DISCONNECT_REASON: 'customerCareModule.order_details.disconnect_reason',
        DISCOUNT_DETAILS: 'customerCareModule.order_details.discount_details',
        DISCOUNTS: 'customerCareModule.order_details.discounts',
        DISTRIBUTION_CHANNEL: 'customerCareModule.order_details.distribution_channel',
        DOWN_PAYMENT: 'customerCareModule.order_details.down_payment',
        DOWNLOAD_ERROR_LOG: 'customerCareModule.order_details.download_error_log',
        EDIT_SERVICE_ATTRIBUTES: 'customerCareModule.order_details.edit_service_attributes',
        ERROR_LOG_NOT_FOUND: 'customerCareModule.order_details.error_log_not_found',
        EXPORT_PENDING_ATTRIBUTES: 'customerCareModule.order_details.export_pending_attributes',
        EXTERNAL_CONTRACT_ID: 'customerCareModule.order_details.external_contract_id',
        EXTERNAL_ORDER_NUMBER: 'customerCareModule.order_details.external_order_number',
        FILE: 'customerCareModule.order_details.file',
        FULFILLMENT_END_DATE: 'customerCareModule.order_details.fulfillment_end_date',
        FULFILLMENT_START_DATE: 'customerCareModule.order_details.fulfillment_start_date',
        FULL_PRICE_PLUS_TAX: 'customerCareModule.order_details.full_price_plus_tax',
        GIFT_NOTIFICATION_MESSAGE: 'customerCareModule.order_details.gift_notification_message',
        GROUP_ORDER_ID: 'customerCareModule.order_details.group_order_id',
        HIDE_DETAILS: 'customerCareModule.order_details.hide_details',
        HISTORY: 'customerCareModule.order_details.history',
        IMPORT_BULK_SERVICE_ATTRIBUTES: {
            FAILED: 'customerCareModule.order_details.import_bulk_service_attributes.failed',
            FILE_IMPORTED_SUCCESSFULLY: 'customerCareModule.order_details.import_bulk_service_attributes.file_imported_successfully',
            FILE_PROCESSING: 'customerCareModule.order_details.import_bulk_service_attributes.file_processing',
            FILE_TYPE_ERROR: 'customerCareModule.order_details.import_bulk_service_attributes.file_type_error',
            JOB_COMPLETE_MESSAGE: 'customerCareModule.order_details.import_bulk_service_attributes.job_complete_message',
            JOB_IS_ALREADY_PROCESSING_FILE: 'customerCareModule.order_details.import_bulk_service_attributes.job_is_already_processing_file',
            NO_FILES_AVAILABLE: 'customerCareModule.order_details.import_bulk_service_attributes.no_files_available',
            PROCESS_FILE: 'customerCareModule.order_details.import_bulk_service_attributes.process_file',
            SUCCESSFUL: 'customerCareModule.order_details.import_bulk_service_attributes.successful',
            TOTAL_PROCESSED_ITEMS: 'customerCareModule.order_details.import_bulk_service_attributes.total_processed_items',
            UPLOADED_FILES_WILL_OVERRIDE_ANY_PREVIOUS_IMPORTS: 'customerCareModule.order_details.import_bulk_service_attributes.uploaded_files_will_override_any_previous_imports'
        },
        IMPORT_PENDING_ATTRIBUTES: 'customerCareModule.order_details.import_pending_attributes',
        IMPORT_SERVICE_ATTRIBUTES: 'customerCareModule.order_details.import_service_attributes',
        INCLUDED_ITEMS: 'customerCareModule.order_details.included_items',
        LATE_FEE: 'customerCareModule.order_details.late_fee',
        MAX_LABEL: 'customerCareModule.order_details.max_label',
        MESSAGE: 'customerCareModule.order_details.message',
        MIN_LABEL: 'customerCareModule.order_details.min_label',
        MODIFIED: 'customerCareModule.order_details.modified',
        NO_ACTIVITY: 'customerCareModule.order_details.no_activity',
        NOTIFICATION_DATE: 'customerCareModule.order_details.notification_date',
        NOTIFICATION_HISTORY: 'customerCareModule.order_details.notification_history',
        ONE_TIME: 'customerCareModule.order_details.one_time',
        ORDER_DATE: 'customerCareModule.order_details.order_date',
        ORDER_DETAILS: 'customerCareModule.order_details.order_details',
        ORDER_ID: 'customerCareModule.order_details.order_id',
        ORDER_NUMBER_TITLE: 'customerCareModule.order_details.order_number_title',
        ORDER_PROPERTY: 'customerCareModule.order_details.order_property',
        ORDER_REASON: 'customerCareModule.order_details.order_reason',
        ORDERED_ITEMS: 'customerCareModule.order_details.ordered_items',
        PAYMENT_SUMMARY: 'customerCareModule.order_details.payment_summary',
        PO_NUMBER: 'customerCareModule.order_details.po_number',
        PRICING_PLAN_ID: 'customerCareModule.order_details.pricing_plan_id',
        PRODUCT_ID: 'customerCareModule.order_details.product_id',
        QUANTITY: 'customerCareModule.order_details.quantity',
        READ: 'customerCareModule.order_details.read',
        REASON_CODE: 'customerCareModule.order_details.reason_code',
        RECIPIENT_NAME: 'customerCareModule.order_details.recipient_name',
        RECURRING: 'customerCareModule.order_details.recurring',
        RECURRING_MONTHLY: 'customerCareModule.order_details.recurring_monthly',
        RESEND: 'customerCareModule.order_details.resend',
        RESEND_NOTIFICATION: 'customerCareModule.order_details.resend_notification',
        RESEND_SUCCESS: 'customerCareModule.order_details.resend_success',
        RESUBMIT_ORDER: 'customerCareModule.order_details.resubmit_order',
        RESUBMIT_ORDER_CONFIRMATION: 'customerCareModule.order_details.resubmit_order_confirmation',
        RESUBMIT_ORDER_SUCCESS: 'customerCareModule.order_details.resubmit_order_success',
        RETURN_AMOUNT: 'customerCareModule.order_details.return_amount',
        CHARGE_TIMING: 'customerCareModule.order_details.charge_timing',
        RETURN_TO_PRODUCT_DETAILS: 'customerCareModule.order_details.return_to_product_details',
        REVOKE: 'customerCareModule.order_details.revoke',
        REVOKE_GIFT: 'customerCareModule.order_details.revoke_gift',
        REVOKE_GIFT_SUCCESS: 'customerCareModule.order_details.revoke_gift_success',
        REASON_FOR_REVOKING: 'customerCareModule.order_details.reason_for_revoking',
        SCHEDULED_FOR: 'customerCareModule.order_details.scheduled_for',
        SERVICE_ATTRIBUTES: {
            TITLE: 'customerCareModule.order_details.service_attributes.title',
            UPDATE_SUCCESS: 'customerCareModule.order_details.service_attributes.update_success'
        },
        SHIPPING_SUMMARY: 'customerCareModule.order_details.shipping_summary',
        SHOW_DETAILS: 'customerCareModule.order_details.show_details',
        STATUS: 'customerCareModule.order_details.status',
        STORE: {
            INFORMATION: 'customerCareModule.order_details.store.information',
            NAME: 'customerCareModule.order_details.store.name',
            NUMBER: 'customerCareModule.order_details.store.number',
        },
        SUBMITTED_BY: 'customerCareModule.order_details.submitted_by',
        SUB_TOTAL: 'customerCareModule.order_details.sub_total',
        SUMMARY: 'customerCareModule.order_details.summary',
        TAXES: 'customerCareModule.order_details.taxes',
        TERMINATION_FEE: 'customerCareModule.order_details.termination_fee',
        TOTAL: 'customerCareModule.order_details.total',
        TRANSACTION_ID: 'customerCareModule.order_details.transaction_id',
        TRANSFER_ORDER_TYPE: 'customerCareModule.order_details.transfer_order_type',
        TRANSFERRED_FROM_SUBSCRIBER: 'customerCareModule.order_details.transferred_from_subscriber',
        TRANSFERRED_TO_SUBSCRIBER: 'customerCareModule.order_details.transferred_to_subscriber',
        TYPE: 'customerCareModule.order_details.type',
        TYPE_COLUMN: 'customerCareModule.order_details.type_column',
        UPLOAD_FILE: 'customerCareModule.order_details.upload_file',
        VIEW_ADDITIONAL_DETAILS: 'customerCareModule.order_details.view_additional_details',
        VIEW_SERVICE_ATTRIBUTES: 'customerCareModule.order_details.view_service_attributes',
        WORKFLOW: 'customerCareModule.order_details.workflow',
        WORKFLOW_NAME: 'customerCareModule.order_details.workflow_name',
        WORKFLOW_TYPE: 'customerCareModule.order_details.workflow_type',
        INSTANCE_PROPERTIES: 'customerCareModule.order_details.instance_properties',
        VIEW_INSTANCE_PROPERTIES: 'customerCareModule.order_details.view_instance_properties',
        SENDER_NAME: 'customerCareModule.order_details.sender_name',
        SENDER_EMAIL: 'customerCareModule.order_details.sender_email',
        NOTIFIED: 'customerCareModule.order_details.notified',
        REVOKED: 'customerCareModule.order_details.revoked',
        REVOKED_REASON_NAME: 'customerCareModule.order_details.revoked_reason_name',
        GIFT_ORDER: 'customerCareModule.order_details.gift_order',
        GIFT_ORDER_STATUS: 'customerCareModule.order_details.gift_order_status'
    },
    ORDER_FAILURE: 'customerCareModule.order_failure',
    ORDER_HISTORY: {
        ACTION_ITEMS: {
            EXPORT_SERVICES_TEMPLATE: 'customerCareModule.order_history.action_items.export_services',
            IMPORT_SERVICE_ATTRIBUTES: 'customerCareModule.order_history.action_items.import_services',
            VIEW_PRICING_SCHEDULE: 'customerCareModule.order_history.action_items.view_pricing_schedule',
            VIEW_SERVICES: 'customerCareModule.order_history.action_items.view_services'
        },
        COLUMNS: {
            ORDER: 'customerCareModule.order_history.columns.order',
            ORDER_DATE: 'customerCareModule.order_history.columns.orderDate',
            ORDER_DESCRIPTION: 'customerCareModule.order_history.columns.orderDescription',
            ORDER_STATUS_NAME: 'customerCareModule.order_history.columns.orderStatusName',
            ORDER_TYPE: 'customerCareModule.order_history.columns.orderType',
            TOTAL_AMOUNT: 'customerCareModule.order_history.columns.totalAmount'
        },
        DOWNLOAD_TEMPLATE: 'customerCareModule.order_history.download_template',
        FILTERS: {
            INCLUDE: 'customerCareModule.order_history.filters.include',
            INCLUDE_EMPTY_PLACEHOLDER: 'customerCareModule.order_history.filters.include_empty_placeholder',
            PAGER_DISPLAY: 'customerCareModule.pager_display',
            RETURN_AUTO_ORDERS_OPTION: 'customerCareModule.order_history.filters.return_auto_orders_option',
            RETURN_MIGRATED_ORDERS_OPTION: 'customerCareModule.order_history.filters.return_migrated_orders_option',
            STATUS: 'customerCareModule.order_history.filters.status',
            TYPE: 'customerCareModule.order_history.filters.type'
        },
        GROUP_ORDERS: 'customerCareModule.order_history.group_orders',
        NEW_ORDER: 'customerCareModule.order_history.new_order',
        ORDERS: 'customerCareModule.order_history.orders',
        REFRESH_STATUS: 'customerCareModule.order_history.refresh_status',
        TITLE: 'customerCareModule.order_history.title'
    },
    ORDER_ID: 'customerCareModule.order_id',
    ORDER_NUMBER: 'customerCareModule.order_number',
    ORDER_SCHEDULE_REQUIRED: 'customerCareModule.order_schedule_required',
    ORDER_SETTINGS_HAVE_BEEN_OVERRIDDEN: 'customerCareModule.order_settings_have_been_overridden',
    ORDER_SUBMITTED_SUCCESSFULLY: 'customerCareModule.order_submitted_successfully',
    ORDER_TOTAL: 'customerCareModule.order_total',
    ORPHAN_DEVICE_TOOLTIP: 'customerCareModule.orphan_device_tooltip',
    OVERVIEW: 'customerCareModule.overview',
    OVERRIDE_BUTTON_TITLE: 'customerCareModule.override_button_title',
    OWNER: 'customerCareModule.owner',
    PAGES: 'customerCareModule.pages',
    PAGER_DISPLAY: 'customerCareModule.pager_display',
    PASS_THROUGH_BILLING: 'customerCareModule.pass_through_billing',
    PAYMENT: 'customerCareModule.payment',
    PAYMENT_BILLING_ADDRESS: {
        ACTION_TEXT_CHANGE: 'customerCareModule.payment_billing_address.action_text_change',
        PAYMENT_ENDING_IN: 'customerCareModule.payment_billing_address.payment_ending_in',
        PAYMENT_EXPIRES: 'customerCareModule.payment_billing_address.payment_expires'
    },
    PAYMENT_FAILURE: 'customerCareModule.payment_failure',
    PAYMENT_INFORMATION: 'customerCareModule.payment_information',
    PAYMENT_INSTRUMENT: 'customerCareModule.payment_instrument',
    PAYMENT_METHOD_FAILED: 'customerCareModule.payment_method_failed',
    PAYMENT_REFUND: 'customerCareModule.payment_refund',
    PAYMENT_SUCCESS: 'customerCareModule.payment_success',
    PENDING_ACTIVE: 'customerCareModule.pending_active',
    PENDING_OFFERS_CAN_NOT_FULL_DISCONNECT: 'customerCareModule.pending_offers_can_not_full_disconnect',
    PENDING_REMOVED: 'customerCareModule.pending_removed',
    PERIOD: 'customerCareModule.period',
    PHONE_REQUIRED: 'customerCareModule.phone_required',
    PHONE: 'customerCareModule.phone',
    PICKLIST_PRODUCTS: 'customerCareModule.picklist_products',
    PIN_LOGIN_ERROR_COUNT: 'customerCareModule.pin_login_error_count',
    PIN_LOGIN_LAST_FAILURE: 'customerCareModule.pin_login_last_failure',
    PIN_LOGIN_LAST_SUCCESS: 'customerCareModule.pin_login_last_success',
    PIN_LOGIN_REVOKED: 'customerCareModule.pin_login_revoked',
    PIN: 'customerCareModule.pin',
    PLEASE_CHOOSE_RESPONSIBLE_PARTY: 'customerCareModule.please_choose_responsible_party',
    POLICIES: 'customerCareModule.policies',
    POLICY_COUNTERS: {
        ACCESS_POLICY: 'customerCareModule.policy_counters.access',
        ALLOCATION: 'customerCareModule.policy_counters.allocation',
        CLOSE: 'customerCareModule.policy_counters.close',
        CURRENT_POLICY: 'customerCareModule.policy_counters.current_policy',
        DESCRIPTION: 'customerCareModule.policy_counters.description',
        EXPIRES: 'customerCareModule.policy_counters.expires',
        ONE_TIME: 'customerCareModule.policy_counters.one_time',
        OWNER: 'customerCareModule.policy_counters.owner',
        POLICY_COUNTERS_TITLE: 'customerCareModule.policy_counters.policy_counters_title',
        POLICY_DETAILS: 'customerCareModule.policy_counters.policy_details',
        POLICY_STATUS: 'customerCareModule.policy_counters.policy_status',
        POLICY_TYPE: 'customerCareModule.policy_counters.policy_type',
        REMAIN: 'customerCareModule.policy_counters.remain',
        RESETS: 'customerCareModule.policy_counters.resets',
        SHARED: 'customerCareModule.policy_counters.shared',
        SPEED_POLICY: 'customerCareModule.policy_counters.speed_policy',
        TOTAL: 'customerCareModule.policy_counters.total',
        USED: 'customerCareModule.policy_counters.used',
        VIEW_DETAILS: 'customerCareModule.policy_counters.view_details'
    },
    PORT_IN: 'customerCareModule.port_in',
    POSTAL_CODE_INVALID: 'customerCareModule.postal_code_invalid',
    POSTAL_CODE_REQUIRED: 'postal_code_required',
    POSTAL_CODE: 'postal_code',
    PRE_AND_POSTPAID_OFFERS_CAN_NOT_FULL_DISCONNECT: 'customerCareModule.pre_and_postpaid_offers_can_not_full_disconnect',
    PREFERRED_CONTACT: 'customerCareModule.preferred_contact',
    PREFERRED_METHOD_OF_CONTACT: 'customerCareModule.preferred_method_of_contact',
    PREFERRED_NAME: 'customerCareModule.preferred_name',
    PREFIX: 'customerCareModule.prefix',
    PREVIOUS: 'customerCareModule.previous',
    PRICE_CHANGE_EFFECT_BILL_CYCLE: 'customerCareModule.price_change_effect_bill_cycle',
    PRICING_PLAN_ID: 'customerCareModule.pricing_plan_id',
    PRICING_PLAN: 'customerCareModule.pricing_plan',
    PRICING_PLANS: 'customerCareModule.pricing_plans',
    PRICING_SCHEDULE: 'customerCareModule.pricing_schedule',
    PRINT_VIEWS: {
        TRANSACTION_DETAILS: {
            ACCOUNT: 'customerCareModule.print_views.transaction_details.account',
            AUTH_CODE: 'customerCareModule.print_views.transaction_details.auth_code',
            BILLING_ADDRESS: 'customerCareModule.print_views.transaction_details.billing_address',
            BILL_TO: 'customerCareModule.print_views.transaction_details.bill_to',
            CREDITS: 'customerCareModule.print_views.transaction_details.credits',
            DATE: 'customerCareModule.print_views.transaction_details.date',
            DESCRIPTION: 'customerCareModule.print_views.transaction_details.description',
            DISCOUNT: 'customerCareModule.print_views.transaction_details.discount',
            EMAIL: 'customerCareModule.print_views.transaction_details.email',
            EXPIRATION_DATE: 'customerCareModule.print_views.transaction_details.expiration_date',
            IBAN: 'customerCareModule.print_views.transaction_details.IBAN',
            NAME: 'customerCareModule.print_views.transaction_details.name',
            ORDER_DETAILS: 'customerCareModule.print_views.transaction_details.order_details',
            ORDER_NUMBER: 'customerCareModule.print_views.transaction_details.order_number',
            PAYMENT_TYPE: 'customerCareModule.print_views.transaction_details.payment_type',
            PHONE: 'customerCareModule.print_views.transaction_details.phone',
            REMAINING_BALANCE: 'customerCareModule.print_views.transaction_details.remaining_balance',
            SUBTOTAL: 'customerCareModule.print_views.transaction_details.subtotal',
            TAXES: 'customerCareModule.print_views.transaction_details.taxes',
            TOTAL: 'customerCareModule.print_views.transaction_details.total',
            TRANSACTED_AMOUNT: 'customerCareModule.print_views.transaction_details.transacted_amount',
            TRANSACTION: 'customerCareModule.print_views.transaction_details.transaction',
            TYPE: 'customerCareModule.print_views.transaction_details.type',
            USERNAME: 'customerCareModule.username'
        }
    },
    PRIORITY: 'customerCareModule.priority',
    PRODUCT_OPTIONS: {
        DETAILS: 'customerCareModule.product_options.details',
        OPTIONS: 'customerCareModule.product_options.options'
    },
    PRODUCT: 'customerCareModule.product',
    PRODUCT_ID: 'customerCareModule.product_id',
    PRODUCT_RATES: 'customerCareModule.product_rates',
    PRODUCT_SERVICE_RELATIONSHIP: {
        CONSUME_SERVICE_FROM: 'customerCareModule.product_service_relationship.consume_service_from',
        FRIENDLY_NAME: 'customerCareModule.product_service_relationship.friendly_name',
        MANAGE_PLAN_SERVICES: 'customerCareModule.product_service_relationship.manage_plan_services',
        NETWORK_IDENTIFIER: 'customerCareModule.product_service_relationship.network_identifier',
        NO_CONSUMING_PLAN_TO_ASSOCIATE: 'customerCareModule.product_service_relationship.no_consuming_plan_to_associate',
        PRICING_PLAN_NAME: 'customerCareModule.product_service_relationship.pricing_plan_name',
        SERVICE: 'customerCareModule.product_service_relationship.service',
        SUCCESS: 'customerCareModule.product_service_relationship.success'
    },
    PRODUCTS: 'customerCareModule.products',
    PRODUCTS_ERROR: 'customerCareModule.products_error',
    PRODUCTS_PAGE: {
        ACCESS_COUNT: 'customerCareModule.products_page.access_count',
        ACCESS_REQUESTS_REMAINING: 'customerCareModule.products_page.access_requests_remaining',
        ADD_DATE: 'customerCareModule.products_page.add_date',
        AVAILABLE: 'customerCareModule.products_page.available',
        CANNOT_REMOVE: 'customerCareModule.cannot_remove',
        CLASS: 'customerCareModule.products_page.class',
        DIFFERENT_CLASSES: 'customerCareModule.products_page.different_classes',
        ENTER_PRODUCT_NAME: 'customerCareModule.products_page.enter_product_name',
        ENTITLEMENT_ID: 'customerCareModule.products_page.entitlement_id',
        EXPIRATION_DATE: 'customerCareModule.products_page.expiration_date',
        RENEWAL_DATE: 'customerCareModule.products_page.renewal_date',
        FULFILLMENT_DATE: 'customerCareModule.products_page.fulfillment_date',
        LICENSE_COUNT: 'customerCareModule.products_page.license_count',
        LICENSE_REQUESTS_REMAINING: 'customerCareModule.products_page.license_requests_remaining',
        NEW_PRODUCT_ORDER: 'customerCareModule.products_page.new_product_order',
        NO_PRODUCTS_EMPTY_STATE: 'customerCareModule.products_page.no_products_empty_state',
        NOT_AVAILABLE: 'customerCareModule.products_page.not_available',
        OFFER: 'customerCareModule.products_page.offer',
        CREATED_DATE: 'customerCareModule.products_page.created_date',
        ORDER_NUMBER: 'customerCareModule.products_page.order_number',
        PRICING_PLAN_OVERRIDE: 'customerCareModule.products_page.pricing_plan_override',
        RENEWAL_COUNT: 'customerCareModule.products_page.renewal_count',
        SEARCH: {
            CATEGORY: 'customerCareModule.products_page.search.category',
            CLASS: 'customerCareModule.products_page.search.class',
            CLEAR: 'customerCareModule.products_page.search.clear',
            COUPON_INPUT_PLACEHOLDER: 'customerCareModule.products_page.search.coupon_input_placeholder',
            COUPON_SUBMIT_BUTTON_TEXT: 'customerCareModule.products_page.search.coupon_submit_button_text',
            COUPON_TITLE: 'customerCareModule.products_page.search.coupon_title',
            PRODUCT: 'customerCareModule.products_page.search.product',
            RECURRENCE: 'customerCareModule.products_page.search.recurrence',
            REFINE_BY: 'customerCareModule.products_page.search.refine_by',
            STRUCTURE_TYPE: 'customerCareModule.products_page.search.structure_type',
            SUBSCRIPTION: 'customerCareModule.products_page.search.subscription'
        },
        START_NEW_ORDER: 'customerCareModule.products_page.start_new_order',
        TIME_REMAINING: 'customerCareModule.products_page.time_remaining',
        TIME_REMAINING_DISPLAY: 'customerCareModule.products_page.time_remaining_display',
        ZERO: 'customerCareModule.products_page.zero'
    },
    PRODUCTS_INVALID: 'customerCareModule.products_invalid',
    REACTIVATE_SERVICE: 'customerCareModule.reactivate_service',
    RECONNECT_OFFER: 'customerCareModule.reconnect_offer',
    REPLACE_PRODUCT_TITLE: 'customerCareModule.replace_product_title',
    REPLACE_PRODUCT_MESSAGE: 'customerCareModule.replace_product_message',
    REPLACE_PRODUCT_PRIMARY: 'customerCareModule.replace_product_primary',
    PRODUCT_CLASS: 'customerCareModule.product_class',
    PRODUCTS_SEARCH: {
        PAGER_DISPLAY: 'customerCareModule.products_search.pager_display',
        SORT_LABEL: 'customerCareModule.products_search.sort_label',
        SORT_OPTIONS: {
            DATE: 'customerCareModule.products_search.sort_options.date',
            NAME: 'customerCareModule.products_search.sort_options.name',
            RELEVANCE: 'customerCareModule.products_search.sort_options.relevance',
            VIEW_COUNT: 'customerCareModule.products_search.sort_options.view_count'
        },
        STRUCTURE_TYPES: {
            A_LA_CARTE: 'customerCareModule.products_search.structure_types.a_la_carte',
            BUNDLE: 'customerCareModule.products_search.structure_types.bundle',
            EPISODIC_BUNDLE: 'customerCareModule.products_search.structure_types.episodic_bundle',
            PICK_LIST: 'customerCareModule.products_search.structure_types.pick_list',
            SERIES_CONTAINER: 'customerCareModule.products_search.structure_types.series_container'
        }
    },
    PROFILE: 'customerCareModule.profile',
    PROFILE_PAGE: {
        ACCOUNTING_METHOD: 'customerCareModule.profile_page.accounting_method',
        ACCOUNTING_METHODS: {
            BALANCE_FORWARD: 'customerCareModule.profile_page.accounting_methods.balance_forward',
            OPEN_INVOICE: 'customerCareModule.profile_page.accounting_methods.open_invoice',
        },
        ACTIVE: 'customerCareModule.profile_page.active',
        ANONYMIZE_CONFIRMATION_TITLE: 'customerCareModule.profile_page.anonymize_confirmation_title',
        ANONYMIZE_CONFIRMATION_YES: 'customerCareModule.profile_page.anonymize_confirmation_yes',
        ANONYMIZE_FAILURE_MESSAGE: 'customerCareModule.profile_page.anonymize_failure_message',
        ANONYMIZE_IMMEDIATELY_CONFIRMATION_MESSAGE: 'customerCareModule.profile_page.anonymize_immediately_confirmation_message',
        ANONYMIZE_IMMEDIATELY: 'customerCareModule.profile_page.anonymize_immediately',
        ANONYMIZE_SUCCESS_MESSAGE: 'customerCareModule.profile_page.anonymize_success_message',
        BILL_CYCLE_CHANGE_IN_PROGRESS_WARNING_MESSAGE: 'customerCareModule.profile_page.bill_cycle_change_in_progress_warning_message',
        BILL_CYCLE_CHANGE_TOOLTIP_MESSAGE: 'customerCareModule.profile_page.bill_cycle_change_tooltip_message',
        BILL_CYCLE_LABEL: 'customerCareModule.profile_page.bill_cycle_label',
        BILL_CYCLE_POP_UP_MESSAGE: 'customerCareModule.profile_page.bill_cycle_pop_up_message',
        BILL_CYCLE_POP_UP_MESSAGE_THREE_STEP: 'customerCareModule.profile_page.bill_cycle_pop_up_message_three_step',
        BILLING_FREQUENCY_LABEL: 'customerCareModule.profile_page.billing_frequency_label',
        BILLING_ADJUSTMENT_DUE_DAYS: 'customerCareModule.profile_page.billing_adjustment_due_days',
        BILLING_ADJUSTMENT_DUE_DAYS_TOOLTIP: 'customerCareModule.profile_page.billing_adjustment_due_days_tooltip',
        BILLING_ADJUSTMENT_DUE_DAYS_STEPPER_LABEL: 'customerCareModule.profile_page.billing_adjustment_due_days_stepper_label',
        BILLING_PAYMENT_DUE_DAYS_STEPPER_LABEL: 'customerCareModule.profile_page.billing_payment_due_days_stepper_label',
        BILLING_PAYMENT_DUE_DAYS: 'customerCareModule.profile_page.billing_payment_due_days',
        CUSTOMER_BILL_CYCLE_UPDATED: 'customerCareModule.profile_page.customer_bill_cycle_updated',
        CUSTOMER_BILLING_DETAILS_UPDATED: 'customerCareModule.profile_page.customer_billing_details_updated',
        CUSTOMER_PIN_CREATED: 'customerCareModule.profile_page.customer_pin_created',
        CUSTOMER_PIN_INVALID: 'customerCareModule.profile_page.customer_pin_invalid',
        CUSTOMER_PIN_UPDATED: 'customerCareModule.profile_page.customer_pin_updated',
        DATE_UNAVAILABLE: 'customerCareModule.profile_page.date_unavailable',
        DELETE_AUTH_CONFIRMATION_NO: 'customerCareModule.profile_page.delete_auth_confirmation_no',
        DELETE_AUTH_CONFIRMATION_MESSAGE: 'customerCareModule.profile_page.delete_auth_confirmation_message',
        DELETE_AUTH_CONFIRMATION_TITLE: 'customerCareModule.profile_page.delete_auth_confirmation_title',
        DELETE_AUTH_CONFIRMATION_YES: 'customerCareModule.profile_page.delete_auth_confirmation_yes',
        DELETE_AUTH_FAILURE_MESSAGE: 'customerCareModule.cannot_remove',
        DELETE_AUTH_SUCCESS_MESSAGE: 'customerCareModule.profile_page.delete_auth_success_message',
        DISABLED: 'customerCareModule.profile_page.disabled',
        EDIT_BILLING: 'customerCareModule.profile_page.edit_billing',
        EDIT: 'customerCareModule.profile_page.edit',
        EMAIL_VERIFICATION_STATUS: 'customerCareModule.profile_page.email_verification_status',
        EMAIL_VERIFICATION_EXPIRATION: 'customerCareModule.profile_page.email_verification_expiration',
        ENABLED: 'customerCareModule.profile_page.enabled',
        EXTERNAL_SUBSCRIBER_ID: 'customerCareModule.profile_page.external_subscriber_id',
        EXPIRATION_DATE: 'customerCareModule.profile_page.expiration_date',
        HAS_ACTIVE_SUBSCRIPTIONS: 'customerCareModule.profile_page.has_active_subscriptions',
        HIERARCHY_MOVE_IN_PROGRESS_WARNING_MESSAGE: 'customerCareModule.profile_page.hierarchy_move_in_progress_warning_message',
        CREATED_DATE: 'customerCareModule.profile_page.created_date',
        EFFECTIVE_START_DATE: 'customerCareModule.profile_page.effective_start_date',
        NEXT_BILL_DATE_LABEL: 'customerCareModule.profile_page.next_bill_date_label',
        NEXT_BILL_DATE: 'customerCareModule.profile_page.next_bill_date',
        PENDING_APPROVAL: 'customerCareModule.profile_page.pending_approval',
        PII_ACCESS_DISABLED_MESSAGE: 'customerCareModule.profile_page.pii_access_disabled_message',
        PIN_VALIDATION_ERROR: 'customerCareModule.profile_page.pin_validation_error',
        PIN_VALIDATION_SUCCESS: 'customerCareModule.profile_page.pin_validation_success',
        REACTIVATE_FAILURE_MESSAGE: 'customerCareModule.profile_page.reactivate_failure_message',
        REACTIVATE_SUCCESS_MESSAGE: 'customerCareModule.profile_page.reactivate_success_message',
        RESEND_EMAIL: 'customerCareModule.profile_page.resend_email',
        REQUESTED_BILL_CYCLE: 'customerCareModule.profile_page.requested_bill_cycle',
        SCHEDULE_FOR_ANONYMIZATION: 'customerCareModule.profile_page.schedule_for_anonymization',
        SCHEDULE_ANONYMIZATION_CONFIRMATION_MESSAGE: 'customerCareModule.profile_page.schedule_anonymization_confirmation_message',
        SUSPENDED: 'customerCareModule.profile_page.suspended',
        TOKEN_ACTIVE: 'customerCareModule.profile_page.token_active',
        UPDATE_SUCCESS_MESSAGE: 'customerCareModule.profile_page.update_success_message',
        VERIFICATION_EMAIL_SENT_SUCCESS: 'customerCareModule.profile_page.verification_email_sent_success'
    },
    QUANTITY: 'customerCareModule.quantity',
    QUANTITY_DECISION_ERROR: 'customerCareModule.quantity_decision_error',
    QUICK_ACTIONS: 'customerCareModule.quick_actions',
    QUICK_ACTIONS_OPTIONS: {
        DISCONNECT_CUSTOMER: 'customerCareModule.quick_actions_options.disconnect_customer',
        OPEN_CASE: 'customerCareModule.quick_actions_options.open_case',
        REDEEM_GIFT_ORDER: 'customerCareModule.quick_actions_options.redeem_gift_order',
        RESET_DEVICE_PIN: 'customerCareModule.quick_actions_options.reset_device_pin',
        RESET_PASSWORD: 'customerCareModule.quick_actions_options.reset_password',
        RESUME_ORDER: 'customerCareModule.quick_actions_options.resume_order',
        START_PRODUCT: 'customerCareModule.quick_actions_options.start_product',
        START_ORDER: 'customerCareModule.quick_actions_options.start_order',
        START_SUBSCRIPTION: 'customerCareModule.quick_actions_options.start_subscription',
        UNREVOKE_LOGIN: 'customerCareModule.quick_actions_options.unrevoke_login'
    },
    QUICK_INFO: 'customerCareModule.quick_info',
    QUICK_VIEW: 'customerCareModule.quick_view',
    QUOTE_ERROR: 'customerCareModule.quote_error',
    RACE: 'customerCareModule.race',
    RATE_SHEET: 'customerCareModule.rate_sheet',
    RATE_SHEET_DETAILS: {
        BILLING_RULE_SELECTION: 'customerCareModule.rate_sheet_details.billing_rule_selection',
        CONFIRMATION_TITLE: 'customerCareModule.rate_sheet_details.confirmation_title',
        CONFIRMATION_TEXT: 'customerCareModule.rate_sheet_details.confirmation_ttext',
    },
    RATE_SHEET_FEE: {
        CREATE_FEE_SUCCESS: 'customerCareModule.rate_sheet_fee.create_fee_success',
        UPDATE_FEE_SUCCESS: 'customerCareModule.rate_sheet_fee.update_fee_success'
    },
    RATE_SHEET_SUCCESS_NOTIFICATION: 'customerCareModule.rate_sheet_success_notification',
    RATE_SHEET_ITEM_TYPES: {
        ENTITLEMENT: 'customerCareModule.rate_sheet_item_types.entitlement',
        FLAT_RATE: 'customerCareModule.rate_sheet_item_types.flat_rate',
        ONE_TIME: 'customerCareModule.rate_sheet_item_types.one_time',
        RECURRING: 'customerCareModule.rate_sheet_item_types.recurring',
        ROUNDING_RULES: 'customerCareModule.rate_sheet_item_types.rounding_rules',
        USAGE_RULE_NON_TIERED: 'customerCareModule.rate_sheet_item_types.usage_rule_non_tiered',
        USAGE_RULE_PER_EVENT: 'customerCareModule.rate_sheet_item_types.usage_rule_per_event',
        USAGE_RULE_TIERED: 'customerCareModule.rate_sheet_item_types.usage_rule_tiered',
        USAGE_RULE: 'customerCareModule.rate_sheet_item_types.usage_rule'
    },
    RATE_SHEET_ROUNDING_RULES_TAB: {
        TITLE: 'customerCareModule.rate_sheet_rounding_rules_tab.title'
    },
    RATE_SHEET_TABS: {
        OFFERS: 'customerCareModule.rate_sheet_tabs.offers',
        STANDALONE_PRODUCTS: 'customerCareModule.rate_sheet_tabs.standalone_products',
        ADD_ONS: 'customerCareModule.rate_sheet_tabs.add_ons'
    },
    RATE_SHEET_TIMELINE: {
        OFFER: 'customerCareModule.rate_sheet_timeline.offer',
        PRICING_PLAN: 'customerCareModule.rate_sheet_timeline.pricing_plan',
        PRODUCT: 'customerCareModule.rate_sheet_timeline.product'
    },
    RATE_SHEET_TIME_BANDS: {
        ANYTIME: 'customerCareModule.rate_sheet_time_bands.anytime',
        HOLIDAY: 'customerCareModule.rate_sheet_time_bands.holiday',
        OFF_PEAK: 'customerCareModule.rate_sheet_time_bands.off_peak',
        PEAK: 'customerCareModule.rate_sheet_time_bands.peak',
        WEEKEND: 'customerCareModule.rate_sheet_time_bands.weekend',
    },
    USAGE_RATES_TABS: {
        PRODUCT_RATES: 'customerCareModule.usage_rates_tabs.product_rates',
        FLAT_PER_EVENT_USAGE_RULES: 'customerCareModule.usage_rates_tabs.flat_per_event_usage_rules',
        TIERED_USAGE_RULES: 'customerCareModule.usage_rates_tabs.tiered_usage_rules'
    },
    REACTIVATE: 'customerCareModule.reactivate',
    REASON_CODE: 'customerCareModule.reason_code',
    RECENT_CUSTOMERS: 'customerCareModule.recent_customers',
    REDEEM_GIFT_ORDER: {
        REDEEM_GIFT_ORDER_TITLE: 'customerCareModule.redeem_gift_order.redeem_gift_order_title',
        NO_REDEMPTION_CODE: 'customerCareModule.redeem_gift_order.no_redemption_code',
        APPLY: 'customerCareModule.redeem_gift_order.apply',
        INVALID_REDEMPTION_CODE_SECONDARY_ERROR: 'customerCareModule.redeem_gift_order.invalid_redemption_code_secondary_error',
        REDEMPTION_CODE_PLACEHOLDER: 'customerCareModule.redeem_gift_order.redemption_code_placeholder',
        REDEEM_GIFT_ORDER_SUBMIT: 'customerCareModule.redeem_gift_order.redeem_gift_order_submit',
        PAYMENT_AND_SHIPPING_TITLE: 'customerCareModule.redeem_gift_order.payment_and_shipping_title',
        PAYMENT_REQUIRED_ERROR: 'customerCareModule.redeem_gift_order.payment_required_error',
        PREVIEW_GIFT_ORDER_QUOTE_ERROR: 'customerCareModule.redeem_gift_order.preview_gift_order_quote_error',
        PREVIEW_NO_CHARGE_MESSAGE: 'customerCareModule.redeem_gift_order.preview_no_charge_message',
        SHIPPING_ADDRESS: 'customerCareModule.redeem_gift_order.shipping_address'
    },
    REDEMPTION_CODE_STATUS: 'customerCareModule.redemption_code_status',
    REDIRECT_BILLING: 'customerCareModule.redirect_billing',
    REDIRECT_CHARGES: {
        CONFIRM_REDIRECT: 'customerCareModule.redirect_charges.confirm_redirect',
        NO_CHARGES_AVAILABLE: 'customerCareModule.redirect_charges.no_charges_available',
        NO_PLANS_TO_REDIRECT: 'customerCareModule.redirect_charges.no_plans_to_redirect',
        PLANS: 'customerCareModule.redirect_charges.plans',
        PRODUCTS: 'customerCareModule.redirect_charges.products',
        REDIRECT_CHARGES: 'customerCareModule.redirect_charges.redirect_charges',
        REDIRECT_CHARGES_FROM: 'customerCareModule.redirect_charges.redirected_charges_from',
        REDIRECT_CHARGES_TO: 'customerCareModule.redirect_charges.redirected_charges_to',
        REDIRECT_DISABLED: 'customerCareModule.redirect_charges.redirect_disabled',
        REDIRECTED_CHARGES_SUCCESS: 'customerCareModule.redirect_charges.redirected_charges_success',
        SERVICE_CURRENTLY_REDIRECTED: 'customerCareModule.redirect_charges.service_currently_redirected',
        SERVICES: 'customerCareModule.redirect_charges.services'
    },
    REFINE_BY_KEYWORD: 'customerCareModule.refine_by_keyword',
    REFINE_BY_NAME: 'customerCareModule.refine_by_name',
    REFRESH: 'customerCareModule.refresh',
    REFRESH_INVOICES: 'customerCareModule.refresh_invoices',
    REFUND: 'customerCareModule.refund',
    RELEASED: 'customerCareModule.released',
    REMAIN: 'customerCareModule.remain',
    REMAINING: 'customerCareModule.remaining',
    REMAINING_BALANCE: 'customerCareModule.remaining_balance',
    REMARK: {
        CREATED: 'customerCareModule.remark.created',
        EDIT: 'customerCareModule.remark.edit',
        MODIFIED: 'customerCareModule.remark.modified',
        SERVICE_UNAVAILABLE: 'customerCareModule.remark.service_unavailable'
    },
    REMARK_SORTING: {
        HIGH_PRIORITY: 'customerCareModule.remark_sorting.high_priority',
        LOW_PRIORITY: 'customerCareModule.remark_sorting.low_priority',
        NEWEST: 'customerCareModule.remark_sorting.newest',
        OLDEST: 'customerCareModule.remark_sorting.oldest',
        SORT_BY: 'customerCareModule.remark_sorting.sort_by'
    },
    REMOVE: 'customerCareModule.remove',
    REMOVED: 'customerCareModule.removed',
    REMOVE_OFFER: {
        AMOUNT_INVALID: 'customerCareModule.remove_offer.amount_invalid',
        AMOUNT_REQUIRED: 'customerCareModule.remove_offer.amount_required',
        AMOUNT_VALUE_INVALID: 'customerCareModule.remove_offer.amount_value_invalid',
        CONFIRMATION_MESSAGE: 'customerCareModule.remove_offer.confirmation_message',
        CREDIT: 'customerCareModule.remove_offer.credit',
        CREDIT_REASON: 'customerCareModule.remove_offer.credit_reason',
        CUSTOM: 'customerCareModule.remove_offer.custom',
        MONTHLY_SUBSCRIPTION: 'customerCareModule.remove_offer.monthly_subscription',
        NOTIFICATION_OPTIONS: 'customerCareModule.remove_offer.notification_options',
        REMOVE: 'customerCareModule.remove_offer.remove',
        REMOVE_OFFER: 'customerCareModule.remove_offer.remove_offer',
        REMOVE_SUCCESSFUL: 'customerCareModule.remove_offer.remove_successful',
        SCHEDULE_CREDIT: 'customerCareModule.remove_offer.schedule_credit',
        SEND_NOTIFICATION: 'customerCareModule.remove_offer.send_notification',
        TOTAL_UNUSED: 'customerCareModule.remove_offer.total_unused',
        TOTAL_UNUSED_CREDIT: 'customerCareModule.remove_offer.total_unused_credit',
        UNDER_CONTRACT: 'customerCareModule.remove_offer.under_contract',
        UNUSED_CREDIT: 'customerCareModule.remove_offer.unused_credit'
    },
    REMOVE_PRODUCTS: {
        CONFIRMATION_MESSAGE: 'customerCareModule.remove_products.confirmation_message',
        ERROR: 'customerCareModule.remove_products.error',
        LIBRARY_UPDATE: 'customerCareModule.remove_products.library_update',
        REASON: 'customerCareModule.remove_products.reason',
        REMOVE_PRODUCT: 'customerCareModule.remove_products.remove_product',
        SEND_NOTIFICATION: 'customerCareModule.remove_products.send_notification',
        SUCCESS: 'customerCareModule.remove_products.success',
        TITLE: 'customerCareModule.remove_products.title'
    },
    RENEWAL_DATE: 'customerCareModule.renewal_date',
    REPLACE_PRODUCT_ORDER: 'customerCareModule.replace_product_order',
    RESTORE: 'customerCareModule.restore',
    RESTORE_PRODUCT_ORDER: 'customerCareModule.restore_product_order',
    REQUESTS: 'customerCareModule.requests',
    REQUIRED_FIELDS: 'customerCareModule.adjustments.required_fields_indicator',
    REQUIRED_FIELD_ERROR: 'field_is_a_required_field',
    REQUIRED_FIELD_INDICATION: 'required_field_indication',
    REQUIRED_PERMISSIONS: 'customerCareModule.required_permissions',
    RESERVED: 'customerCareModule.reserved',
    RESET_DEVICE_PIN_DIALOG: {
        BUTTON_NO: 'customerCareModule.reset_device_pin_dialog.button_no',
        BUTTON_YES: 'customerCareModule.reset_device_pin_dialog.button_yes',
        CONTENT: 'customerCareModule.reset_device_pin_dialog.content',
        TITLE: 'customerCareModule.reset_device_pin_dialog.title'
    },
    RESET_DEVICE_PIN_FAILURE: 'customerCareModule.reset_device_pin_failure',
    RESET_DEVICE_PIN_SUCCESS: 'customerCareModule.reset_device_pin_success',
    RESET_EXPIRES: 'customerCareModule.reset_expires',
    RESET_PASSWORD_DIALOG: {
        BUTTON_NO: 'customerCareModule.reset_password_dialog.button_no',
        BUTTON_YES: 'customerCareModule.reset_password_dialog.button_yes',
        CONTENT: 'customerCareModule.reset_password_dialog.content',
        TITLE: 'customerCareModule.reset_password_dialog.title'
    },
    RESET_REVOKED_LOGIN_DIALOG: {
        BUTTON_NO: 'customerCareModule.cancel',
        BUTTON_YES: 'customerCareModule.reset_revoked_login_dialog.button_yes',
        CONFIRM_RESET_PASSWORD: 'customerCareModule.reset_revoked_login_dialog.confirm_reset_password',
        CONTENT: 'customerCareModule.reset_revoked_login_dialog.content',
        TITLE: 'customerCareModule.reset_revoked_login_dialog.title'
    },
    RESET_REVOKED_PASSWORD_DIALOG: {
        BUTTON_NO: 'customerCareModule.cancel',
        BUTTON_YES: 'customerCareModule.reset_revoked_password_dialog.button_yes',
        CONTENT: 'customerCareModule.reset_revoked_password_dialog.content',
        TITLE: 'customerCareModule.reset_revoked_password_dialog.title'
    },
    RESET_PASSWORD_FAILURE: 'customerCareModule.reset_password_failure',
    RESET_PASSWORD_SUCCESS: 'customerCareModule.reset_password_success',
    RESET_REVOKE_FAILURE: 'customerCareModule.reset_revoke_success',
    RESET_REVOKE_SUCCESS: 'customerCareModule.reset_revoke_failure',
    RESIDENTIAL: 'customerCareModule.residential',
    RESIDENTIAL_CUSTOMER: 'customerCareModule.residential_customer',
    RESOLUTION: 'customerCareModule.resolution',
    RESPONSIBLE_FOR: 'customerCareModule.responsible_for',
    RESPONSIBLE_PARTY: {
        RESPONSIBLE_PARTY: 'customerCareModule.responsible_party.responsible_party',
        UPDATED_SUCCESSFULLY: 'customerCareModule.responsible_party.updated_successfully'
    },
    RESTORE_DEFAULTS: 'customerCareModule.restore_defaults',
    RESUME: 'customerCareModule.resume',
    RESUME_OFFER: 'customerCareModule.resume_offer',
    RESUME_OFFER_FORM_HINT: 'customerCareModule.resume_offer_form_hint',
    RESUME_OFFER_SUBMITTED: 'customerCareModule.resume_offer_submitted',
    RESUME_ORDER: 'customerCareModule.resume_order_failure',
    RESUME_ORDER_FAILURE: 'customerCareModule.resume_order_failure',
    RESUME_SAVED_ORDER_FAILURE: {
        CONTRACT_INVALID: 'customerCareModule.resume_saved_order_failure.contract_invalid',
        TITLE: 'customerCareModule.resume_saved_order_failure.title',
        INVALID_INVENTORY: 'customerCareModule.resume_saved_order_failure.invalid_inventory',
        OKAY: 'customerCareModule.resume_saved_order_failure.okay',
        INVALID_INVENTORY_MESSAGE: 'customerCareModule.resume_saved_order_failure.invalid_inventory_message',
        NOT_AVAILABLE: 'customerCareModule.resume_saved_order_failure.not_available',
        NOT_VALID: 'customerCareModule.resume_saved_order_failure.not_valid',
        NOT_FOUND: 'customerCareModule.resume_saved_order_failure.not_found'
    },
    RESUME_SAVED_ORDER_INSUFFICIENT_ACCESS: {
        TITLE: 'customerCareModule.resume_saved_order_insufficient_access.title',
        WARNING_MESSAGE: 'customerCareModule.resume_saved_order_insufficient_access.warning_message',
        TAKE_ME_BACK: 'customerCareModule.resume_saved_order_insufficient_access.take_me_back'
    },
    RESUME_CONFIRMATION: 'customerCareModule.resume_confirmation',
    RESUME_SERVICE: 'customerCareModule.resume_service',
    RESUME_SERVICES: 'customerCareModule.resume_services',
    RESUME_SERVICES_FAILURE: 'customerCareModule.resume_services_failure',
    RESUME_SERVICES_SUCCESS: 'customerCareModule.resume_services_success',
    RETURN_PREVIOUS_PAGE: 'customerCareModule.return_previous_page',
    RETURNED: 'customerCareModule.returned',
    ROLE: 'role',
    ROOT_HIERARCHY_NAME: 'customerCareModule.root_hierarchy_name',
    ROOT_HIERARCHY_NAME_REQUIRED: 'customerCareModule.root_hierarchy_name_required',
    RUNTIME: 'customerCareModule.runtime',
    SAVE: 'customerCareModule.save',
    SAVE_AND_EXIT: 'customerCareModule.save_and_exit',
    SAVE_BULK_PRICING: 'customerCareModule.save_bulk_pricing',
    SAVE_CART: {
        CART_IS_EMPTY: 'customerCareModule.save_cart.cart_is_empty',
        CART_IS_HAVING_ONLY_STANDALONE_PRODUCTS: 'customerCareModule.save_cart.cart_is_having_only_standalone_products'
    },
    SAVE_CUSTOMER: 'customerCareModule.save_customer',
    SAVE_ORDER: 'customerCareModule.save_order',
    SAVE_ORDER_FAILURE: {
        TITLE: 'customerCareModule.save_order_failure.title'
    },
    SAVE_ORDER_SUCCESS: 'customerCareModule.save_order_success',
    SAVE_ORDER_FORM: {
        SUCCESS: 'customerCareModule.save_order_form.success'
    },
    SAVE_ORDER_PROMPT: {
        CONFIRM: 'customerCareModule.save_order_prompt.confirm',
        DENY: 'customerCareModule.save_order_prompt.deny',
        MESSAGE: 'customerCareModule.save_order_prompt.message',
        TITLE: 'customerCareModule.save_order_prompt.title'
    },
    SCHEDULING_OPTIONS: {
        BILLING_EFFECTIVE_DATE: {
            BILLING_EFFECTIVE_DATE_1: 'customerCareModule.scheduling_options.billing_effective_date.billing_effective_date_1',
            BILLING_EFFECTIVE_DATE_2: 'customerCareModule.scheduling_options.billing_effective_date.billing_effective_date_2',
            BILLING_EFFECTIVE_DATE_3: 'customerCareModule.scheduling_options.billing_effective_date.billing_effective_date_3',
            BILLING_EFFECTIVE_DATE_4: 'customerCareModule.scheduling_options.billing_effective_date.billing_effective_date_4',
            BILLING_EFFECTIVE_DATE_EMPTY: 'customerCareModule.scheduling_options.billing_effective_date.billing_effective_date_empty'
        },
        CHANGE_FDO_DATE: 'customerCareModule.scheduling_options.change_fdo_date',
        CHANGE_FUTURE_DATED_ORDER_AND_BILLING_EFFECTIVE_DATE: 'customerCareModule.scheduling_options.change_future_dated_order_and_billing_effective_date',
        CUSTOM: 'customerCareModule.scheduling_options.custom',
        EFFECTIVE: 'customerCareModule.scheduling_options.effective',
        EFFECTIVE_IMMEDIATELY: 'customerCareModule.scheduling_options.effective_immediately',
        IMMEDIATE: 'customerCareModule.scheduling_options.immediate',
        ON: 'customerCareModule.scheduling_options.on',
        ON_NEXT_BILL_CYCLE: 'customerCareModule.scheduling_options.on_next_bill_cycle',
        ORDER_RESCHEDULE: 'customerCareModule.scheduling_options.order_reschedule',
        SPECIFIED_DATE_EMPTY: 'customerCareModule.scheduling_options.specified_date_empty',
        SPECIFIED_DATE_MAX_NEW_ORDER: 'customerCareModule.scheduling_options.specified_date_max_new_order',
        SPECIFIED_DATE_MIN: 'customerCareModule.scheduling_options.specified_date_min',
        SPECIFIED_DATE_MIN_NEW_ORDER: 'customerCareModule.scheduling_options.specified_date_min_new_order'
    },
    SEARCH: {
        SEARCH_AGAIN: 'customerCareModule.search.search_again',
        SIMPLE_SEARCH_BUTTON: 'customerCareModule.search.simple_search_button',
        TITLE: 'customerCareModule.search.title'
    },
    SEARCH_ERRORS_CUSTOMER: {
        ACCOUNT_OR_REFERENCEID_ERROR: 'customerCareModule.search_errors_customer.account_or_referenceid_error',
        ADDITIONAL_SEARCH_CRITERIA_ERROR: 'customerCareModule.search_errors_customer.additional_search_criteria_error',
        EMAIL_FORMAT_ERROR: 'customerCareModule.search_errors_customer.email_format_error',
        EMPTY_FORM_ERROR: 'customerCareModule.search_errors_customer.empty_form_error',
        EMPTY_POSTAL_CODE_ERROR: 'customerCareModule.search_errors_customer.empty_postal_code_error',
        INVALID_NAME_ERROR: 'customerCareModule.search_errors_customer.invalid_name_error',
        NO_RESULTS_FOUND: 'customerCareModule.search_errors_customer.no_results_found',
        ORDERID_FORMAT_ERROR: 'customerCareModule.search_errors_customer.orderid_format_error',
        PAYMENT_TYPE_ERROR: 'customerCareModule.search_errors_customer.payment_type_error',
        SERVICEID_FORMAT_ERROR: 'customerCareModule.search_errors_customer.serviceid_format_error',
        SUBSCRIBERID_FORMAT_ERROR: 'customerCareModule.search_errors_customer.subscriberid_format_error'
    },
    SEARCH_ERRORS_GIFT_CARD: {
        ACCOUNT_NUMBER_ERROR: 'customerCareModule.search_errors_gift_card.account_number_error',
        ACCOUNT_NUMBER_LENGTH_ERROR: 'customerCareModule.search_errors_gift_card.account_number_length_error',
        EMAIL_FORMAT_ERROR: 'customerCareModule.search_errors_gift_card.email_format_error'
    },
    SEARCH_FIELDS_CUSTOMER: {
        ACCOUNT_NUMBER: 'customerCareModule.search_fields_customer.account_number',
        COMPANY_NAME: CoreLocaleKeys.COMPANY_NAME,
        CONTACT_NUMBER: 'customerCareModule.search_fields_customer.contact_number',
        EMAIL_OR_LOGIN: 'customerCareModule.search_fields_customer.email_or_login',
        EMAIL: 'customerCareModule.search_fields_customer.email',
        EXTERNAL_ID: 'customerCareModule.search_fields_customer.external_id',
        EXTERNAL_REFERENCE_ID: 'customerCareModule.transaction_type.external_reference_id',
        FIRST_NAME: 'customerCareModule.search_fields_customer.first_name',
        IDENTITY_VERIFICATION: 'customerCareModule.search_fields_customer.identity_verification',
        LAST_NAME: 'customerCareModule.search_fields_customer.last_name',
        ORIGINAL_TRANSACTION_ID: 'customerCareModule.transaction_details.itunes_detail_labels.original_transaction_id',
        PAYMENT_ACCOUNT_TITLE: {
            CREDIT_CARD: 'customerCareModule.search_fields_customer.payment_account_title.credit_card',
            CREDIT_CARD_FIRST_FOUR: 'customerCareModule.search_fields_customer.payment_account_title.credit_card_first_four',
            CREDIT_CARD_LAST_FOUR: 'customerCareModule.search_fields_customer.payment_account_title.credit_card_last_four',
            DEFAULT: 'customerCareModule.account_number',
            ECHECK: 'customerCareModule.account_number',
            EXTERNAL_BILLING: 'customerCareModule.account_number',
            ITUNES: 'customerCareModule.search_fields_customer.payment_account_title.itunes',
            GOOGLE_PLAY: 'customerCareModule.search_fields_customer.payment_account_title.google_play',
            PAYPAL: 'customerCareModule.search_fields_customer.payment_account_title.paypal',
            UWP: 'customerCareModule.search_fields_customer.payment_account_title.uwp'
        },
        PAYMENT_CREDIT_CARD_FULL: 'customerCareModule.search_fields_customer.payment_credit_card_full',
        PAYMENT_CREDIT_CARD_PARTIAL: 'customerCareModule.search_fields_customer.payment_credit_card_partial',
        PAYMENT_RECONCILIATION_ID: 'customerCareModule.search_fields_customer.payment_reconciliation_id',
        PAYMENT_REFERENCE_ID: 'customerCareModule.search_fields_customer.payment_reference_id',
        PAYMENT_SUBTYPE: 'customerCareModule.search_fields_customer.payment_subtype',
        PAYMENT_TRANSACTION_DATE: 'customerCareModule.search_fields_customer.payment_transaction_date',
        PAYMENT_TYPE: 'customerCareModule.search_fields_customer.payment_type',
        PHONE_NUMBER: 'customerCareModule.search_fields_customer.phone_number',
        PURCHASE_TOKEN: 'customerCareModule.transaction_details.google_play_detail_labels.purchase_token',
        RECEIPT_NUMBER: 'customerCareModule.transaction_details.pos_detail_labels.receipt_number',
        SERVICE_ID: 'customerCareModule.search_fields_customer.service_id',
        SINGLE_SELECT_PLACEHOLDER: 'customerCareModule.search_fields_customer.single_select_placeholder',
        SUBSCRIBER_ID: 'customerCareModule.search_fields_customer.subscriber_id',
        TAX_EXEMPTION_PLACEHOLDER: 'customerCareModule.search_fields_customer.tax_exemption_placeholder'
    },
    SEARCH_FIELDS_GIFT_CARD: {
        ACCOUNT_NUMBER: 'customerCareModule.account_number',
        FIELD_REQUIRED_MESSAGE: 'customerCareModule.search_fields_gift_card.field_required_message',
        PIN: 'customerCareModule.search_fields_gift_card.pin',
        SENDER_EMAIL: 'customerCareModule.search_fields_gift_card.sender_email'
    },
    SEARCH_FIELDS_COUPON: {
        REDEMPTION_CODE: 'customerCareModule.search_fields_coupon.redemption_code',
    },
    SEARCH_ITEM_ID: 'customerCareModule.search_item_id',
    SEARCH_OTHER_CUSTOMER: {
        CANNOT_SELECT_CURRENT_CUSTOMER: 'customerCareModule.search_other_customer.cannot_select_current_customer',
        DEPENDENT_REQUIRED_ERROR_MESSAGE: 'customerCareModule.search_other_customer.dependent_required_error_message',
        EXISTING_CUSTOMER: 'customerCareModule.search_other_customer.existing_customer',
        HOUSEHOLD_MEMBER: 'customerCareModule.search_other_customer.household_member',
        NO_HOUSEHOLD_MEMBERS: 'customerCareModule.search_other_customer.no_household_members',
        SEARCH_BY: 'customerCareModule.search_other_customer.search_by',
    },
    SEARCH_PLACEHOLDER: 'customerCareModule.search_placeholder',
    SEARCH_RESPONSIBLE_PARTY: 'customerCareModule.search_responsible_party',
    SEARCH_RESULTS: {
        BACK_ICON_HOVER_TEXT: 'customerCareModule.search_results.back_icon_hover_text',
        TITLE: 'customerCareModule.search_results.title'
    },
    SEARCH_SERVICES: 'customerCareModule.search_services',
    SECURITY_QUESTION: 'customerCareModule.security_question',
    SECURITY_QUESTION_ANSWER: 'customerCareModule.security_question_answer',
    SEE_ALL_CONTENT_ACTIVITY: 'customerCareModule.see_all_content_activity',
    SEE_ALL_INDIVIDUAL_USAGES: 'customerCareModule.see_all_individual_usages',
    SEE_ALL_INTERACTIONS: 'customerCareModule.see_all_interactions',
    SEE_ALL_SHARED_USAGES: 'customerCareModule.see_all_shared_usages',
    SELECT: 'customerCareModule.select',
    SELECT_WITH_ELLIPSIS: 'customerCareModule.select_with_ellipsis',
    SELECT_OPTIONS: 'customerCareModule.select_options',
    SELECT_NEXT_RENEWAL_DATE: 'customerCareModule.select_next_renewal_date',
    SELECT_SEASON: 'customerCareModule.select_season',
    SELECTABLE_ITEMS: {
        AT_LEAST: 'customerCareModule.selectable_items.at_least',
        AT_MOST: 'customerCareModule.selectable_items.at_most',
        BETWEEN: 'customerCareModule.selectable_items.between',
        EXACTLY: 'customerCareModule.selectable_items.exactly',
        FROM: 'customerCareModule.selectable_items.from',
        INVALID: 'customerCareModule.selectable_items.invalid'
    },
    SELECTED: 'customerCareModule.selected',
    SEND: 'customerCareModule.send',
    SEND_TO_STORE: {
        COMMENTS: 'customerCareModule.send_to_store.comments',
        PICKUP_DATE: 'customerCareModule.send_to_store.pickup_date',
        PICKUP_DETAILS: 'customerCareModule.send_to_store.pickup_details',
        SEND_FAILURE: 'customerCareModule.send_to_store.send_failure',
        SEND_SUCCESS: 'customerCareModule.send_to_store.send_success',
        SUBMIT_FOR_PICKUP: 'customerCareModule.send_to_store.submit_for_pickup'
    },
    SEND_TO_STORE_ERROR_POPUP: {
        TITLE: 'customerCareModule.send_to_store_error_popup.title'
    },
    SERVICE: 'customerCareModule.service',
    SERVICE_ADD_ONS: 'customerCareModule.service_add_ons',
    SERVICE_ATTRIBUTES: 'customerCareModule.service_attributes',
    SERVICE_FEATURE: 'customerCareModule.service_feature',
    SERVICE_FEATURES: 'customerCareModule.service_features',
    SERVICE_FEATURES_ADD: 'customerCareModule.service_features_add',
    SERVICE_FEATURES_CHOOSE: 'customerCareModule.service_features_choose',
    SERVICE_FEATURES_MANAGE: 'customerCareModule.service_features_manage',
    SERVICE_FEATURES_NONE_SELECTED: 'customerCareModule.service_features_none_selected',
    SERVICE_FEATURES_TITLE: 'customerCareModule.service_features_title',
    ADD_ONS_ADD: 'customerCareModule.add_ons_add',
    ADD_ONS_CHOOSE: 'customerCareModule.add_ons_choose',
    ADD_ONS_NONE_SELECTED: 'customerCareModule.add_ons_none_selected',
    SERVICE_SUSPEND_SERVICE_COUNT: 'customerCareModule.service_suspend_service_count',
    SERVICE_RESUME_SERVICE_COUNT: 'customerCareModule.service_resume_service_count',
    SERVICE_SUSPENSION_FEE: {
        ONE_TIME: 'customerCareModule.service_suspension_fee.one_time',
        RECURRING: 'customerCareModule.service_suspension_fee.recurring',
        TITLE: 'customerCareModule.service_suspension_fee.title'
    },
    SERVICE_SUSPENSION_TYPE: {
        NETWORK_AND_BILLING: 'customerCareModule.service_suspension_type.network_and_billing',
        NETWORK_ONLY: 'customerCareModule.service_suspension_type.network_only'
    },
    SERVICES: 'customerCareModule.services',
    SERVICES_AND_USAGE: {
        PAGE_TITLE: 'customerCareModule.services_and_usage.page_title',
        SHARED_ENTITLEMENTS: {
            FRIENDLY_NAME: 'customerCareModule.services_and_usage.shared_entitlements.friendly_name',
            NO_RESULTS: 'customerCareModule.services_and_usage.shared_entitlements.no_results',
            NO_RESULTS_MATCH_THE_SEARCH: 'customerCareModule.services_and_usage.shared_entitlements.no_results_match_the_search',
            SERVICE_ID: 'customerCareModule.services_and_usage.shared_entitlements.service_id',
            SUBSCRIBER_ID: 'customerCareModule.services_and_usage.shared_entitlements.subscriber_id',
            USAGE: 'customerCareModule.services_and_usage.shared_entitlements.usage'
        },
        TABS: {
            SERVICES: 'customerCareModule.services_and_usage.tabs.services',
            SHARED_ENTITLEMENTS: 'customerCareModule.services_and_usage.tabs.shared_entitlements'
        }
    },
    SERVICES_LIST: {
        SUSPENDED_STATUS_TOOLTIP: 'customerCareModule.services_list.suspended_status_tooltip',
        TYPE_OF_SERVICE: 'customerCareModule.services_list.type_of_service',
        TYPES: {
            BULK: 'customerCareModule.services_list.types.bulk',
            NON_BULK: 'customerCareModule.services_list.types.non_bulk',
        }
    },
    SERVICE_IDENTIFIER_ALREADY_EXISTS: 'customerCareModule.service_identifiers_already_exists',
    SERVICE_IDENTIFIER_DUPLICATES_FOUND: 'customerCareModule.service_identifier_duplicates_found',
    SERVICE_IDENTIFIER_UNIQUE_ERROR: 'customerCareModule.service_identifier_unique_error',
    SERVICE_INHERITING_HIERARCHY_NODE:'customerCareModule.service_inheriting_hierarchy_node',
    SERVICE_LIFECYCLE_STATUS: {
        SERVICE_LIFECYCLE_STATUS_TITLE: 'customerCareModule.service_lifecycle_status.service_lifecycle_status_title',
        TOOLTIP: 'customerCareModule.service_lifecycle_status.tooltip',
    },
    SERVICE_REQUIRED: 'customerCareModule.service_required',
    SERVICE_NAME: 'customerCareModule.service_name',
    SET_DATE: 'customerCareModule.set_date',
    SET_EXPIRATION_DATE: 'customerCareModule.set_expiration_date',
    SHARED_USAGE: 'customerCareModule.shared_usage',
    SHARING_ACTIONS: {
        DISABLE_SUCCESS: 'customerCareModule.sharing_actions.disable_success',
        ENABLE_SUCCESS: 'customerCareModule.sharing_actions.enable_success',
        START_SHARING: 'customerCareModule.sharing_actions.start_sharing',
        STOP_SHARING: 'customerCareModule.sharing_actions.stop_sharing'
    },
    SHIPPING: 'customerCareModule.shipping',
    SHIPPING_ADDRESS: 'customerCareModule.shipping_address',
    SHIPPING_INFORMATION: 'customerCareModule.shipping_information',
    SHIPPING_TO: 'customerCareModule.shipping_to',
    SHIPPING_METHOD: 'customerCareModule.shipping_method',
    SHIPPING_METHOD_SELECT: 'customerCareModule.shipping_method_select',
    SHIPPING_TAX_MESSAGE: 'customerCareModule.shipping_tax_message',
    SHOW: 'customerCareModule.show',
    SHOW_ANSWER: 'customerCareModule.show_answer',
    SHOW_MORE: 'customerCareModule.show_more',
    SOCIAL_SECURITY_NUMBER: 'customerCareModule.social_security_number',
    SSN: 'customerCareModule.ssn',
    START_BY_CREATING_ONE_NOW: 'customerCareModule.start_by_creating_one_now',
    START_DATE: 'customerCareModule.start_date',
    STARTS_ON: 'customerCareModule.starts_on',
    STATE_PROVINCE: 'customerCareModule.state_province',
    STATE_REGION_PROVINCE: 'state_region_province',
    STATE_REGION_PROVINCE_REQUIRED: 'state_region_province_required',
    STATUS: 'customerCareModule.status',
    STORE_INVENTORY_SEARCH_MODAL: {
        REQUEST_ERROR: 'customerCareModule.store_inventory_search_modal.request_error',
        SELECT_REGION_PRIMARY: 'customerCareModule.store_inventory_search_modal.select_region_primary',
        SELECT_REGION_SECONDARY: 'customerCareModule.store_inventory_search_modal.select_region_secondary',
        SELECT_STORE: 'customerCareModule.store_inventory_search_modal.select_store',
        SELECTED: 'customerCareModule.store_inventory_search_modal.selected',
        TITLE_TEXT: 'customerCareModule.store_inventory_search_modal.title_text'
    },
    SUBJECT: 'customerCareModule.subject',
    SUBMIT: 'customerCareModule.submit',
    SUBMIT_FOR_PICK_UP: 'customerCareModule.submit_for_pick_up',
    SUBMIT_ORDER: 'customerCareModule.submit_order',
    SUBSCRIBER_ID: 'customerCareModule.subscriber_id',
    SUBSCRIBER: {
        STATE: {
            PROSPECT: 'customerCareModule.subscriber.state.prospect',
            ACTIVE: 'customerCareModule.subscriber.state.active',
            DISCONNECTED: 'customerCareModule.subscriber.state.disconnected',
            ANONYMIZED: 'customerCareModule.subscriber.state.anonymized',
            BLOCKLISTED: 'customerCareModule.subscriber.state.blocklisted'
        },
        STATE_LABEL: 'customerCareModule.subscriber.state_label'
    },
    SUBSCRIBER_OFFER: {
        SERVICE_DETAILS: {
            SERVICE_ADD_ONS: 'customerCareModule.subscriber_offer.service_details.service_add_ons',
            ADD_ONS: 'customerCareModule.subscriber_offer.service_details.add_ons'
        }
    },
    SUBSCRIPTION: 'customerCareModule.subscription',
    SUBSCRIPTIONS: {
        ADD_DATE: 'customerCareModule.subscriptions.add_date',
        AFFECTED_SUBSCRIPTIONS: 'customerCareModule.subscriptions.affected_subscriptions',
        APPLY_CHANGES: 'customerCareModule.subscriptions.apply_changes',
        APPLY_DISCOUNT: 'customerCareModule.subscriptions.apply_discount',
        AUTOMATIC_RENEWAL: 'customerCareModule.subscriptions.automatic_renewal',
        CANCEL_REMOVAL: 'customerCareModule.subscriptions.cancel_removal',
        CANCEL_REMOVAL_SUCCESS: 'customerCareModule.subscriptions.cancel_removal_success',
        CHANGE_IMMEDIATE: 'customerCareModule.subscriptions.change_immediate',
        CHANGE_NEXT_CYCLE: 'customerCareModule.subscriptions.change_next_cycle',
        COLUMNS: {
            PRICING_PLAN: 'customerCareModule.subscriptions.columns.pricing_plan',
            PRODUCT: 'customerCareModule.subscriptions.columns.product',
            REMINDER_DATE: 'customerCareModule.subscriptions.columns.reminder_date',
            RENEW: 'customerCareModule.subscriptions.columns.renew',
            RENEWAL_DATE: 'customerCareModule.subscriptions.columns.renewal_date',
            RENEWAL_TYPE: 'customerCareModule.subscriptions.columns.renewal_type',
            STATUS: 'customerCareModule.subscriptions.columns.status'
        },
        CONFIRM: 'customerCareModule.subscriptions.confirm',
        CONTRACT_END: 'customerCareModule.subscriptions.contract_end',
        DISCOUNTS: {
            APPLIED: 'customerCareModule.subscriptions.discounts.applied',
            AMOUNT: 'customerCareModule.subscriptions.discounts.amount',
            COUPON: 'customerCareModule.subscriptions.discounts.coupon',
            CURRENT_CYCLE: 'customerCareModule.subscriptions.discounts.current_cycle',
            CYCLES_REMAINING: 'customerCareModule.subscriptions.discounts.cycles_remaining',
            DISCOUNT: 'customerCareModule.subscriptions.discounts.discount',
            EXPIRATION_DATE: 'customerCareModule.subscriptions.discounts.expiration_date',
            REMOVED: 'customerCareModule.subscriptions.discounts.removed',
            SOURCE: 'customerCareModule.subscriptions.discounts.source',
            STATUS: 'customerCareModule.subscriptions.discounts.status',
            REMOVE_REASON: 'customerCareModule.subscriptions.discounts.remove_reason'
        },
        EXPIRES: 'customerCareModule.subscriptions.expires',
        FEES: 'customerCareModule.subscriptions.fees',
        FULFILLMENT_DATE: 'customerCareModule.subscriptions.fulfillment_date',
        ITEM: {
            EFFECTIVE_ON: 'customerCareModule.subscriptions.item.effective_on',
            EXTENSIONS: {
                END_DATE: 'customerCareModule.subscriptions.item.extensions.end_date',
                EXTENSIONS: 'customerCareModule.subscriptions.item.extensions.extensions',
                REMOVE_ERROR: 'customerCareModule.subscriptions.item.extensions.remove_error',
                START_DATE: 'customerCareModule.subscriptions.item.extensions.start_date',
                STATUS: 'customerCareModule.subscriptions.item.extensions.status'
            },
            GROSS_RENEW_AMOUNT: 'customerCareModule.subscriptions.item.gross_renew_amount',
            ID: 'customerCareModule.subscriptions.item.id',
            ITEM_ID: 'customerCareModule.subscriptions.item.item_id',
            PRICING_PLAN_ID: 'customerCareModule.subscriptions.item.pricing_plan_id',
            PRICING_PLAN_NAME: 'customerCareModule.subscriptions.item.pricing_plan_name',
            PRODUCT_ID: 'customerCareModule.subscriptions.item.product_id',
            PRODUCT_NAME: 'customerCareModule.subscriptions.item.product_name',
            RENEW: 'customerCareModule.subscriptions.item.renew',
            RENEW_AMOUNT: 'customerCareModule.subscriptions.item.renew_amount',
            RENEWAL_PRICING_PLAN: 'customerCareModule.subscriptions.item.renewal_pricing_plan',
            RENEWAL_SOURCE: 'customerCareModule.subscriptions.item.renewal_source',
            SHIPPED: 'customerCareModule.subscriptions.item.shipped'
        },
        ITEMS: 'customerCareModule.subscriptions.items',
        LATE_FEE: 'customerCareModule.subscriptions.late_fee',
        MANUAL_RENEWAL: 'customerCareModule.subscriptions.manual_renewal',
        MODIFY: 'customerCareModule.subscriptions.modify',
        MODIFY_RENEWAL: 'customerCareModule.subscriptions.modify_renewal',
        MODIFY_SUBSCRIPTION: 'customerCareModule.subscriptions.modify_subscription',
        NO_OPTIONS: 'customerCareModule.subscriptions.no_options',
        NO_RESULTS: 'customerCareModule.subscriptions.no_results',
        NON_RENEW: 'customerCareModule.subscriptions.non_renew',
        OKAY: 'customerCareModule.subscriptions.okay',
        ORDER_NEW: 'customerCareModule.subscriptions.order_new',
        ORDER_NUMBER: 'customerCareModule.subscriptions.order_number',
        ORDER_STATUS: 'customerCareModule.subscriptions.order_status',
        PAYMENT_INFO: 'customerCareModule.subscriptions.payment_info',
        PAYMENT_METHOD: 'customerCareModule.subscriptions.payment_method',
        PAYMENT_SHIPPING_INFO: 'customerCareModule.subscriptions.payment_shipping_info',
        PENDING_ORDER_NUMBER: 'customerCareModule.subscriptions.pending_order_number',
        PENDING_ORDER_NUMBER_TOOLTIP: 'customerCareModule.subscriptions.pending_order_number_tooltip',
        PRICING_PLANS: 'customerCareModule.subscriptions.pricing_plans',
        REMOVAL_DATE: 'customerCareModule.subscriptions.removal_date',
        REMOVE_DISCOUNT: {
            SUCCESS: 'customerCareModule.subscriptions.remove_discount.success',
            TITLE: 'customerCareModule.subscriptions.remove_discount.title'
        },
        REMOVE_EXTENSION: {
            AMOUNT_TO_CREDIT: 'customerCareModule.subscriptions.remove_extension.amount_to_credit',
            CONFIRMATION_MESSAGE: 'customerCareModule.subscriptions.remove_extension.confirmation_message',
            CUSTOM_AMOUNT_INVALID: 'customerCareModule.subscriptions.remove_extension.custom_amount_invalid',
            END_DATE: 'customerCareModule.subscriptions.remove_extension.end_date',
            EXTENSION: 'customerCareModule.subscriptions.remove_extension.extension',
            FEE_CREDIT: 'customerCareModule.subscriptions.remove_extension.fee_credit',
            MAX_AMOUNT: 'customerCareModule.subscriptions.remove_extension.max_amount',
            REMOVE: 'customerCareModule.subscriptions.remove_extension.remove',
            REMOVE_EXTENSION: 'customerCareModule.subscriptions.remove_extension.remove_extension',
            REMOVE_PRICING_PLAN: 'customerCareModule.subscriptions.remove_extension.remove_pricing_plan',
            REMOVE_SUCCESS: 'customerCareModule.subscriptions.remove_extension.remove_success',
            START_DATE: 'customerCareModule.subscriptions.remove_extension.start_date',
            STATUS: 'customerCareModule.subscriptions.remove_extension.status',
            TOTAL_CREDIT_APPLIED: 'customerCareModule.subscriptions.remove_extension.total_credit_applied'
        },
        REMOVE_SUBSCRIPTION: {
            APPLY_TOTAL_CREDIT: 'customerCareModule.subscriptions.remove_subscription.apply_total_credit',
            CONFIRMATION_MESSAGE: 'customerCareModule.subscriptions.remove_subscription.confirmation_message',
            CREDIT: 'customerCareModule.subscriptions.remove_subscription.credit',
            CREDIT_AMOUNT: 'customerCareModule.subscriptions.remove_subscription.credit_amount',
            CREDIT_NOT_AVAILABLE: 'customerCareModule.subscriptions.remove_subscription.credit_not_available',
            CREDIT_REASON: 'customerCareModule.subscriptions.remove_subscription.credit_reason',
            FEES_CREDIT: 'customerCareModule.subscriptions.remove_subscription.fees_credit',
            ITEMIZED_CREDIT: 'customerCareModule.subscriptions.remove_subscription.itemized_credit',
            MAXIMUM_AMOUNT: 'customerCareModule.subscriptions.remove_subscription.maximum_amount',
            NOTIFICATION_OPTIONS: 'customerCareModule.subscriptions.remove_subscription.notification_options',
            PAYMENT_REQUIRED: 'customerCareModule.subscriptions.remove_subscription.payment_required',
            REMOVE: 'customerCareModule.subscriptions.remove_subscription.remove',
            REMOVE_ALL: 'customerCareModule.subscriptions.remove_subscription.remove_all',
            SEND_NOTIFICATION: 'customerCareModule.subscriptions.remove_subscription.send_notification',
            SUCCESS: 'customerCareModule.subscriptions.remove_subscription.success',
            TERMINATION_FEE: 'customerCareModule.subscriptions.remove_subscription.termination_fee',
            TERMINATION_FEE_SUBTOTAL: 'customerCareModule.subscriptions.remove_subscription.termination_fee_subtotal',
            TERMINATION_FEE_TAX: 'customerCareModule.subscriptions.remove_subscription.termination_fee_tax',
            TOTAL_CREDIT_TO_APPLY: 'customerCareModule.subscriptions.remove_subscription.total_credit_to_apply',
            TOTAL_USED_CREDIT: 'customerCareModule.subscriptions.remove_subscription.total_used_credit',
            UNDER_CONTRACT: 'customerCareModule.subscriptions.remove_subscription.under_contract',
            UNUSED: 'customerCareModule.subscriptions.remove_subscription.unused',
            WAIVE_TERMINATION: 'customerCareModule.subscriptions.remove_subscription.waive_termination'
        },
        REMINDER_DATE: 'customerCareModule.subscriptions.reminder_date',
        RENEW_AUTOMATICALLY: 'customerCareModule.subscriptions.renew_automatically',
        RENEWS: 'customerCareModule.subscriptions.renews',
        RENEWAL_AMOUNT: 'customerCareModule.subscriptions.renewal_amount',
        RENEWAL_COUNT: 'customerCareModule.subscriptions.renewal_count',
        RENEWAL_TYPE: 'customerCareModule.subscriptions.renewal_type',
        RENEW_CYCLE: 'customerCareModule.subscriptions.renew_cycle',
        REPLACE: 'customerCareModule.subscriptions.replace',
        REPLACE_SUBSCRIPTION: 'customerCareModule.subscriptions.replace_subscription',
        RESTORE_SUBSCRIPTION: 'customerCareModule.subscriptions.restore_subscription',
        SCHEDULE: 'customerCareModule.subscriptions.schedule',
        SHIPPING_ADDRESS: 'customerCareModule.subscriptions.shipping_address',
        SHIPPING_METHOD: 'customerCareModule.subscriptions.shipping_method',
        SUBSCRIPTION_CURRENT_PRICING_PLAN: 'customerCareModule.subscriptions.subscription_current_pricing_plan',
        SUBSCRIPTION_DATES: 'customerCareModule.subscriptions.subscription_dates',
        SUBSCRIPTION_ID: 'customerCareModule.subscriptions.subscription_id',
        SUBSCRIPTION_PRICING_PLANS: 'customerCareModule.subscriptions.subscription_pricing_plans',
        SUBSCRIPTION_RENEWAL_PRICING_PLAN: 'customerCareModule.subscriptions.subscription_renewal_pricing_plan',
        SUBSCRIPTION_STATUS: 'customerCareModule.subscriptions.subscription_status',
        TERMINATION_FEE: 'customerCareModule.subscriptions.termination_fee',
        TERMINATION_REASON: 'customerCareModule.subscriptions.termination_reason',
        TITLE: 'customerCareModule.subscriptions.title',
        TOTAL_USED_CREDIT: 'customerCareModule.subscriptions.total_used_credit',
        TOTAL_CREDIT_TO_APPLY: 'customerCareModule.subscriptions.total_credit_to_apply',
        UPDATE: 'customerCareModule.subscriptions.update',
        UPDATE_SUCCESS: 'customerCareModule.subscriptions.update_success',
        VIEW_PAYMENT_HISTORY: 'customerCareModule.subscriptions.view_payment_history',
        WAIVE_ALL_CHARGES: 'customerCareModule.subscriptions.waive_all_charges',
        WAIVE_CHARGE_REASON: 'customerCareModule.subscriptions.waive_charge_reason',
        WAIVE_LATE_FEE: 'customerCareModule.subscriptions.waive_late_fee',
        WAIVE_LATE_FEE_REASON: 'customerCareModule.subscriptions.waive_late_fee_reason',
        WILL_NOT_RENEW: 'customerCareModule.subscriptions.will_not_renew',
    },
    SUBTENANT: 'customerCareModule.subtenant',
    SUBTOTAL: 'customerCareModule.subtotal',
    SUFFIX: 'customerCareModule.suffix',
    SUMMARY: 'customerCareModule.summary',
    REFUND_MESSAGE: 'customerCareModule.refund_message',
    SUPPRESS_ZERO_USAGE_CHARGE: 'customerCareModule.suppress_zero_usage_charge',
    SUSPEND: 'customerCareModule.suspend',
    SUSPEND_DEVICE_FINANCE_CHARGES: 'customerCareModule.suspend_device_finance_charges',
    SUSPENDED: 'customerCareModule.suspended',
    SUSPEND_CONFIRMATION: 'customerCareModule.suspend_confirmation',
    SUSPEND_OFFER: 'customerCareModule.suspend_offer',
    SUSPEND_OFFER_FORM_HINT: 'customerCareModule.suspend_offer_form_hint',
    SUSPEND_OFFER_SUBMITTED: 'customerCareModule.suspend_offer_submitted',
    SUSPEND_SERVICE: 'customerCareModule.suspend_service',
    SUSPEND_SERVICES: 'customerCareModule.suspend_services',
    SUSPEND_SERVICES_FAILURE: 'customerCareModule.suspend_services_failure',
    SUSPEND_SERVICES_SUCCESS: 'customerCareModule.suspend_services_success',
    SUSPENDED_SERVICE_ERROR: 'customerCareModule.suspended_services_error',
    SUSPENSION_TYPE: 'customerCareModule.suspension_type',
    TAX_AND_GL_LOCATION: {
        CHANGE_STORE: 'customerCareModule.tax_and_gl_location.change_store',
        CUSTOMER_ADDRESS: 'customerCareModule.tax_and_gl_location.customer_address',
        SELECT_CUSTOMER_ADDRESS: 'customerCareModule.tax_and_gl_location.select_customer_address',
        SELECT_CUSTOMER_ADDRESS_AND_SERVICE_IDENTIFIER: 'customerCareModule.tax_and_gl_location.select_customer_address_and_service_identifier',
        SELECT_LOCATION_TOAST_MESSAGE: 'customerCareModule.tax_and_gl_location.select_location_toast_message',
        SELECT_SERVICE_IDENTIFIER: 'customerCareModule.tax_and_gl_location.select_service_identifier',
        SELECT_STORE: 'customerCareModule.tax_and_gl_location.select_store',
        SELECT_TAX_AND_GL_LOCATION: 'customerCareModule.tax_and_gl_location.select_tax_and_gl_location',
        SERVICE_IDENTIFIER: 'customerCareModule.tax_and_gl_location.service_identifier',
        STORE_LOCATION: 'customerCareModule.tax_and_gl_location.store_location',
        TITLE: 'customerCareModule.tax_and_gl_location.title'
    },
    TAX_EXEMPT: 'customerCareModule.tax_exempt',
    TAX_EXEMPT_TOOLTIP: 'customerCareModule.tax_exempt_tooltip',
    TAXES: 'customerCareModule.taxes',
    TAX_LABELS: {
        ADDITIONAL_INDIVIDUAL_EXEMPTION: 'customerCareModule.tax_labels.additional_individual_exemption',
        CATEGORY_EXEMPTIONS: 'customerCareModule.tax_labels.category_exemptions',
        CHARGE_TYPES_FOR_DETAILED_TAXATION: 'customerCareModule.tax_labels.charge_types_for_detailed_taxation',
        DROPDOWN_SELECT_VALIDATIONS: {
            ROW_INCOMPLETE: 'customerCareModule.tax_labels.dropdown_select_validations.row_incomplete',
            ROW_DUPLICATE: 'customerCareModule.tax_labels.dropdown_select_validations.row_duplicate',
            ROW_REQUIRED: 'customerCareModule.tax_labels.dropdown_select_validations.row_required'
        },
        INCLUDE: 'customerCareModule.tax_labels.include',
        INDIVIDUAL_EXEMPTIONS: 'customerCareModule.tax_labels.individual_exemptions',
        INDIVIDUAL_TAX_EXEMPTION: 'customerCareModule.tax_labels.individual_tax_exemption',
        LEVELS: {
            EXEMPT: 'customerCareModule.tax_labels.levels.exempt',
            COUNTY: 'customerCareModule.tax_labels.levels.county',
            FEDERAL: 'customerCareModule.tax_labels.levels.federal',
            LIFELINE: 'customerCareModule.tax_labels.levels.lifeline',
            LOCAL: 'customerCareModule.tax_labels.levels.local',
            STATE: 'customerCareModule.tax_labels.levels.state',
            UNINCORPORATED: 'customerCareModule.tax_labels.levels.unincorporated'
        },
        LEVEL_EXEMPTIONS: 'customerCareModule.tax_labels.level_exemptions',
        SELECT: CoreLocaleKeys.SELECT,
        OVERRIDE: 'customerCareModule.tax_labels.override',
        PREFERRED_CURRENCY_FOR_TAX_REPORTING: 'customerCareModule.tax_labels.preferred_currency_for_tax_reporting',
        TAX_ASSOCIATION_CODE: 'customerCareModule.tax_labels.tax_association_code',
        TAX_EXEMPTION: 'customerCareModule.tax_labels.tax_exemption',
        TAX_EXEMPTIONS: 'customerCareModule.tax_labels.tax_exemptions',
        TAX_EXEMPTION_CATEGORY: 'customerCareModule.tax_labels.tax_exemption_category',
        TAX_EXEMPTION_LEVEL: 'customerCareModule.tax_labels.tax_exemption_level',
        TAX_LEVEL: 'customerCareModule.tax_labels.tax_level',
        TAXATION_STATE: 'customerCareModule.tax_labels.taxation_state',
        TAX_TYPE: 'customerCareModule.tax_labels.tax_type'
    },
    TAX_LOCATION_RULES: {
        ADDRESS_ON_FILE: 'customerCareModule.tax_location_rules.address_on_file',
        BILL_TO_LOCATION_TOOLTIP_CONTENT: 'customerCareModule.tax_location_rules.bill_to_location_tooltip_content',
        BILL_TO_LOCATION: 'customerCareModule.tax_location_rules.bill_to_location',
        CUSTOMIZE: 'customerCareModule.tax_location_rules.customize',
        DEFAULT_BEHAVIOR: 'customerCareModule.tax_location_rules.default_behavior',
        DEFAULT_TAX_LOGIC: 'customerCareModule.tax_location_rules.default_tax_logic',
        INFORMATION: 'customerCareModule.tax_location_rules.information',
        NPA_NXX_ENTRY: 'customerCareModule.tax_location_rules.npa_nxx_entry',
        NPA_NXX: 'customerCareModule.tax_location_rules.npa_nxx',
        ORIGINATING_LOCATION_TOOLTIP_CONTENT: 'customerCareModule.tax_location_rules.originating_location_tooltip_content',
        ORIGINATING_LOCATION: 'customerCareModule.tax_location_rules.originating_location',
        TAX_LOCATION_RULES: 'customerCareModule.tax_location_rules.tax_location_rules',
        TAX_PERCENTAGE_SPLIT_ORIGINATING: 'customerCareModule.tax_location_rules.tax_percentage_split_originating',
        TAX_PERCENTAGE_SPLIT_OVERRIDE: 'customerCareModule.tax_location_rules.tax_percentage_split_override',
        TAX_PERCENTAGE_SPLIT_TERMINATING: 'customerCareModule.tax_location_rules.tax_percentage_split_terminating',
        TAX_PERCENTAGE_SPLIT: 'customerCareModule.tax_location_rules.tax_percentage_split',
        TERMINATING_LOCATION_TOOLTIP_CONTENT: 'customerCareModule.tax_location_rules.terminating_location_tooltip_content',
        TERMINATING_LOCATION: 'customerCareModule.tax_location_rules.terminating_location',
        TITLE: 'customerCareModule.tax_location_rules.title',
        USE_DEFAULT: 'customerCareModule.tax_location_rules.use_default'
    },
    TERMS_AND_CONDITIONS: 'customerCareModule.terms_conditions',
    TIERED_USAGE_RATES: 'customerCareModule.tiered_usage_rates',
    TOP_UPS: {
        APPLY_TOP_UP: 'customerCareModule.top_ups.apply_top_up',
        DETAILS_HIDE: 'customerCareModule.top_ups.details_hide',
        DETAILS_SHOW: 'customerCareModule.top_ups.details_show',
        FAILURE: 'customerCareModule.top_ups.failure',
        NO_TOP_UPS: 'customerCareModule.top_ups.no_top_ups',
        SELECT_PAYMENT_METHOD: 'customerCareModule.top_ups.select_payment_method',
        SUCCESS: 'customerCareModule.top_ups.success',
        TOP_UP: 'customerCareModule.top_ups.top_up',
        TOP_UP_REQUIRED: 'customerCareModule.top_ups.top_up_required'
    },
    TOTAL: 'customerCareModule.total',
    TOTAL_REMAINING: 'customerCareModule.total_remaining',
    TOTAL_TOOLTIP: 'customerCareModule.total_tooltip',
    TOTAL_USAGE: 'customerCareModule.total_usage',
    TOTAL_USED: 'customerCareModule.total_used',
    TOTALS: {
        CREDITS: 'customerCareModule.totals.credits',
        CURRENCY: 'customerCareModule.totals.currency',
        EMPTY_STATE_LINE_ONE: 'customerCareModule.totals.empty_state_line_one',
        EMPTY_STATE_LINE_TWO: 'customerCareModule.totals.empty_state_line_two',
        NET_AMOUNT: 'customerCareModule.totals.net_amount',
        ORIGINAL_AMOUNT: 'customerCareModule.totals.original_amount',
        TITLE: 'customerCareModule.totals.title',
        WRITE_OFFS: 'customerCareModule.totals.write_offs'
    },
    TRANSACTION: 'customerCareModule.transaction',
    TRANSACTION_DETAILS: {
        ALA_CARTE: 'customerCareModule.transaction_details.ala_carte',
        AMOUNT: 'customerCareModule.transaction_details.amount',
        AMOUNT_REQUIRED: 'customerCareModule.transaction_details.amount_required',
        APPLY_CREDIT: 'customerCareModule.transaction_details.apply_credit',
        ATTEMPTED_AMOUNT: 'customerCareModule.transaction_details.attempted_amount',
        AUTHORIZED_USER: 'customerCareModule.transaction_details.authorized_user',
        BALANCE: 'customerCareModule.transaction_details.balance',
        BILL_PAY: 'customerCareModule.transaction_details.bill_pay',
        BILLING_RETRY: 'customerCareModule.transaction_details.billing_retry',
        COMPLETE: 'customerCareModule.transaction_details.complete',
        CURRENCY: 'customerCareModule.transaction_details.currency',
        DATE: 'customerCareModule.transaction_details.date',
        DESCRIPTION: 'customerCareModule.transaction_details.description',
        DEVICE_TYPE: 'customerCareModule.transaction_details.device_type',
        DISTRIBUTION_CHANNEL: 'customerCareModule.transaction_details.distribution_channel',
        EMAIL_TRANSACTION: 'customerCareModule.transaction_details.email_transaction',
        ITEM_TYPE: 'customerCareModule.transaction_details.item_type',
        LAST_UPDATED: 'customerCareModule.transaction_details.last_updated',
        MERCHANT_ID: 'customerCareModule.transaction_details.merchant_id',
        NO_TRANSACTIONS: 'customerCareModule.transaction_details.no_transactions',
        ORIGINAL_TOTAL: 'customerCareModule.transaction_details.original_total',
        OFFER: 'customerCareModule.transaction_details.offer',
        OFFER_CAPITAL: 'customerCareModule.transaction_details.offer_capital',
        ORDER_NUMBER: 'customerCareModule.transaction_details.order_number',
        ORDER_PROCESSING: 'customerCareModule.transaction_details.order_processing',
        ORDER_TYPE: 'customerCareModule.transaction_details.order_type',
        OPEN: 'customerCareModule.transaction_details.open',
        PAYMENT_INSTRUMENT_CHARGE: 'customerCareModule.transaction_details.payment_instrument_charge',
        PAYMENT_INSTRUMENT_DEPOSIT: 'customerCareModule.transaction_details.payment_instrument_deposit',
        PAYMENT_INSTRUMENT_VALIDATION: 'customerCareModule.transaction_details.payment_instrument_validation',
        PRICING_PLAN: 'customerCareModule.transaction_details.pricing_plan',
        PRINT_TRANSACTION: 'customerCareModule.transaction_details.print_transaction',
        PROCESSING_DATE: 'customerCareModule.transaction_details.processing_date',
        PROCESSING_DETAILS: 'customerCareModule.transaction_details.processing_details',
        PRODUCT: 'customerCareModule.transaction_details.product',
        PURCHASE_ITEMS: 'customerCareModule.transaction_details.purchase_items',
        PURCHASE_ORDER: 'customerCareModule.transaction_details.purchase_order',
        PURCHASER_ID: 'customerCareModule.transaction_details.purchaser_id',
        REASON_REQUIRED: 'customerCareModule.transaction_details.reason_required',
        REFERENCE_ID: 'customerCareModule.transaction_details.reference_id',
        REFUND: 'customerCareModule.transaction_details.refund',
        REMAINING_BALANCE: 'customerCareModule.transaction_details.remaining_balance',
        RENEWAL: 'customerCareModule.transaction_details.renewal',
        RENEWAL_TYPE: 'customerCareModule.transaction_details.renewal_type',
        RESULT: 'customerCareModule.transaction_details.result',
        RETURN_TO_ORDER_DETAILS: 'customerCareModule.transaction_details.return_to_order_details',
        RETURN_TO_SHARED_ENTITLEMENTS: 'customerCareModule.transaction_details.return_to_shared_entitlements',
        RETURN_TO_TRANSACTION_DETAILS: 'customerCareModule.transaction_details.return_to_transaction_details',
        SCHEDULE: 'customerCareModule.transaction_details.schedule',
        SERVICE_ID: 'customerCareModule.transaction_details.service_id',
        STATE: 'customerCareModule.transaction_details.state',
        SUB_TOTAL: 'customerCareModule.transaction_details.sub_total',
        SUBSCRIPTION_FEE: 'customerCareModule.transaction_details.subscription_fee',
        SUCCESSFUL_CREDIT: 'customerCareModule.transaction_details.successful_credit',
        SUCCESSFUL_REFUND: 'customerCareModule.transaction_details.successful_refund',
        SUCCESSFUL_EMAIL: 'customerCareModule.transaction_details.successful_email',
        SUCCESSFUL_WRITE_OFF: 'customerCareModule.transaction_details.successful_write_off',
        TITLE: 'customerCareModule.transaction_details.title',
        TOTAL_AMOUNT: 'customerCareModule.transaction_details.total_amount',
        TOTAL_CHANGES: 'customerCareModule.transaction_details.total_changes',
        TRANSACTED_AMOUNT: 'customerCareModule.transaction_details.transacted_amount',
        TRANSACTED_DATE: 'customerCareModule.transaction_details.transacted_date',
        TRANSACTION_ID: 'customerCareModule.transaction_details.transaction_id',
        TRANSACTION_ITEMS: 'customerCareModule.transaction_details.transaction_items',
        TYPE: 'customerCareModule.transaction_details.type',
        UNABLE_TO_OPEN_WINDOW: 'customerCareModule.transaction_details.unable_to_open_window',
        WRITE_OFF: 'customerCareModule.transaction_details.write_off',
        WRITE_OFF_REVERSAL: 'customerCareModule.transaction_details.write_off_reversal',
        ECHECK_DETAIL_LABELS: {
            ACCOUNT_NUMBER: 'customerCareModule.account_number',
            AVS_CODE: 'customerCareModule.transaction_details.echeck_detail_labels.avs_code',
            AVS_RAW_CODE: 'customerCareModule.transaction_details.echeck_detail_labels.avs_raw_code',
            GATEWAY_RESULT_CODE: 'customerCareModule.transaction_details.echeck_detail_labels.gateway_result_code',
            PROCESSOR_RESPONSE_CODE: 'customerCareModule.transaction_details.echeck_detail_labels.processor_response_code',
            PROCESSOR_TRANSACTION_ID: 'customerCareModule.transaction_details.echeck_detail_labels.processor_transaction_id',
            RECONCILIATION_ID: 'customerCareModule.transaction_details.echeck_detail_labels.reconciliation_id',
            REQUEST_ID: 'customerCareModule.transaction_details.echeck_detail_labels.request_id',
            REQUEST_TOKEN: 'customerCareModule.transaction_details.echeck_detail_labels.request_token',
            REQUESTED_DATE: 'customerCareModule.transaction_details.echeck_detail_labels.requested_date',
            ROUTING_NUMBER: 'customerCareModule.transaction_details.echeck_detail_labels.routing_number'
        },
        ITUNES_DETAIL_LABELS: {
            PRODUCT_ID: 'customerCareModule.transaction_details.itunes_detail_labels.product_id',
            TRANSACTION_ID: 'customerCareModule.transaction_details.itunes_detail_labels.transaction_id',
            ORIGINAL_TRANSACTION_ID: 'customerCareModule.transaction_details.itunes_detail_labels.original_transaction_id',
            STATUS_CODE: 'customerCareModule.transaction_details.itunes_detail_labels.status_code',
            TRANSACTION_RECEIPT: 'customerCareModule.transaction_details.itunes_detail_labels.transaction_receipt',
            APPLE_ITEM_ID: 'customerCareModule.transaction_details.itunes_detail_labels.apple_item_id',
            BUNDLE_ID: 'customerCareModule.transaction_details.itunes_detail_labels.bundle_id',
            VERSION: 'customerCareModule.transaction_details.itunes_detail_labels.version',
            UNIQUE_ID: 'customerCareModule.transaction_details.itunes_detail_labels.unique_id',
            UNIQUE_VENDOR_ID: 'customerCareModule.transaction_details.itunes_detail_labels.unique_vendor_id',
            WEB_ORDER_LINE_ITEM_ID: 'customerCareModule.transaction_details.itunes_detail_labels.web_order_line_item_id',
            EXPIRATION_DATE: 'customerCareModule.transaction_details.itunes_detail_labels.expiration_date',
            ORIGINAL_PURCHASE_DATE: 'customerCareModule.transaction_details.itunes_detail_labels.original_purchase_date',
            PURCHASE_DATE: 'customerCareModule.transaction_details.itunes_detail_labels.purchase_date'
        },
        GOOGLE_PLAY_DETAIL_LABELS: {
            PURCHASE_TOKEN: 'customerCareModule.transaction_details.google_play_detail_labels.purchase_token',
            PURCHASE_STATE: 'customerCareModule.transaction_details.google_play_detail_labels.purchase_state',
            CONSUMPTION_STATE: 'customerCareModule.transaction_details.google_play_detail_labels.consumption_state',
            DEVELOPER_PAYLOAD: 'customerCareModule.transaction_details.google_play_detail_labels.developer_payload',
            PRODUCT_ID: 'customerCareModule.transaction_details.google_play_detail_labels.product_id',
            PACKAGE_NAME: 'customerCareModule.transaction_details.google_play_detail_labels.package_name',
            AUTO_RENEWING: 'customerCareModule.transaction_details.google_play_detail_labels.auto_renewing',
            PURCHASE_DATE: 'customerCareModule.transaction_details.google_play_detail_labels.purchase_date',
            EXPIRATION_DATE: 'customerCareModule.transaction_details.google_play_detail_labels.expiration_date'
        },
        ACP_DETAIL_LABELS: {
            BILL_CODE: 'customerCareModule.transaction_details.acp_detail_labels.bill_code',
            BILL_TYPE: 'customerCareModule.transaction_details.acp_detail_labels.bill_type',
            DESCRIPTION: 'customerCareModule.transaction_details.acp_detail_labels.description',
            FROM_DATE: 'customerCareModule.transaction_details.acp_detail_labels.from_date',
            PROCESSING_RESULT: 'customerCareModule.transaction_details.acp_detail_labels.processing_result',
            REFERENCE: 'customerCareModule.transaction_details.acp_detail_labels.reference',
            RETURN_CODE: 'customerCareModule.transaction_details.acp_detail_labels.return_code',
            THRU_DATE: 'customerCareModule.transaction_details.acp_detail_labels.thru_date',
            TRANSACTION_CODE: 'customerCareModule.transaction_details.acp_detail_labels.transaction_code',
            TRANSACTION_RESULT: 'customerCareModule.transaction_details.acp_detail_labels.transaction_result'
        },
        BRAINTREE_METHOD_DETAIL_LABELS: {
            AVS_POSTAL_RESPONSE_CODE: 'customerCareModule.transaction_details.braintree_method_detail_labels.avs_postal_response_code',
            AVS_RESPONSE_CODE: 'customerCareModule.transaction_details.braintree_method_detail_labels.avs_response_code',
            AVS_STREET_RESPONSE_CODE: 'customerCareModule.transaction_details.braintree_method_detail_labels.avs_street_response_code',
            BRAINTREE_TRANSACTION_ID: 'customerCareModule.transaction_details.braintree_method_detail_labels.braintree_transaction_id',
            CVV_RESPONSE_CODE: 'customerCareModule.transaction_details.braintree_method_detail_labels.cvv_response_code',
            GATEWAY_RESPONSE_CODE: 'customerCareModule.transaction_details.braintree_method_detail_labels.gateway_response_code',
            MERCHANT_ACCOUNT_ID: 'customerCareModule.transaction_details.braintree_method_detail_labels.merchant_account_id',
            PROCESSOR_RESPONSE_CODE: 'customerCareModule.transaction_details.braintree_method_detail_labels.processor_response_code',
            REFUND_IDS: 'customerCareModule.transaction_details.braintree_method_detail_labels.refund_ids'
        },
        DIRECT_DEBIT_DETAIL_LABELS: {
            GATEWAY_RESULT: 'customerCareModule.transaction_details.direct_debit_detail_labels.gateway_result',
            RECONCILIATION_ID: 'customerCareModule.transaction_details.direct_debit_detail_labels.reconciliation_id',
            PROCESSOR_RESPONSE: 'customerCareModule.transaction_details.direct_debit_detail_labels.processor_response',
            REQUEST_ID: 'customerCareModule.transaction_details.direct_debit_detail_labels.request_id',
            REQUESTED: 'customerCareModule.transaction_details.direct_debit_detail_labels.requested',
            REQUEST_TOKEN: 'customerCareModule.transaction_details.direct_debit_detail_labels.request_token',
        },
        EXTERNAL_BILLER_DETAIL_LABELS: {
            EXTERNAL_REFERENCE_ID: 'customerCareModule.transaction_details.external_biller_detail_labels.external_reference_id',
            REQUESTED: 'customerCareModule.transaction_details.external_biller_detail_labels.requested',
            REQUEST_ID: 'customerCareModule.transaction_details.external_biller_detail_labels.request_id',
            RESPONSE_CODE: 'customerCareModule.transaction_details.external_biller_detail_labels.response_code',
            TRANSACTION_DATA: 'customerCareModule.transaction_details.external_biller_detail_labels.transaction_data',
        },
        EXTERNAL_DEPOSIT: 'customerCareModule.transaction_details.external_deposit',
        PAYPAL_DETAIL_LABELS: {
            PAYPAL_ERROR_CODE: 'customerCareModule.transaction_details.paypal_detail_labels.paypal_error_code',
            PAYMENT_TYPE: 'customerCareModule.transaction_details.paypal_detail_labels.payment_type',
            PAYPAL_TOKEN: 'customerCareModule.transaction_details.paypal_detail_labels.paypal_token',
            PAYPAL_TRANSACTION_ID: 'customerCareModule.transaction_details.paypal_detail_labels.paypal_transaction_id',
            TARGET_TRANSACTION_ITEM_ID: 'customerCareModule.transaction_details.paypal_detail_labels.paypal_transaction_item_id',
            PAYMENT_PENDING_REASON: 'customerCareModule.transaction_details.paypal_detail_labels.paypal_pending_reason',
            SENDER_TRANSACTION_ID: 'customerCareModule.transaction_details.paypal_detail_labels.sender_transaction_id',
            PAYPAL_CORRELATION_ID: 'customerCareModule.transaction_details.paypal_detail_labels.paypal_correlation_id'
        },
        POS_DETAIL_LABELS: {
            AUTHORIZATION_CODE: 'customerCareModule.transaction_type.authorization_code',
            DEVICE_DESCRIPTION: 'customerCareModule.transaction_details.pos_detail_labels.device_description',
            PAYMENT_EFFECTIVE_DATE: 'customerCareModule.transaction_details.pos_detail_labels.payment_effective_date',
            PAYMENT_FREQUENCY_INDICATOR: 'customerCareModule.transaction_details.pos_detail_labels.payment_frequency_indicator',
            PAYMENT_PROFILE: 'customerCareModule.transaction_details.pos_detail_labels.payment_profile',
            PAYMENT_PROVIDER: 'customerCareModule.transaction_details.pos_detail_labels.payment_provider',
            PAYMENT_REFERENCE_CODE: 'customerCareModule.transaction_details.pos_detail_labels.payment_reference_code',
            RECEIPT_NUMBER: 'customerCareModule.transaction_details.pos_detail_labels.receipt_number',
            REFERENCE_CODE: 'customerCareModule.transaction_details.pos_detail_labels.reference_code',
            SERVICE_IDENTIFIER: 'customerCareModule.transaction_details.pos_detail_labels.service_identifier',
            SUB_TYPE: 'customerCareModule.type',
            TRANSACTION_DETAILS: 'customerCareModule.transaction_details.pos_detail_labels.transaction_details'
        },
        ROKU_DETAIL_LABELS: {
            CHANNEL_ID: 'customerCareModule.transaction_details.roku_detail_labels.channel_id',
            CHANNEL_NAME: 'customerCareModule.transaction_details.roku_detail_labels.channel_name',
            CUSTOMER_ID: 'customerCareModule.transaction_details.roku_detail_labels.customer_id',
            EXPIRATION_DATE: 'customerCareModule.transaction_details.roku_detail_labels.expiration_date',
            ORIGINAL_PURCHASE_DATE: 'customerCareModule.transaction_details.roku_detail_labels.original_purchase_date',
            PRODUCT_ID: 'customerCareModule.transaction_details.roku_detail_labels.product_id',
            PRODUCT_NAME: 'customerCareModule.transaction_details.roku_detail_labels.product_name',
            PURCHASE_DATE: 'customerCareModule.transaction_details.roku_detail_labels.purchase_date',
            REFUND_ID: 'customerCareModule.transaction_details.roku_detail_labels.refund_id',
            RESULT_CODE: 'customerCareModule.transaction_details.roku_detail_labels.result_code',
            TRANSACTION_ID: 'customerCareModule.transaction_details.roku_detail_labels.transaction_id',
            USERNAME: 'customerCareModule.transaction_details.roku_detail_labels.username'
        },
        STRIPE_DETAIL_LABELS: {
            RESULT_CODE: 'customerCareModule.transaction_details.stripe_detail_labels.result_code',
            TRANSACTION_DATE: 'customerCareModule.transaction_details.stripe_detail_labels.transaction_date',
            TRANSACTION_ID: 'customerCareModule.transaction_details.stripe_detail_labels.transaction_id',
            TRANSACTION_TYPE: 'customerCareModule.transaction_details.stripe_detail_labels.transaction_type'
        },
        SVA_DETAIL_LABELS: {
            BALANCE_ADJUSTMENT_TITLE: 'customerCareModule.transaction_details.sva_detail_labels.balance_adjustment_title',
            BALANCE_ADJUSTMENT_REASON: 'customerCareModule.transaction_details.sva_detail_labels.balance_adjustment_reason',
            BALANCE_ADJUSTMENT_REASON_NAME: 'customerCareModule.transaction_details.sva_detail_labels.balance_adjustment_reason_name',
            CHARGE_TITLE: 'customerCareModule.transaction_details.sva_detail_labels.charge_title',
            CHARGE_AMOUNT: 'customerCareModule.transaction_details.sva_detail_labels.charge_amount',
            CHARGE_CURRENCY: 'customerCareModule.transaction_details.sva_detail_labels.charge_currency',
            CHARGE_PAYMENT_INSTRUMENT_ID: 'customerCareModule.transaction_details.sva_detail_labels.charge_payment_instrument_id',
            LOYALTY_CARD_TRANSACTION: 'customerCareModule.transaction_details.sva_detail_labels.loyalty_card_transaction',
            LOYALTY_EVENT_TITLE: 'customerCareModule.transaction_details.sva_detail_labels.loyalty_event_title',
            LOYALTY_EVENT_NAME: 'customerCareModule.transaction_details.sva_detail_labels.loyalty_event_name',
            LOYALTY_EVENT_PROPERTY: 'customerCareModule.transaction_details.sva_detail_labels.loyalty_event_property',
            LOYALTY_EVENT_SUBSCRIBER_ID: 'customerCareModule.transaction_details.sva_detail_labels.loyalty_event_subscriber_id',
            LOYALTY_EVENT_TYPE: 'customerCareModule.transaction_details.sva_detail_labels.loyalty_event_type',
        },
        TRANSACTION_ITEM_TYPE: {
            AUTHORIZATION: 'customerCareModule.transaction_details.transaction_item_type.authorization',
            AUTHORIZATION_REVERSAL: 'customerCareModule.transaction_details.transaction_item_type.authorization_reversal',
            CAPTURE: 'customerCareModule.transaction_details.transaction_item_type.capture',
            CREDIT: 'customerCareModule.transaction_details.transaction_item_type.credit',
            DEPOSIT: 'customerCareModule.transaction_details.transaction_item_type.deposit',
            NO_PROCESSING: 'customerCareModule.transaction_details.transaction_item_type.no_processing',
            SALE: 'customerCareModule.transaction_details.transaction_item_type.sale',
            VOIDED_CREDIT: 'customerCareModule.transaction_details.transaction_item_type.voided_credit',
            VOIDED_DEPOSIT: 'customerCareModule.transaction_details.transaction_item_type.voided_deposit',
            VOIDED_SALE: 'customerCareModule.transaction_details.transaction_item_type.voided_sale',
            WRITE_OFF: 'customerCareModule.transaction_details.transaction_item_type.write_off',
            WRITE_OFF_REVERSAL: 'customerCareModule.transaction_details.transaction_item_type.write_off_reversal'
        },
        UWP_DETAIL_LABELS: {
            OFFER_ID: 'customerCareModule.transaction_details.uwp_detail_labels.offer_id',
            TRANSACTION_ID: 'customerCareModule.transaction_details.uwp_detail_labels.transaction_id'
        },
        XBOX_DETAIL_LABELS: {
            ASSERTION_DATE: 'customerCareModule.transaction_details.xbox_detail_labels.assertion_date',
            ASSERTION_ID: 'customerCareModule.transaction_details.xbox_detail_labels.assertion_id',
        }
    },
    TRANSACTIONS: {
        COLUMNS: {
            AMOUNT: 'customerCareModule.transactions.columns.amount',
            DATE: 'customerCareModule.transactions.columns.date',
            DESCRIPTION: 'customerCareModule.transactions.columns.description',
            DISCOUNT: 'customerCareModule.transactions.columns.discount',
            PAYMENT: 'customerCareModule.transactions.columns.payment',
            RESULT: 'customerCareModule.transactions.columns.result',
            TRANSACTION_ID: 'customerCareModule.transactions.columns.transaction_id',
            TYPE: 'customerCareModule.transactions.columns.type'
        },
        FILTERS: {
            ALL: 'customerCareModule.transactions.filters.all',
            BRANDABLE_CURRENCY: 'customerCareModule.transactions.filters.brandable_currency',
            CURRENCY: 'customerCareModule.transactions.filters.currency',
            PAGER_DISPLAY: 'customerCareModule.products_search.pager_display',
            PAYMENT_METHOD: 'customerCareModule.transactions.filters.payment_method',
            TYPE: 'customerCareModule.transactions.filters.type'
        },
        NO_RESULTS: 'customerCareModule.transactions.no_results',
        NO_TRANSACTIONS: 'customerCareModule.transactions.no_transactions',
        NO_TRANSACTIONS_SUB: 'customerCareModule.transactions.no_transactions_sub',
        RETURN_TO: 'customerCareModule.transactions.return_to',
        TITLE: 'customerCareModule.transactions.title'
    },
    TRANSACTION_TYPE: {
        AMOUNT: 'customerCareModule.transaction_type.amount',
        AUTHORIZATION_CODE: 'customerCareModule.transaction_type.authorization_code',
        AUTHORIZATION_DATE: 'customerCareModule.transaction_type.authorization_date',
        AVS: 'customerCareModule.transaction_type.avs',
        AVS_RAW: 'customerCareModule.transaction_type.avs_raw',
        CV: 'customerCareModule.transaction_type.cv',
        CV_RAW: 'customerCareModule.transaction_type.cv_raw',
        EXTERNAL_REFERENCE_ID: 'customerCareModule.transaction_type.external_reference_id',
        GATEWAY_RESULT_CODE: 'customerCareModule.transaction_type.gateway_result_code',
        PAYMENT: 'customerCareModule.transaction_type.payment',
        PR_CODE: 'customerCareModule.transaction_type.pr_code',
        RECONCILIATION_ID: 'customerCareModule.transaction_type.reconciliation_id',
        REQUEST_DATE: 'customerCareModule.transaction_type.request_date',
        REQUEST_ID: 'customerCareModule.transaction_type.request_id',
        REQUEST_TOKEN: 'customerCareModule.transaction_type.request_token',
        RESPONSE_CODE: 'customerCareModule.transaction_type.response_code',
        RESULT_CODE: 'customerCareModule.transaction_type.result_code',
        STORE_ID: 'customerCareModule.transaction_type.store_id',
        TRANSACTION_DATA: 'customerCareModule.transaction_type.transaction_data',
        TRANSACTION_RESULT: 'customerCareModule.transaction_type.transaction_result',
        TRANSACTION_RESULT_ECHECK: 'customerCareModule.transaction_type.transaction_result_echeck',
        TRANSACTION_RESULT_EXTERNAL_BILL: 'customerCareModule.transaction_type.transaction_result_external_bill',
        TRANSACTION_RESULT_GOOGLE_PLAY: 'customerCareModule.transaction_type.transaction_result_google_play',
        TRANSACTION_RESULT_ITUNES: 'customerCareModule.transaction_type.transaction_result_itunes',
        TRANSACTION_RESULT_ROKU: 'customerCareModule.transaction_type.transaction_result_roku',
        TRANSACTION_RESULT_STRIPE: 'customerCareModule.transaction_type.transaction_result_stripe'
    },
    TRANSFER: {
        CANCEL_TRANSFER: 'customerCareModule.transfer.cancel_transfer',
        CONFIG_STEP: {
            CALL_TO_ACTION_LABEL: 'customerCareModule.transfer.config_step.call_to_action_label',
            CALL_TO_ACTION_LINK: 'customerCareModule.transfer.config_step.call_to_action_link',
            CUSTOMER_TO_RECEIVE_SERVICES: 'customerCareModule.transfer.config_step.customer_to_receive_services',
            SEARCH_CUSTOMER_DIALOG: {
                ANONYMIZED_DISABLED_TOOLTIP: 'customerCareModule.transfer.config_step.search_customer_dialog.anonymized_disabled_tooltip',
                BLOCKLISTED_DISABLED_TOOLTIP: 'customerCareModule.transfer.config_step.search_customer_dialog.blocklisted_disabled_tooltip',
                PROSPECT_LITE_DISABLED_TOOLTIP: 'customerCareModule.transfer.config_step.search_customer_dialog.prospect_lite_disabled_tooltip',
                TITLE: 'customerCareModule.transfer.config_step.search_customer_dialog.title',
                TRANSFER_TO: 'customerCareModule.transfer.config_step.search_customer_dialog.transfer_to'
            },
            SERVICES_TO_BE_TRANSFERRED: 'customerCareModule.transfer.config_step.services_to_be_transferred'
        },
        FROM_CUSTOMER_NAME: 'customerCareModule.transfer.from_customer_name',
        NO_CUSTOMER_SELECTED: 'customerCareModule.transfer.no_customer_selected',
        NO_SERVICES_AND_CUSTOMER_SELECTED: 'customerCareModule.transfer.no_services_and_customer_selected',
        NO_SERVICES_SELECTED: 'customerCareModule.transfer.no_services_selected',
        NON_TRANSFERABLE_REASON: {
            BULK: 'customerCareModule.transfer.non_transferable_reason.bulk',
            CONTRACT: 'customerCareModule.transfer.non_transferable_reason.contract',
            DEFAULT: 'customerCareModule.transfer.non_transferable_reason.default',
            OPEN_MOBILE_NUMBER_PORT: 'customerCareModule.transfer.non_transferable_reason.open_mobile_number_port',
            OPEN_ORDER: 'customerCareModule.transfer.non_transferable_reason.open_order',
            PREPAID: 'customerCareModule.transfer.non_transferable_reason.prepaid'
        },
        START_TRANSFER: 'customerCareModule.transfer.start_transfer',
        SUBMIT_TRANSFER_ORDER: {
            COMPLETE_TRANSFER: 'customerCareModule.transfer.submit_transfer_order.complete_transfer',
            MESSAGE: 'customerCareModule.transfer.submit_transfer_order.message',
            TITLE: 'customerCareModule.transfer.submit_transfer_order.title'
        },
        TO_CUSTOMER_NAME: 'customerCareModule.transfer.to_customer_name',
        TRANSFER_SERVICES: 'customerCareModule.transfer.transfer_services',
        TRANSFER_SERVICES_TO: 'customerCareModule.transfer.transfer_services_to',
        TRANSFER_SERVICES_ORDER_INSUFFICIENT_ACCESS_TITLE: 'customerCareModule.transfer.transfer_services_order_insufficient_access_title'
    },
    CHANGE_OFFER: {
        CANCEL_CHANGE: 'customerCareModule.change_offer.cancel_change',
        CHANGE_CONTEXT_FAILURE: 'customerCareModule.change_offer.change_context_failure',
        CHANGE_OFFER: 'customerCareModule.change_offer.change_offer',
        CHANGE_OFFER_SUCCESS: 'customerCareModule.change_offer.change_offer_success',
        EXISTING_OFFER: 'customerCareModule.change_offer.existing_offer',
        EXISTING_OFFERS: 'customerCareModule.change_offer.existing_offers',
        NEW_OFFER: 'customerCareModule.change_offer.new_offer',
        SHOW_DIFFERENCES: 'customerCareModule.change_offer.show_differences'
    },
    TREATMENT_DETAILS: {
        AMOUNT_PAID: 'customerCareModule.treatment_details.amount_paid',
        CHANGE_STEP: {
            CHANGE_STEP: 'customerCareModule.treatment_details.change_step.change_step',
            DE_ESCALATE: 'customerCareModule.treatment_details.change_step.de_escalate',
            DEESCALATION_INFO: 'customerCareModule.treatment_details.change_step.deescalation_info',
            ESCALATE: 'customerCareModule.treatment_details.change_step.escalate',
            ERRORS: {
                FORM_API_SUBMISSION_FAILED: 'customerCareModule.treatment_details.change_step.errors.form_api_submission_failed'
            },
            SUCCESS_UPDATE: 'customerCareModule.treatment_details.change_step.success_update',
            UPDATE: 'customerCareModule.treatment_details.change_step.update'
        },
        CLOSE: 'customerCareModule.treatment_details.close',
        COMPLETION_DATE: 'customerCareModule.treatment_details.completion_date',
        CREATED_ON: 'customerCareModule.treatment_details.created_on',
        CREATE_PROMISE_TO_PAY: {
            ESTIMATED_INSTALLMENT_AMOUNT: 'customerCareModule.treatment_details.create_promise_to_pay.estimated_installment_amount',
            INSTALLMENT_AMOUNT_PLACEHOLDER: 'customerCareModule.treatment_details.create_promise_to_pay.installment_amount_placeholder',
            INSTALLMENTS_LABEL: 'customerCareModule.treatment_details.create_promise_to_pay.installments_label',
            PERIOD_LABEL: 'customerCareModule.treatment_details.create_promise_to_pay.period_label',
            PERIOD_SELECT_PLACEHOLDER: 'customerCareModule.treatment_details.create_promise_to_pay.period_select_placeholder',
            SAVE: 'customerCareModule.treatment_details.create_promise_to_pay.save',
            START_DATE: 'customerCareModule.treatment_details.create_promise_to_pay.start_date',
            SUCCESS: 'customerCareModule.treatment_details.create_promise_to_pay.success',
            ERRORS: {
                FORM_API_SUBMISSION_FAILED: 'customerCareModule.treatment_details.create_promise_to_pay.errors.form_api_submission_failed',
                FORM_API_SUBMISSION_FAILED_UNKNOWN_REASON: 'customerCareModule.treatment_details.create_promise_to_pay.errors.form_api_submission_failed_unknown_reason',
                INSTALLMENTS_MIN: 'customerCareModule.treatment_details.create_promise_to_pay.errors.installments_min',
                INSTALLMENTS_REQUIRED: 'customerCareModule.treatment_details.create_promise_to_pay.errors.installments_required',
                PERIOD_REQUIRED: 'customerCareModule.treatment_details.create_promise_to_pay.errors.period_required',
                START_DATE_INVALID: 'customerCareModule.treatment_details.create_promise_to_pay.errors.start_date_invalid',
                START_DATE_IN_PAST: 'customerCareModule.treatment_details.create_promise_to_pay.errors.start_date_in_past',
                START_DATE_REQUIRED: 'customerCareModule.treatment_details.create_promise_to_pay.errors.start_date_required'
            }
        },
        CREDIT_CLASS: 'customerCareModule.treatment_details.credit_class',
        CUSTOMER_TYPE: 'customerCareModule.treatment_details.customer_type',
        CURRENT_STEP: 'customerCareModule.treatment_details.current_step',
        DELINQUENT_BALANCE: 'customerCareModule.treatment_details.delinquent_balance',
        HEADING: 'customerCareModule.treatment_details.heading',
        INSTALLMENT_CHARGE: 'customerCareModule.treatment_details.installment_charge',
        INSTALLMENT_STARTED: 'customerCareModule.treatment_details.installment_started',
        INSTALLMENT_PAID: 'customerCareModule.treatment_details.installment_paid',
        NA: 'customerCareModule.treatment_details.na',
        NEXT_INSTALLMENT_DUE: 'customerCareModule.treatment_details.next_installment_due',
        NEXT_STEP_DATE: 'customerCareModule.treatment_details.next_step_date',
        NOT_IN_TREATMENT: 'customerCareModule.treatment_details.not_in_treatment',
        OF: 'customerCareModule.treatment_details.of',
        PENDING: 'customerCareModule.treatment_details.pending',
        PERIOD_TYPE: 'customerCareModule.treatment_details.period_type',
        PROMISE_TO_PAY: 'customerCareModule.treatment_details.promise_to_pay',
        PROMISE_TO_PAY_SCHEDULE: {
            PROMISE_TO_PAY_SCHEDULE: 'customerCareModule.treatment_details.promise_to_pay_schedule.promise_to_pay_schedule',
            TABLE_HEADERS: {
                AMOUNT: 'customerCareModule.treatment_details.promise_to_pay_schedule.table_headers.amount',
                DUE_DATE: 'customerCareModule.treatment_details.promise_to_pay_schedule.table_headers.due_date',
                INSTALLMENT: 'customerCareModule.treatment_details.promise_to_pay_schedule.table_headers.installment',
                PAID: 'customerCareModule.treatment_details.promise_to_pay_schedule.table_headers.paid',
                START_DATE: 'customerCareModule.treatment_details.promise_to_pay_schedule.table_headers.start_date'
            }
        },
        PROMISE_TO_PAY_STATUS: {
            BROKEN: 'customerCareModule.treatment_details.promise_to_pay_status.broken',
            COMPLETED: 'customerCareModule.treatment_details.promise_to_pay_status.completed',
            OPEN: 'customerCareModule.treatment_details.promise_to_pay_status.open'
        },
        SET_UP_PROMISE_TO_PAY: 'customerCareModule.treatment_details.set_up_promise_to_pay',
        STATUS: 'customerCareModule.treatment_details.status',
        STEP: 'customerCareModule.treatment_details.step',
        TREATMENT_BALANCE: 'customerCareModule.treatment_details.treatment_balance',
        TREATMENT_ENTRY_DATE: 'customerCareModule.treatment_details.treatment_entry_date',
        TREATMENT_REASON: 'customerCareModule.treatment_details.treatment_reason',
        VIEW_PROMISE_TO_PAY_SCHEDULE: 'customerCareModule.treatment_details.view_promise_to_pay_schedule'
    },
    SECONDS: 'customerCareModule.seconds',
    SUPPRESS_LATE_PAYMENT_FEES: 'customerCareModule.suppress_late_payment_fees',
    TROUBLE_CALL: 'customerCareModule.trouble_call',
    TYPE: 'customerCareModule.type',
    TYPES: 'customerCareModule.types',
    UNIT_PRICE: 'customerCareModule.unit_price',
    UNIT_PRICE_PER_INVOICE: 'customerCareModule.unit_price_per_invoice',
    UNKNOWN_USER: 'customerCareModule.unknown_user',
    UNLIMITED: 'customerCareModule.unlimited',
    UNSAVED_CHANGES_PROMPT: {
        CONFIRM: 'unsavedChangesPrompt.confirm',
        DENY: 'unsavedChangesPrompt.deny',
        MESSAGE: 'unsavedChangesPrompt.message',
        TITLE: 'unsavedChangesPrompt.title'
    },
    UNTIL: 'customerCareModule.until',
    UPCOMING_INVOICE: 'customerCareModule.upcoming_invoice',
    UPCOMING_INVOICE_EXPIRED: 'customerCareModule.upcoming_invoice_expired',
    UPCOMING_INVOICES: 'customerCareModule.upcoming_invoices',
    UPCOMING_INVOICES_LOAD_DATA_DIALOGUE: 'customerCareModule.upcoming_invoices_load_data_dialogue',
    UPCOMING_INVOICES_REFRESH_TIMER_DIALOGUE: 'customerCareModule.upcoming_invoices_refresh_timer_dialogue',
    UPDATE: CoreLocaleKeys.UPDATE,
    UPDATE_CASE: 'customerCareModule.update_case',
    UPDATE_SERVICE_LIFECYCLE_STATUS: 'customerCareModule.update_service_lifecycle_status',
    UPDATE_RESPONSIBLE_PARTY: 'customerCareModule.update_responsible_party',
    USAGE: 'customerCareModule.usage',
    USAGE_CAP_SETTINGS: 'customerCareModule.usage_cap_settings',
    USAGE_CAPS: {
        ACTIVATE: 'customerCareModule.usage_caps.activate',
        ACCOUNT_LEVEL: 'customerCareModule.usage_caps.account_level',
        ALL_USAGE_CAP_SETTINGS: 'customerCareModule.usage_caps.all_usage_cap_settings',
        BREACHED_CAPS: {
            CAPPED_ACCOUNT: 'customerCareModule.usage_caps.breached_caps.capped_account',
            CAPPED_SERVICES_LIST: 'customerCareModule.usage_caps.breached_caps.capped_services_list',
            CAPPED_SERVICES_SEVERAL: 'customerCareModule.usage_caps.breached_caps.capped_services_several',
            RESET_DATE: 'customerCareModule.usage_caps.breached_caps.reset_date',
            RESET_DATE_ACCOUNT: 'customerCareModule.usage_caps.breached_caps.reset_date_account',
            RESET_DATE_TODAY: 'customerCareModule.usage_caps.breached_caps.reset_date_today',
            RESET_DATE_TODAY_ACCOUNT: 'customerCareModule.usage_caps.breached_caps.reset_date_today_account',
            RESET_MESSAGE: 'customerCareModule.usage_caps.breached_caps.reset_message'
        },
        DISCOUNT_TO_ZERO: 'customerCareModule.usage_caps.discount_to_zero',
        EXPIRES: 'customerCareModule.usage_caps.expires',
        NOTIFICATIONS: 'customerCareModule.usage_caps.notifications',
        NOTIFICATION_TOOLTIP_BY_INCREMENTAL_VALUE_LIMITED_RESETS: 'customerCareModule.usage_caps.notification_tooltip_by_incremental_value_limited_resets',
        NOTIFICATION_TOOLTIP_BY_INCREMENTAL_VALUE_UNLIMITED_RESETS: 'customerCareModule.usage_caps.notification_tooltip_by_incremental_value_unlimited_resets',
        NOTIFICATION_TOOLTIP_PERCENTAGE: 'customerCareModule.usage_caps.notification_tooltip_percentage',
        PLAN_USGE_CAPS: 'customerCareModule.usage_caps.plan_usge_caps',
        REMAINING: 'customerCareModule.usage_caps.remaining',
        RESTRICT_USAGE: 'customerCareModule.usage_caps.restrict_usage',
        SERVICE_LEVEL: 'customerCareModule.usage_caps.service_level',
        SINGLE_SERVICE_TITLE: 'customerCareModule.usage_caps.single_service_title',
        UPDATE_SUCCESS: 'customerCareModule.usage_caps.update_success',
        USAGE_CONTROLS_LABEL: 'customerCareModule.usage_caps.usage_controls_label',
        USAGE_CAP: 'customerCareModule.usage_caps.usage_cap',
        USAGE_CAP_INDICATOR: {
            BREACHED: 'customerCareModule.usage_caps.usage_cap_indicator.breached',
            MESSAGE_SEPARATOR: 'customerCareModule.usage_caps.usage_cap_indicator.message_separator',
            MULTIPLE: 'customerCareModule.usage_caps.usage_cap_indicator.multiple',
            NONE: 'customerCareModule.usage_caps.usage_cap_indicator.none',
            SINGLE: 'customerCareModule.usage_caps.usage_cap_indicator.single',
            USAGE_ALLOWED_DISCOUNT_ZERO: 'customerCareModule.usage_caps.usage_cap_indicator.usage_allowed_discount_zero',
            USAGE_RESTRICTED: 'customerCareModule.usage_caps.usage_cap_indicator.usage_restricted'
        },
        USAGE_CAP_WARNING: 'customerCareModule.usage_caps.usage_cap_warning',
        USAGE_NOTIFICATIONS:'customerCareModule.usage_caps.usage_notifications'
    },
    USAGE_CHARGES_AND_CONTROLS: 'customerCareModule.usage_charges_and_controls',
    USAGE_DETAILS: {
        ACTION_TYPE: {
            ACTION_TYPE_TITLE: 'customerCareModule.usage_details.action_type.action_type_title',
            AGGREGATED: 'customerCareModule.usage_details.action_type.aggregated',
            ONLINE_RATED: 'customerCareModule.usage_details.action_type.online_rated',
            RATED: 'customerCareModule.usage_details.action_type.rated',
            REPROCESSED: 'customerCareModule.usage_details.action_type.reprocessed',
            REVOKED: 'customerCareModule.usage_details.action_type.revoked'
        },
        DISCLAIMER_MESSAGE: 'customerCareModule.usage_details.disclaimer_message',
        DONE: 'customerCareModule.usage_details.done',
        EXTERNAL_TAG: 'customerCareModule.usage_details.external_tag',
        INCLUDE_EXPIRED_ENTITLEMENTS: 'customerCareModule.usage_details.include_expired_entitlements',
        LOCATION_OVERRIDES: 'customerCareModule.usage_details.location_overrides',
        POPUP_CONTENT: {
            ACTUAL_EVENT_DATE_TIME: 'customerCareModule.usage_details.popup_content.actual_event_date_time',
            ACTUAL_EVENT_DATE_TIME_TOOLTIP: 'customerCareModule.usage_details.popup_content.actual_event_date_time_tooltip',
            NO_FINANCIAL_IMPLICATION_TOOLTIP: 'customerCareModule.usage_details.popup_content.no_financial_implication_tooltip',
            SUBTYPE: 'customerCareModule.usage_details.popup_content.subtype'
        },
        REMAINING: 'customerCareModule.usage_details.remaining',
        SEARCH_ENTITLEMENT_NAME: 'customerCareModule.usage_details.search_entitlement_name',
        SHOW_ALL: 'customerCareModule.usage_details.show_all',
        TABLE_CONTENT: {
            BILLED_TRUE: 'customerCareModule.usage_details.table_content.billed_true',
            BILLED_FALSE: 'customerCareModule.usage_details.table_content.billed_false',
            CALL_FORWARDING: 'customerCareModule.usage_details.table_content.call_forwarding',
            DIRECTION_INCOMING: 'customerCareModule.usage_details.table_content.direction_incoming',
            DIRECTION_OUTGOING: 'customerCareModule.usage_details.table_content.direction_outgoing',
            UNKNOWN_NETWORK_EVENT: 'customerCareModule.usage_details.table_content.unknown_network_event'
        },
        TABLE_HEADERS: {
            BILLED: 'customerCareModule.usage_details.table_headers.billed',
            CALL_CATEGORY: 'customerCareModule.usage_details.table_headers.call_category',
            CHARGE: 'customerCareModule.usage_details.table_headers.charge',
            DATE_TIME: 'customerCareModule.usage_details.table_headers.date_time',
            DIRECTION: 'customerCareModule.usage_details.table_headers.direction',
            ENTITLEMENT_NAME: 'customerCareModule.usage_details.table_headers.entitlement_name',
            EVENT_SOURCE: 'customerCareModule.usage_details.table_headers.event_source',
            FROM: 'customerCareModule.usage_details.table_headers.from',
            PROCESS_DATE : 'customerCareModule.usage_details.table_headers.process_date',
            SUB_TYPE: 'customerCareModule.usage_details.table_headers.subType',
            TO: 'customerCareModule.usage_details.table_headers.to',
            TYPE: 'customerCareModule.usage_details.table_headers.type',
            UNIT: 'customerCareModule.usage_details.table_headers.unit',
            USAGE_ACTUAL: 'customerCareModule.usage_details.table_headers.usage_actual',
            USAGE_BILLED: 'customerCareModule.usage_details.table_headers.usage_billed',
            USAGE: 'customerCareModule.usage_details.table_headers.usage'
        },
        TOOLTIP_CONTENT: 'customerCareModule.usage_details.tooltip_content',
        TOTAL: 'customerCareModule.usage_details.total',
        TOTAL_CHARGES: 'customerCareModule.usage_details.total_charges',
        USAGE: 'customerCareModule.usage_details.usage',
        USAGES_LIMIT_EXCEEDED_WARNING: 'customerCareModule.usage_details.usages_limit_exceeded_warning'
    },
    USED: 'customerCareModule.used',
    USED_IN_HIERARCHY: 'customerCareModule.used_in_hierarchy',
    USER: 'customerCareModule.user',
    USERNAME: 'customerCareModule.username',
    UTC: 'customerCareModule.utc',
    VALIDATE_PIN: 'customerCareModule.validate_pin',
    VALIDATE: 'customerCareModule.validate',
    VALUE: 'customerCareModule.value',
    VIEW_CONTENT_ACTIVITY: 'customerCareModule.view_content_activity',
    VIEW_DEPOSITS: 'customerCareModule.view_deposits',
    VIEW_DETAILS: 'customerCareModule.view_details',
    VIEW_FULL_CART_POPUP_TITLE: 'customerCareModule.view_full_cart_popup_title',
    VIEW_FULL_CART: 'customerCareModule.view_full_cart',
    VIEW_MORE: 'customerCareModule.view_more',
    VIEW_OPTIONS: 'customerCareModule.view_options',
    VIEW_SERVICE_DETAILS: 'customerCareModule.view_service_details',
    VIEW_UPCOMING_INVOICES: 'customerCareModule.view_upcoming_invoices',
    VIEW: 'customerCareModule.view',
    VISIBILITY: 'customerCareModule.visibility',
    WHOLESALE_FLAG: 'customerCareModule.wholesaleFlag',
    WIZARD: {
        VALIDATIONS: {
            AT_LEAST_ONE_PRICING_PLAN_REQUIRED: 'customerCareModule.wizard.validations.atLeaseOnePricingPlanRequired',
            DO_PAYMENT_INSTRUMENTS_COVER_ORDER_TOTAL: 'customerCareModule.wizard.validations.doPaymentInstrumentsCoverOrderTotal',
            IS_PAYMENT_INSTRUMENT_EXPIRED: 'customerCareModule.wizard.validations.isPaymentInstrumentExpired',
            IS_PAYMENT_INSTRUMENT_INACTIVE: 'customerCareModule.wizard.validations.isPaymentInstrumentInactive',
            IS_PAYMENT_INSTRUMENT_SELECTED: 'customerCareModule.wizard.validations.isPaymentInstrumentSelected',
            IS_SHIPPING_ADDRESS_SELECTED: 'customerCareModule.wizard.validations.isShippingAddressSelected'
        },
        STEPS: {
            ATTRIBUTES: 'customerCareModule.wizard.steps.attributes',
            CHECKOUT: 'customerCareModule.wizard.steps.checkout',
            COMPARE_OFFERS: 'customerCareModule.wizard.steps.compare_offers',
            CUSTOMER: 'customerCareModule.wizard.steps.customer',
            DECISIONS: 'customerCareModule.wizard.steps.decisions',
            ELIGIBILITY: 'customerCareModule.wizard.steps.eligibility',
            OFFERS: 'customerCareModule.wizard.steps.offers',
            PRODUCTS: 'customerCareModule.wizard.steps.products',
            CHOOSE_CUSTOMER_AND_SERVICES: 'customerCareModule.wizard.steps.choose_customer_and_services'
        }
    },
    WORKFLOW: {
        ACTIVITY: {
            IN_PROCESS: 'customerCareModule.workflow.activity.in_process',
        },
        NO_WORKFLOWS_EXIST: 'customerCareModule.workflow.no_workflows_exist',
        WORKFLOW_REQUEST_ERROR: 'customerCareModule.workflow.workflow_request_error'
    },
    WRITE_OFF: {
        AMOUNT: 'customerCareModule.write_off.amount',
        AMOUNT_INVALID: 'customerCareModule.write_off.amount_invalid',
        AMOUNT_REQUIRED: 'customerCareModule.write_off.amount_required',
        AMOUNT_VALUE_INVALID: 'customerCareModule.write_off.amount_value_invalid',
        DESCRIPTION: 'customerCareModule.description',
        MAX_AMOUNT: 'customerCareModule.write_off.max_amount',
        REASON: 'customerCareModule.write_off.reason',
        SUBMIT: 'customerCareModule.write_off.submit',
        TITLE: 'customerCareModule.write_off.title'
    },
    YES: CoreLocaleKeys.YES
};
