import {stateGo} from 'redux-ui-router';
import {
    __,
    filter,
    isNil,
    keys,
    max,
    pathOr,
    range
} from 'ramda';
import {
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    RouteParams,
    RouteState
} from '../../../../reducers/selectors/customer.selectors';
import {
    IsFetchingSingleProductContext,
    ViewLastAttemptErrorSelector
} from './../../../../reducers/selectors/products.order.selectors';
import {
    applySubscriptionCoupon,
    cancelPendingOrder,
    removeSubscriptionDiscount,
    retrievePendingOrder,
    retrieveSubscription,
    setCurrentSubscriptionOfferingDetails,
    setEditSubscriptionItemId,
    setModifyRenewalSubscriptionItem,
    setSelectedDiscount,
    setSubscriptionCouponCode,
    updateSubscription
} from '../../../../reducers/actions/customer.subscriptions.actions';
import {
    getProductMetadataBatch,
    goToCheckoutForRestoreSubscription,
    retrieveProductContext
} from '../../../../reducers/actions/products.order.actions';
import {
    addToAndUpdateShoppingCart,
    reinitializeProductOrder,
} from '../../../../reducers/actions/products.wizard.actions';
import {
    ActiveAddressesWithCurrentSubscriptionAddressSelector,
    BackButtonRouteForSubscriptionDetailsSelector,
    BillingCycleSelector,
    BrandableCurrencyNameSelector,
    CurrencySelector,
    CurrentOfferIdSelector,
    CurrentSubscriptionIdSelector,
    CurrentSubscriptionOfferingDetailsSelector,
    CurrentSubscriptionSelector,
    IsCurrentSubscriptionOfferingPendingSelector,
    IsFetchingDataSelector,
    IsFetchingSubscriptionDetailsSelector,
    IsRemovingDiscountSelector,
    IsRetrievingPendingOrderSelector,
    IsUpdatingSubscriptionSelector,
    IsValidatingCouponSelector,
    OrderNumberSelector,
    OrderIdSelector,
    PendingOrderSelector,
    SelectedDiscountSelector,
    // Commented to use ShippingAddressSelectorForSubscriptions as a workaround
    // Because the API is not updating the ShipToName field PD-485248
    // Should be used again once the endpoint SubscriberManagement/UpdateSubscription gets fixed
    // ShippingAddressSelector,
    ShippingAddressSelectorForSubscriptions,
    ShippingMethodNameSelector,
    ShowShippingInfoSelector,
    SubscriptionCouponCodeSelector,
    SubscriptionDetailMoreOptionsSelector,
    SubscriptionErrorSelector,
    UserCanRemoveDiscountSelector,
    UserCanRenewSelector,
    UserCanRestoreSubscriptionSelector,
    UserCanUpdateSubscriptionSelector
} from '../../../../reducers/selectors/customer.subscriptions.selectors';
import * as SubscriptionConstants from '../../../shared/constants/subscriptions.constants';
import {
    SubscriptionItemsExtensionSelectors,
    SubscriptionProductsMoreOptionsMapSelector,
    SubscriptionWithDetailedItemsSelector
} from '../../../../reducers/selectors/customer.subscriptions.products.selectors';


import CustomerCareLocaleKeys from './../../../../locales/keys';
import {
    retrieveAvailablePaymentInstrumentTypes,
    retrieveWallet,
    selectPaymentMethod
} from '../../../../reducers/actions/customer.ewallet.actions';
import {
    EwalletErrorSelector,
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector
} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {
    isReadOnlyException
} from '../../../../reducers/helpers/ewallet.reducer.helper';
import {CurrentMemberHasCreatePaymentInstrumentPermissionsSelector} from './../../../../reducers/selectors/customer.household.selectors';
import {
    E_WALLET_ADMIN_ACCESS,
    ORDERING_ACCESS
} from '../../../../security.attributes';
import {setLockerFilter, showSubscriptionBanner} from '../../../../reducers/actions/customer.transactions.actions';

import {
    AddressesErrorSelector,
    IsFetchingAddressesSelector
} from '../../../../reducers/selectors/customer.addresses.selectors';
import {retrieveCustomerAddresses} from '../../../../reducers/actions/customer.addresses.actions';
import {
    SUBSCRIPTION_DETAILS_ROUTE
} from '../subscriptions.config';

import {
    setSelectedOfferName,
    startEditOfferFlow
} from '../../../../reducers/actions/offering.order.actions';
import {STATE_NAME as TRANSITION_OFFER} from '../../orders/transition/transition.offer.wizard.constants';
import {DASHBOARD_ROUTE} from '../../../../reducers/constants/dashboard.constants';
import {OFFERING_OPTION_STATUSES} from '../../../shared/constants/offering.option.status.constants';

function SubscriptionDetailController($ngRedux, uiNotificationService, $timeout, $state) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            addressError: AddressesErrorSelector(store),
            backRoute: BackButtonRouteForSubscriptionDetailsSelector(store),
            billingCycleName: BillingCycleSelector(store),
            brandableCurrencyName: BrandableCurrencyNameSelector(store),
            canRemoveDiscount: UserCanRemoveDiscountSelector(store),
            codeTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(__, store),
            couponCode: SubscriptionCouponCodeSelector(store),
            currency: CurrencySelector(store),
            currentAddresses: ActiveAddressesWithCurrentSubscriptionAddressSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentCustomer: CurrentCustomerSelector(store),
            currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
            currentSubscription: CurrentSubscriptionSelector(store),
            currentSubscriptionOfferingDetails: CurrentSubscriptionOfferingDetailsSelector(store),
            discountRemoveReasonsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.DiscountRemoveReason, store),
            discountRemoveReasonOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.DiscountRemoveReason, store),
            eWalletError: EwalletErrorSelector(store),
            isCurrentSubscriptionOfferingPending: IsCurrentSubscriptionOfferingPendingSelector(store),
            isFetchingAddresses: IsFetchingAddressesSelector(store),
            isFetchingData: IsFetchingDataSelector(store),
            isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
            isFetchingSingleProductContext: IsFetchingSingleProductContext(store),
            isFetchingSubscriptionDetailsSelector: IsFetchingSubscriptionDetailsSelector(store),
            isRemovingDiscount: IsRemovingDiscountSelector(store),
            IsRetrievingPendingOrder: IsRetrievingPendingOrderSelector(store),
            isUpdatingSubscription: IsUpdatingSubscriptionSelector(store),
            isValidatingCoupon: IsValidatingCouponSelector(store),
            moreOptionsMenuItems: SubscriptionDetailMoreOptionsSelector(store),
            offerId: CurrentOfferIdSelector(store),
            orderNumber: OrderNumberSelector(store),
            orderId: OrderIdSelector(store),
            pendingOrder: PendingOrderSelector(store),
            paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
            productOrderError: ViewLastAttemptErrorSelector(store),
            routeParams: RouteParams(store),
            routeState: RouteState(store),
            selectedPaymentMethod: SelectedPaymentMethodWithTypeNamesViewModelSelector(store),
            selectedDiscount: SelectedDiscountSelector(store),
            showShippingInfo: ShowShippingInfoSelector(store),
            subscriptionError: SubscriptionErrorSelector(store),
            subscriptionId: CurrentSubscriptionIdSelector(store),
            subscriptionItems: SubscriptionWithDetailedItemsSelector(store),
            subscriptionItemsExtensions: SubscriptionItemsExtensionSelectors(store),
            subscriptionItemsMoreOptionsMap: SubscriptionProductsMoreOptionsMapSelector(store),
            // Commented to use ShippingAddressSelectorForSubscriptions as a workaround
            // Because the API is not updating the ShipToName field PD-485248
            // Should be used again once the endpoint SubscriberManagement/UpdateSubscription gets fixed
            //subscriptionShippingAddress: ShippingAddressSelector(store),
            subscriptionShippingAddress: ShippingAddressSelectorForSubscriptions(store),
            subscriptionShippingMethod: ShippingMethodNameSelector(store),
            userCanRenew: UserCanRenewSelector(store),
            userCanRestoreSubscription: UserCanRestoreSubscriptionSelector(store),
            userCanUpdateSubscription: UserCanUpdateSubscriptionSelector(store),
            userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
        };
    };

    const controllerActions = {
        applySubscriptionCoupon,
        addToAndUpdateShoppingCart,
        cancelPendingOrder,
        fetchCodeType: MetadataActions.codes.fetchCodeTypes,
        getProductMetadataBatch,
        getShippingMethodsByCountryCode: MetadataActions.shippingMethods.getShippingMethodsByCountryCode,
        goToCheckoutForRestoreSubscription,
        reinitializeProductOrder,
        removeSubscriptionDiscount,
        retrieveAvailablePaymentInstrumentTypes,
        retrieveCustomerAddresses,
        retrievePendingOrder,
        retrieveProductContext,
        retrieveSubscription,
        retrieveWallet,
        selectPaymentMethod,
        setCurrentSubscriptionOfferingDetails,
        setEditSubscriptionItemId,
        setLockerFilter,
        setModifyRenewalSubscriptionItem,
        setSelectedDiscount,
        setSelectedOfferName,
        setSubscriptionCouponCode,
        showSubscriptionBanner,
        startEditOfferFlow,
        stateGo,
        updateSubscription
    };

    this.$onInit = () => {
        this.metadataCodes = [
            MetadataConstants.codes.DiscountRemoveReason,
            MetadataConstants.codes.SubscriberProductPricingPlanChangeSource,
            MetadataConstants.codes.SubscriptionBillingRenewalType,
            MetadataConstants.codes.SubscriptionStateIndicator
        ];

        this.uiNotificationService = uiNotificationService;
        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.resetCouponCode();
        this.retrieveAddresses();
        this.retrieveSubscription().then(() => {
            if (this.state.showShippingInfo && pathOr(false, ['subscriptionShippingAddress', 'Country'], this.state)) {
                this.actions.getShippingMethodsByCountryCode(this.state.subscriptionShippingAddress.Country);
            }
        });

        this.localeKeys = CustomerCareLocaleKeys;

        this.manualRenewal = i18n.translate(this.localeKeys.SUBSCRIPTIONS.MANUAL_RENEWAL);
        this.autoRenewal = i18n.translate(this.localeKeys.SUBSCRIPTIONS.AUTOMATIC_RENEWAL);
        this.willNotRenew = i18n.translate(this.localeKeys.SUBSCRIPTIONS.WILL_NOT_RENEW);
        this.stateOrName = SUBSCRIPTION_DETAILS_ROUTE;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        this.metadataCodes.forEach((code) => {
            if (!this.state.codeTypeLoaded(code)) {
                this.actions.fetchCodeType(code);
            }
        });

        this.actions.reinitializeProductOrder();

        this.actions.retrieveAvailablePaymentInstrumentTypes().catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        this.nextStepText = i18n.translate(this.localeKeys.CONTINUE_TO_STEP, {
            step: i18n.translate(this.localeKeys.WIZARD.STEPS.CHECKOUT)
        });

        this.applyDiscountDialogApi = null;
        this.applyDiscountDialogConfig = {
            onRegisterApi: (evt) => {
                this.applyDiscountDialogApi = Object.assign({}, evt.api, {
                    open: () => {
                        this.showDiscountForm = true;

                        $timeout(() => {
                            evt.api.open();
                        });
                    },
                    close: () => {
                        this.showDiscountForm = false;
                        evt.api.close();
                    }
                });
            }
        };

        this.showEditModal = false;

        this.removeDiscountModal = {
            onRegisterApi: (evt) => {
                this.removeDiscountModal = evt.api;
            }
        };

        this.removeExtensionModalConfig = {
            onRegisterApi: (evt) => {
                this.removeExtensionModalApi = evt.api;
            }
        };

        this.toggleModifyRenewalModal = false;
        this.modifyRenewalModal = {
            onRegisterApi: (evt) => {
                this.modifyRenewalModal = evt.api;
            }
        };

        this.cancelChangeModal = {
            onRegisterApi: (evt) => {
                this.cancelChangeModalApi = evt.api;
            }
        };

        this.cancelRemovalModal = {
            onRegisterApi: (evt) => {
                this.cancelRemovalModalApi = evt.api;
            }
        };
    };

    this.onApplyCouponCode = (couponCode) => {
        this.actions.setSubscriptionCouponCode({
            name: couponCode,
            description: null
        });
        return this.applySubscriptionCoupon();
    };

    this.resetCouponCode = () => {
        this.actions.setSubscriptionCouponCode(null);
    };

    this.handleMoreOptionsSelected = ({item}, subscriptionItem) => {
        switch (item.id) {
            case SubscriptionConstants.APPLY_DISCOUNT_OPTION_ID:
                this.openDiscountDialog();
                break;
            case SubscriptionConstants.CHANGE_SUBSCRIPTION_OFFER_OPTION_ID:
                this.actions.setSelectedOfferName(this.state.currentSubscriptionOfferingDetails.DisplayName);
                $state.go(TRANSITION_OFFER, {
                    offeringId: this.state.currentSubscriptionOfferingDetails.OfferingId
                });
                break;
            case SubscriptionConstants.MODIFY_SUBSCRIPTION:
                if (!isNil(subscriptionItem)) {
                    this.actions.setEditSubscriptionItemId(subscriptionItem.Id);
                }
                this.showEditModal = true;
                break;
            case SubscriptionConstants.REMOVE_SUBSCRIPTION_OPTION_ID:
                if (!isNil(subscriptionItem)) {
                    this.actions.stateGo('^.remove', {
                        childSubscriptionId: subscriptionItem.Id
                    });
                } else {
                    this.actions.stateGo('^.remove');
                }
                break;
            case SubscriptionConstants.REMOVE_SUBSCRIPTION_OFFER_OPTION_ID:
                $state.go('index.customercare.customer.removeoffer', {
                    offeringId: this.state.currentSubscription.OfferingId,
                    subscriptionId: this.state.currentSubscription.Id,
                    currency: this.state.currency
                });
                break;
            case SubscriptionConstants.REMOVE_SUBSCRIPTION_EXTENSION_OPTION_ID:
                this.removeExtensionModalApi.open();
                break;
            case SubscriptionConstants.MODIFY_SUBSCRIPTION_OFFER_OPTION_ID:
                this.actions.startEditOfferFlow(this.state.currentSubscription.DisplayName);
                $state.go('index.customercare.customer.orders.editoffer', {
                    offeringId: this.state.currentSubscription.OfferingId,
                    offeringInstanceId: this.state.currentSubscriptionOfferingDetails.OfferingInstanceId
                });
                break;
            case SubscriptionConstants.REPLACE_SUBSCRIPTION_OPTION_ID:
                if (!isNil(subscriptionItem)) {
                    this.actions.stateGo('^.replace', {
                        childSubscriptionId: subscriptionItem.Id
                    });
                } else {
                    this.actions.stateGo('^.replace');
                }
                break;
            case SubscriptionConstants.VIEW_PAYMENT_HISTORY_OPTION_ID:
                this.actions.showSubscriptionBanner(true);
                this.actions.setLockerFilter(this.state.currentSubscription.Items[0].Id);
                $state.go('index.customercare.customer.transactions.list', {
                    previousRoute: {
                        name: this.state.routeState.name,
                        params: this.state.routeParams
                    },
                    subscription: this.state.currentSubscription
                });
                break;
            case SubscriptionConstants.MODIFY_RENEWAL_OPTION_ID:
                this.openRenewalModal(subscriptionItem);
                break;
            default: break;
        }
    };

    this.handleMoreOptionClickedOnItem = (moreOptionItemClicked, subscriptionItem) => {
        // the options at the item level will not work as expected until hardcoding when entering flow is removed
        this.handleMoreOptionsSelected(moreOptionItemClicked, subscriptionItem);
    };

    this.closeEditModal = () => {
        this.showEditModal = false;
        this.actions.setEditSubscriptionItemId(null);
    };

    this.restoreSubscription = () => {
        this.actions.goToCheckoutForRestoreSubscription();
    };

    this.onAddressSwitcherCreateOrEditCallback = (selectedAddress) => {
        this.handleUpdateSubscription(selectedAddress, true);
    };

    this.onAddressSwitcherChangeCallback = (selectedAddress) => {
        // eslint-disable-next-line no-prototype-builtins
        this.handleUpdateSubscription(selectedAddress.hasOwnProperty('asMutable')
            ? selectedAddress.asMutable()
            : selectedAddress,
        false);
    };

    this.handleUpdateSubscription = (selectedAddress, retrieveAddresses) => {

        const FilledOrEmptyShipToName = selectedAddress.ShipToName ? selectedAddress.ShipToName : null;
        const updatedSubscription = this.state.currentSubscription.set('IncludeInactiveDiscounts', true).set('Items', this.state.currentSubscription.Items.map((item) => {
            selectedAddress.ShipToName = FilledOrEmptyShipToName;
            return item.set('ShippingAddress', selectedAddress);
        }));

        if (retrieveAddresses) {
            this.retrieveAddresses().then(() => {
                this.updateSubscription(updatedSubscription);
            });
        } else {
            this.updateSubscription(updatedSubscription);
        }
    };

    this.applyDiscount = (discountData) => {
        this.closeDiscountDialog();

        const discountIds = filter((discountMapId) => {
            return discountData.selectedDiscounts[discountMapId];
        }, keys(discountData.selectedDiscounts));

        const request = {
            Id: this.state.subscriptionId,
            DiscretionaryDiscounts: discountIds,
            DiscretionaryDiscountReasonCode: discountData.discountReason.id,
            IncludeInactiveDiscounts: true
        };

        return this.updateSubscription(request).then(() => {
            return this.retrieveSubscription();
        });
    };

    this.closeDiscountDialog = () => {
        this.applyDiscountDialogApi.close();
    };

    this.openDiscountDialog = () => {
        this.applyDiscountDialogApi.open();
    };

    this.hasSubscriberAdminAccess = () => {
        return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, E_WALLET_ADMIN_ACCESS);
    };

    this.hasOrderingAccess = () => {
        return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
    };

    this.$onDestroy = () => {
        this.actions.setCurrentSubscriptionOfferingDetails(null);
        disconnectRedux();
    };

    this.onPaymentSwitcherChangeCallback = (paymentInstrument) => {
        this.actions.selectPaymentMethod(paymentInstrument);

        const data = {
            Id: this.state.subscriptionId,
            PaymentInstrument: paymentInstrument,
            IncludeInactiveDiscounts: true
        };

        this.updateSubscription(data);
    };

    this.isLoading = () => {
        return this.state.isFetchingData || this.state.isUpdatingSubscription || this.state.isFetchingEwalletData
            || this.state.isFetchingAddresses || this.state.IsRetrievingPendingOrder || this.state.isFetchingSingleProductContext
            || this.state.isFetchingSubscriptionDetailsSelector;
    };

    this.retrieveAddresses = () => {
        return this.actions.retrieveCustomerAddresses(this.state.currentCustomerId, false).catch(() => {
            if (this.state.addressError) {
                uiNotificationService.transientError(this.state.addressError.message);
            }
        });
    };

    this.retrieveSubscription = () => {
        const request = {
            IncludeInactiveDiscounts: true,
            IncludeChangeOptions: true
        };

        if (this.state.offerId) {
            request.OfferingId = this.state.offerId;
        } else {
            request.Id = this.state.subscriptionId;
        }

        return this.actions.retrieveSubscription(this.state.currentCustomerId, request).then(() => {
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            }).then(() => {
                this.actions.selectPaymentMethod(this.state.currentSubscription.PaymentInstrument);
                this.setInstanceProperties();
            }).catch(() => {
                if (this.state.eWalletError) {
                    uiNotificationService.transientError(this.state.eWalletError.message);
                }
            });
        }).catch(() => {
            if (this.state.subscriptionError) {
                uiNotificationService.transientError(this.state.subscriptionError.message);
            }
        });
    };

    this.setInstanceProperties = () => {
        const productIds = [];

        this.state.subscriptionItems.forEach((item) => {
            if (item.InstanceProperties) {
                productIds.push(item.Product.Id);
            }
            if (item.Children) {
                item.Children.forEach(childItem => {
                    if (childItem.InstanceProperties) {
                        productIds.push(childItem.Product.Id);
                    }
                });
            }
        });

        if (productIds.length > 0) {
            this.actions.getProductMetadataBatch(productIds).catch(() => {
                if (this.state.productOrderError) {
                    uiNotificationService.transientError(this.state.productOrderError.message);
                }
            });
        }
    };

    this.updateSubscription = (data) => {
        return this.actions.updateSubscription(this.state.currentCustomerId, data).then(() => {
            this.uiNotificationService.transientSuccess(i18n.translate(this.localeKeys.SUBSCRIPTIONS.UPDATE_SUCCESS));
        }).catch(() => {
            if (this.state.subscriptionError) {
                uiNotificationService.transientError(this.state.subscriptionError.message);
            }
        });
    };

    this.cancelPendingOrder = () => {
        return this.actions.cancelPendingOrder(this.state.currentCustomerId, {
            'OrderId': this.state.currentSubscription.PendingOrderId
        });
    };

    this.applySubscriptionCoupon = () => {
        const customerId = this.state.currentCustomerId;
        const subscriptionId = this.state.subscriptionId;
        const couponName = this.state.couponCode.name;
        return this.actions.applySubscriptionCoupon(customerId, subscriptionId, couponName).then(() => {
            this.uiNotificationService.transientSuccess(i18n.translate(this.localeKeys.SUBSCRIPTIONS.UPDATE_SUCCESS));
            return this.retrieveSubscription();
        }, (payload) => {
            uiNotificationService.transientError(payload.translatedMessage);
        });
    };

    this.displayInnerHeading = () => {
        return this.state.showShippingInfo ?
            `${i18n.translate(this.localeKeys.SUBSCRIPTIONS.PAYMENT_SHIPPING_INFO)}` :
            `${i18n.translate(this.localeKeys.SUBSCRIPTIONS.PAYMENT_INFO)}`;
    };

    this.openRenewalModal = (subscriptionItem) => {
        this.actions.setModifyRenewalSubscriptionItem(subscriptionItem || null);
        this.toggleModifyRenewalModal = true;
        $timeout(() => {
            this.modifyRenewalModal.open();
        });
    };

    this.openCancelRemovalModal = () => {
        this.cancelRemovalModalApi.open();
    };

    this.openCancelChangeModal = () => {
        this.actions.retrievePendingOrder(this.state.currentCustomerId, {
            'OrderId': this.state.currentSubscription.PendingOrderId
        }).then(() => {
            this.cancelChangeModalApi.open();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    };

    this.closeRenewalModal = () => {
        this.modifyRenewalModal.close();
        this.toggleModifyRenewalModal = false;
    };

    this.onRenewSuccess = () => {
        this.closeRenewalModal();
        this.toggleModifyRenewalModal = false;
    };

    this.closeCancelChangeModal = () => {
        this.cancelChangeModalApi.close();
    };

    this.closeCancelRemovalModal = () => {
        this.cancelRemovalModalApi.close();
    };

    this.onCancelRemovalChangeSuccess = () => {
        if (this.isPendingChange()) {
            this.closeCancelChangeModal();
        } else {
            this.closeCancelRemovalModal();
        }

        this.cancelPendingOrder().then(() => {
            if (this.isPendingChange()) {
                this.uiNotificationService.transientSuccess(i18n.translate(this.localeKeys.CHANGE_OFFER.CHANGE_OFFER_SUCCESS));
                this.actions.stateGo(DASHBOARD_ROUTE);
            } else {
                this.uiNotificationService.transientSuccess(i18n.translate(this.localeKeys.SUBSCRIPTIONS.CANCEL_REMOVAL_SUCCESS));
                this.retrieveSubscription();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    };

    this.canCreatePaymentInstruments = () => {
        return this.state.currentMemberCanCreatePaymentInstrument && this.hasSubscriberAdminAccess();
    };

    this.openRemoveDiscountModal = () => {
        this.showDiscountRemove = true;
        $timeout(() => {
            this.removeDiscountModal.open();
        });
    };

    this.closeRemoveDiscountModal = () => {
        this.showDiscountRemove = false;
        this.actions.setSelectedDiscount({});
        this.removeDiscountModal.close();
    };

    this.onDiscountChange = (discount) => {
        this.actions.setSelectedDiscount(discount);
    };

    this.onRemoveDiscount = (discount) => {
        const updatedObject = Object.assign({}, discount, {
            Reason: null
        });
        this.actions.setSelectedDiscount(updatedObject);
        this.openRemoveDiscountModal();
    };

    this.removeDiscount = () => {
        return this.actions.removeSubscriptionDiscount(this.state.currentCustomerId, this.state.selectedDiscount.Id, this.state.selectedDiscount.Reason).then(() => {
            this.closeRemoveDiscountModal();
            uiNotificationService.transientSuccess(i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_DISCOUNT.SUCCESS));
            return this.retrieveSubscription();
        }).catch(() => {
            uiNotificationService.transientError(this.state.subscriptionError.message);
        });
    };

    this.onSubscriptionExtensionRemoveListComplete = (extensionId) => {
        this.removeExtensionModalApi.close();
        this.actions.stateGo('index.customercare.customer.subscriptions.detail.extension', {
            extensionId: extensionId,
            currency: this.state.currency
        });
    };

    this.closeSubscriptionExtensionRemoveListPopup = () => {
        this.removeExtensionModalApi.close();
    };

    this.getRenewalText = () => {
        if (this.isCurrentPaymentMethodRemoved()) {
            return this.willNotRenew;
        }
        return this.state.currentSubscription.Type === SubscriptionConstants.SUBSCRIPTION_TYPE.MANUAL  ?
            this.manualRenewal :
            this.autoRenewal;
    };

    this.isReadOnly = () => {
        if (this.state.currentSubscription &&
            (this.state.currentSubscription.Status === SubscriptionConstants.SUBSCRIPTION_STATUSES.SUSPENDED ||
                this.state.currentSubscription.Status === SubscriptionConstants.SUBSCRIPTION_STATUSES.REMOVED ||
                this.state.currentSubscription.Status === SubscriptionConstants.SUBSCRIPTION_STATUSES.PENDING_REMOVAL ||
                this.state.IsCurrentSubscriptionOfferingPending)) {
            return true;
        }

        const selectedPaymentMethodType = pathOr('', ['Type'], this.state.selectedPaymentMethod);
        const readOnlyException = isReadOnlyException(selectedPaymentMethodType);

        if (readOnlyException) {
            return false;
        } else {
            return this.state.selectedPaymentMethod && !this.state.selectedPaymentMethod.actionables.canOrder;
        }
    };

    this.redirectToOrderDetails = (id) => {
        this.actions.stateGo('index.customercare.customer.orderHistory.details', {
            orderId: id,
            previousRoute: {
                name: this.state.routeState.name,
                params: this.state.routeParams
            },
            subscription: this.state.currentSubscription
        });
    };

    this.isCurrentPaymentMethodRemoved = () => {
        if (!this.state.selectedPaymentMethod) {
            return;
        }
        return this.state.selectedPaymentMethod.Status === SubscriptionConstants.PAYMENT_METHOD_STATUSES.REMOVED;
    };

    this.getExistingOfferTitle = () => {
        if (!this.state.IsRetrievingPendingOrder && this.state.pendingOrder) {
            return i18n.translate(this.localeKeys.CHANGE_OFFER.EXISTING_OFFER, {
                offeringName: pathOr('', ['pendingOrder', 'ExistingOfferings', '0', 'OfferingName'], this.state) || this.state.currentSubscription.displayName
            });
        }
    };

    this.getNewOfferTitle = () => {
        if (!this.state.IsRetrievingPendingOrder && this.state.pendingOrder) {
            return i18n.translate(this.localeKeys.CHANGE_OFFER.NEW_OFFER, {
                offeringName: pathOr('', ['pendingOrder', 'NewOfferings', '0', 'OfferingName'], this.state) || this.state.currentSubscription.displayName
            });
        }
    };

    this.getMaxChangeItemsRange = () => {
        return range(0, max(
            pathOr(0, ['NewOfferings', '0', 'OrderItems', 'length'], this.state.pendingOrder),
            pathOr(0, ['ExistingOfferings', '0', 'OrderItems', 'length'], this.state.pendingOrder)));
    };

    this.isPendingRemoval = () => {
        return pathOr(false, ['currentSubscriptionOfferingDetails', 'Status'], this.state) === OFFERING_OPTION_STATUSES.PENDING_REMOVED;
    };

    this.isPendingChange = () => {
        return this.state.IsCurrentSubscriptionOfferingPending && !this.isPendingRemoval();
    };
}

export default {
    bindings: {},
    template: require('./subscriptions.detail.html'),
    controller: SubscriptionDetailController,
    controllerAs: 'subscriptionDetailController'
};
